import React, { Component } from 'react'

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/Dealers/Actions';

import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import { Link } from 'react-router-dom';

import DealersListingSkeleton from './../../components/Dealers/DealersListingSkeleton';
import MuPagination from './../../components/MuPagination/index';
import SingleDealer from './../../components/DealersListing/SingleDealer';

// Document Head Manager
import { Helmet } from "react-helmet";
// import MetaTags from 'react-meta-tags';

const styles = theme => ({
  root: {
    maxWidth: 1070,
  },
  rootPaper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
})

class Dealers extends Component {

  state = {
    searchTerm: ''
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadDealersDataAPI();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.loading) {
      this.loadDealersDataAPI();
    }
  }

  loadDealersDataAPI = () => {
    const { currentPage, getAllDealersDataAction, limit } = this.props;
    const { searchTerm } = this.state;
    getAllDealersDataAction(currentPage, limit, searchTerm);
  }

  handleChangePageNumber = (offset, pageNumber) => {
    this.props.changePageNumberAction(pageNumber);
  }

  componentWillUnmount() {
    this.props.resetLoadingFlagAction();
  }

  handleChangeSearchTerm = (e) => {
    this.setState({
      searchTerm: e.target.value
    })
  }

  handleSearchSubmit = (e) => {
    e && e.preventDefault();
    this.props.getSearchedDataAction();
  }

  render() {
    const { classes, loading, dealersData, totalResults, currentPage, limit } = this.props;
    const { searchTerm } = this.state;

    return (
      <div className={`${classes.root} mx-auto mt-4 mb-4`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Car Dealers | Buy and Sell Second-hand Cars | Chat2Cars</title>
          <meta name="title" content="Car Dealers | Buy and Sell Second-hand Cars | Chat2Cars" />
          {/* <title>Dealers | {process.env.REACT_APP_NAME}</title> */}
          <meta property="og:title" content="Car Dealers | Buy and Sell Second-hand Cars | Chat2Cars" />
          <meta name="description" content="Want to buy and sell second-hand cars at the best prices? Contact our best car dealers - We also have the BMW and Toyota dealership in South Africa." />
          <meta property="og:image" content="https://chat2cars.co.za/logo.png" />
        </Helmet>
        <Container>
          <Breadcrumbs className="mb-4" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
              Home
              </Link>
            <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">Dealers</Typography>
          </Breadcrumbs>
          <div className="d-flex justify-content-end">
            <Paper component="form" className={classes.rootPaper} onSubmit={this.handleSearchSubmit}>
              <InputBase
                className={classes.input}
                placeholder="Search Dealer"
                inputProps={{ 'aria-label': 'search dealer' }}
                value={searchTerm}
                onChange={this.handleChangeSearchTerm}
              />
              <IconButton className={classes.iconButton} aria-label="search" onClick={this.handleSearchSubmit}>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
          {loading ?
            <DealersListingSkeleton />
            :
            <React.Fragment>
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <MuPagination
                  limit={limit}
                  offset={(currentPage - 1) * limit}
                  total={totalResults}
                  onClickPageNo={(e, offset, pageNumber) => this.handleChangePageNumber(offset, pageNumber)}
                />
              </div>
              <Grid container spacing={4}>
                {dealersData.map(dealer => {
                  return <SingleDealer key={dealer._id} dealer={dealer} />
                })}
              </Grid>
              <div className="d-flex align-items-center justify-content-between flex-wrap mt-5">
                <MuPagination
                  limit={limit}
                  offset={(currentPage - 1) * limit}
                  total={totalResults}
                  onClickPageNo={(e, offset, pageNumber) => this.handleChangePageNumber(offset, pageNumber)}
                />
              </div>
            </React.Fragment>
          }
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.Dealers
  };
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dealers));