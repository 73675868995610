import React, { useEffect } from 'react';

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/BookTestDrive/Actions';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import { KeyboardDateTimePicker } from "@material-ui/pickers";

import { Link } from 'react-router-dom';

import ReactGA from "react-ga";

// Document Head Manager
import { Helmet } from "react-helmet";

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SubscribeField } from './../../components/Footer/Footer.style';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#F5C82A',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#F5C82A',
    color: '#000',
    fontWeight: 900,
    '&:hover': {
      backgroundColor: '#F5C82A',
      color: '#000',
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    minWidth: '100%',
    '& .MuiInput-underline': {
      '&:after': {
        borderBottom: '2px solid #000',
      }
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#000'
    }
  },
}));

const BookTestDriveValidationSchema = Yup.object().shape({
  fullname: Yup.string().required('Please enter full name'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Please enter an email'),
  mobile_number: Yup.number()
    .positive('Must be positive numbers')
    .test('len', 'Must be exactly 9 digits', val => val && val.toString().length === 9),
  state: Yup.object().required('Please select province'),
  city: Yup.object().required('Please select city'),
  postcode: Yup.string().required('Please enter post code'),
  car_brand: Yup.object().required('Please select a make'),
  car_model: Yup.object().required('Please select a model'),
  datetime: Yup.string().required('Please select date & time'),
});

const BookTestDrive = (props) => {
  const {
    hasError,
    hasSuccess,
    message,
    isLoading,
    provinces,
    cities,
    vehicleBrandsList,
    vehicleModelsList,
    loadProvincesAndVehicleBrandAction,
    submitBookTestDriveDataAction,
    loadCitiesAction,
    fetchVehicleModelAction,
    removeModelsAction,
    setLoadingFlagAction,
    resetAllBookTestDriveOptionAction,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    if (provinces.length === 0 && vehicleBrandsList.length === 0) {
      loadProvincesAndVehicleBrandAction();
    }
  }, [provinces, vehicleBrandsList, loadProvincesAndVehicleBrandAction]);

  useEffect(() => {
    return () => {
      resetAllBookTestDriveOptionAction();
    };
  }, [resetAllBookTestDriveOptionAction])

  const handleFormSubmit = values => {
    setLoadingFlagAction();
    const newValues = {
      ...values,
      state: values.state_name,
      city: values.city_name,
      car_brand: values.car_brand.maketitle,
      car_model: values.car_model.modeltitle,
    }
    submitBookTestDriveDataAction(newValues);
    ReactGA.event({
      category: 'conversion',
      action: 'AW-627651729/8NYyCN3zwdkBEJHppKsC'
    });
  }

  const handleChangeProvince = (id) => {
    loadCitiesAction(id);
  }

  const onChangeBrand = brand => {
    fetchVehicleModelAction(brand)
  }

  return (
    <Container className="my-4 px-3" style={{ minHeight: 'calc(100vh -  608px)' }} maxWidth="sm">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Book Test Drive | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <Breadcrumbs className="mt-4" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
          Home
        </Link>
        <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">Book Test Drive</Typography>
      </Breadcrumbs>
      <div className={`shadow bg-white p-4 ${classes.paper}`}>
        <Typography component="h1" variant="h5">
          Book Test Drive
        </Typography>
        {isLoading ?
          <Container className="my-4 d-flex justify-content-center align-items-center" component="main" style={{ minHeight: 'calc(100vh -  608px)' }} maxWidth="sm">
            <CircularProgress />
          </Container>
          :
          hasError ?
            <Alert className="mt-4" style={{ width: '100%' }} severity="error">
              <AlertTitle>Error</AlertTitle>
              {message}
            </Alert>
            :
            hasSuccess ?
              <React.Fragment>
                <Alert className="mt-4" style={{ width: '100%' }} severity="success">
                  <AlertTitle>Your test drive request has been submitted successfully</AlertTitle>
                </Alert>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="default"
                  className={classes.submit}
                  component={Link}
                  to="/"
                >
                  Go to Home
                </Button>
              </React.Fragment>
              :
              <Formik
                // enableReinitialize
                initialValues={{
                  fullname: '',
                  mobile_number: '',
                  email: '',
                  state: null,
                  city: null,
                  postcode: '',
                  car_brand: null,
                  model: null,
                  datetime: moment().format(),
                }}
                onSubmit={handleFormSubmit}
                validationSchema={BookTestDriveValidationSchema}
              >
                {(renderProps) => {
                  const { values: formValues, touched, errors } = renderProps;
                  return (
                    <Form className={classes.form}>
                      <SubscribeField
                        className="news-letter-input"
                        label="Full name"
                        style={{ width: '100%' }}
                        margin="normal"
                        fullWidth
                        autoFocus
                        newsletter={true}
                        value={formValues.fullname}
                        onChange={e => renderProps.setFieldValue('fullname', e.target.value)}
                        helperText={touched.fullname && errors.fullname}
                        error={touched.fullname && errors.fullname ? true : false}
                      />
                      <SubscribeField
                        className="news-letter-input"
                        label="Contact Number"
                        style={{ width: '100%' }}
                        margin="normal"
                        fullWidth
                        newsletter={true}
                        value={formValues.mobile_number}
                        onChange={e => renderProps.setFieldValue('mobile_number', e.target.value)}
                        helperText={touched.mobile_number && errors.mobile_number}
                        error={touched.mobile_number && errors.mobile_number ? true : false}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">+27</InputAdornment>,
                          type: 'number'
                        }}
                      />
                      <SubscribeField
                        className="news-letter-input"
                        label="Your Email Address"
                        style={{ width: '100%' }}
                        margin="normal"
                        fullWidth
                        id="email"
                        name="email"
                        autoComplete="email"
                        newsletter={true}
                        InputProps={{
                          type: 'email',
                          autoComplete: 'off'
                        }}
                        value={formValues.email}
                        onChange={e => renderProps.setFieldValue('email', e.target.value)}
                        helperText={touched.email && errors.email}
                        error={touched.email && errors.email ? true : false}
                      />
                      <Autocomplete
                        options={provinces}
                        getOptionLabel={option => option.state_name}
                        autoComplete
                        includeInputInList
                        renderInput={params =>
                          <SubscribeField
                            {...params}
                            label="Province"
                            className="news-letter-input"
                            margin="normal"
                            fullWidth
                            newsletter={true}
                            helperText={touched.state && errors.state}
                            error={touched.state && errors.state ? true : false}
                          />
                        }
                        value={formValues.state || null}
                        onChange={(event, value) => {
                          if (value) {
                            handleChangeProvince(value._id)
                            renderProps.setFieldValue('city', null)
                          }
                          return renderProps.setFieldValue('state', value ? value : null)
                        }}
                      />
                      <Autocomplete
                        options={cities}
                        getOptionLabel={option => option.city_name}
                        autoComplete
                        includeInputInList
                        renderInput={params =>
                          <SubscribeField
                            {...params}
                            label="City"
                            className="news-letter-input"
                            margin="normal"
                            fullWidth
                            newsletter={true}
                            helperText={touched.city && errors.city}
                            error={touched.city && errors.city ? true : false}
                          />
                        }
                        value={formValues.city || null}
                        onChange={(event, value) => renderProps.setFieldValue('city', value ? value : null)}
                      />
                      <SubscribeField
                        className="news-letter-input"
                        label="Post Code"
                        style={{ width: '100%' }}
                        margin="normal"
                        fullWidth
                        newsletter={true}
                        value={formValues.postcode}
                        onChange={e => renderProps.setFieldValue('postcode', e.target.value)}
                        helperText={touched.postcode && errors.postcode}
                        error={touched.postcode && errors.postcode ? true : false}
                        InputProps={{
                          type: 'number'
                        }}
                      />
                      <Autocomplete
                        options={vehicleBrandsList}
                        getOptionLabel={option => option.maketitle}
                        renderInput={params => (
                          <SubscribeField
                            {...params}
                            placeholder="Make"
                            fullWidth
                            label="Make"
                            className="news-letter-input"
                            margin="normal"
                            newsletter={true}
                            helperText={touched.car_brand && errors.car_brand}
                            error={touched.car_brand && errors.car_brand ? true : false}
                          />
                        )}
                        value={formValues.car_brand}
                        onChange={(event, value) => {
                          if (value) {
                            onChangeBrand(value);
                          } else {
                            removeModelsAction();
                          }
                          renderProps.setFieldValue('car_brand', value);
                          renderProps.setFieldValue('car_model', null);
                        }}
                      />
                      <Autocomplete
                        options={vehicleModelsList}
                        getOptionLabel={option => option.modeltitle}
                        renderInput={params => (
                          <SubscribeField
                            {...params}
                            placeholder="Model"
                            fullWidth
                            label="Model"
                            className="news-letter-input"
                            margin="normal"
                            newsletter={true}
                            helperText={touched.car_model && errors.car_model}
                            error={touched.car_model && errors.car_model ? true : false}
                          />
                        )}
                        value={formValues.car_model}
                        onChange={(event, value) => renderProps.setFieldValue('car_model', value)}
                      />
                      <KeyboardDateTimePicker
                        value={new Date(moment(formValues.datetime))}
                        className="mt-3"
                        disablePast
                        fullWidth
                        label="Date & Time"
                        onChange={value => renderProps.setFieldValue('datetime', moment(value).format())}
                        showTodayButton
                        helperText={touched.datetime && errors.datetime}
                        error={touched.datetime && errors.datetime ? true : false}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="default"
                        className={classes.submit}
                      >
                        Submit
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
        }
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  ...state.BookTestDrive,
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookTestDrive)
