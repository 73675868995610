import {
  LOAD_PROVINCES_VEHICLE_BRAND_POSTAD,
  FETCH_VEHICLE_MODEL_BOOK_TEST_DRIVE,
  SUBMIT_BOOK_TEST_DRIVE_DATA,
  SUBMIT_BOOK_TEST_DRIVE_DATA_FAILURE,
  RESET_BOOK_TEST_DRIVE_OPTION,
  REMOVE_MODEL_OPTIONS,
  SET_LOADING,
  LOAD_CITIES,
} from './Constants';
import { ADDED_NOTIFICATION } from "../Notification/Constants";
import Api from '../../helpers/ApiHandler';

const api = new Api();

export const submitBookTestDriveDataAction = (payload) => dispatch => {
  api.post(`cars/test-drive/book-test-drive`, { data: payload })
    .then((result) => {
      if (result.success) {
        dispatch({
          type: SUBMIT_BOOK_TEST_DRIVE_DATA,
          data: result.msg
        });
      } else {
        dispatch({
          type: SUBMIT_BOOK_TEST_DRIVE_DATA_FAILURE,
          data: result.msg
        })
      }
    })
    .catch((err) => {
      // console.log('err', err);
    });
}

export const loadProvincesAndVehicleBrandAction = () => async dispatch => {
  const brands = await api.get('common/get-all-makes', { skipAuth: true });
  const states = await api.get('city_state/get-all-states', { skipAuth: true });
  try {
    dispatch({
      type: LOAD_PROVINCES_VEHICLE_BRAND_POSTAD,
      data: {
        provinces: states.success ? states.data : [],
        vehicleBrandsList: brands.success ? brands.data : [],
      }
    })
  } catch (err) {
    // console.log('err', err);
  };
}

export const fetchVehicleModelAction = (brand) => dispatch => {
  api.get(`common/get-single-make/${brand._id}`, { skipAuth: true })
    .then((result) => {
      if (result.success) {
        dispatch({
          type: FETCH_VEHICLE_MODEL_BOOK_TEST_DRIVE,
          data: result.data.models
        })
      } else {
        // console.log('result', result);
      }
    })
    .catch((err) => {
      // console.log('err', err);
    });
}

export const loadCitiesAction = (id) => dispatch => {
  api.post('city_state/get-cities-by-state-ids', { data: { state_ids: id }, skipAuth: true })
    .then((result) => {
      if (result.success) {
        dispatch({
          type: LOAD_CITIES,
          data: result.data
        })
      } else {
        // console.log('result', result)
      }
    })
    .catch((err) => {
      // console.log('err', err);
    });
}

export const removeModelsAction = () => dispatch => {
  dispatch({
    type: REMOVE_MODEL_OPTIONS
  })
}

export const setLoadingFlagAction = () => dispatch => {
  dispatch({
    type: SET_LOADING
  })
}

export const resetAllBookTestDriveOptionAction = () => dispatch => {
  dispatch({
    type: RESET_BOOK_TEST_DRIVE_OPTION
  })
}

export const sendNotificationAction = (type, message) => dispatch => {
  dispatch({
    type: ADDED_NOTIFICATION,
    data: {
      type,
      message,
    },
  })
}