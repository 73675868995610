import React from 'react';

import moment from "moment";
import { Link } from 'react-router-dom';

const UserInfo = ({ carData: { creator, created_at } }) => {
  return (
    <div className="user-contact-info shadow">
      <div className="user-info-card">
        <div className="user-photo">
          <img className="img-circle" src={creator.avatarurl} alt="" />
        </div>
        <div className="user-information">
          <span className="UserName">
            <Link to={`/dealer/${creator._id}`}>{creator.fullname}</Link>
          </span>
          <div className="item-date">
            <span className="ad-pub">Published on: {moment(created_at).format('YYYY-MM-DD')}</span>
            <br />
            <br />
            <Link to={`/dealer/${creator._id}`}>
              More Listings
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserInfo;
