import React, { Component } from 'react';

// Material UI Components
import { withStyles } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/DealerDetail/Actions';

import { Link } from 'react-router-dom';

// Document Head Manager
import { Helmet } from "react-helmet";

import './DealerDetail.css';
import { UIDecorLine } from '../../components/Footer/Footer.style';
import CarCard from './../../components/CarCard';
import MuPagination from './../../components/MuPagination';
import GotoLoginDialog from './../../components/GotoLoginDialog';

const styles = theme => ({
  root: {
    maxWidth: 1200
  },
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },
  large: {
    width: theme.spacing(25),
    height: theme.spacing(25),
    margin: '0 auto'
  },
  card: {
    minWidth: 230,
    marginTop: theme.spacing(3),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  rootPaper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
})

class DealerDetail extends Component {

  state = {
    isDialogOpen: false,
    searchTerm: '',
  }

  componentDidMount() {
    const { filterParams: { currentPage, limit }, match, fetchDealerDataAction } = this.props;
    const payload = {
      page: currentPage,
      limit,
      creator: match.params.id
    };
    fetchDealerDataAction(match.params.id, payload, false);
  }

  componentDidUpdate() {
    const { isLoadingResults, filterParams: { currentPage, limit }, match, fetchDealerDataAction } = this.props;
    if (isLoadingResults) {
      const { searchTerm } = this.state;
      const payload = {
        page: currentPage,
        limit,
        creator: match.params.id,
        search: searchTerm
      };
      fetchDealerDataAction(match.params.id, payload, false);
    }
  }

  componentWillUnmount() {
    this.props.resetDealerDetailAction();
  }

  handleUpdatePageNumber(value) {
    this.props.updatePageNoAction(value);
  }

  handleLikeUnLikeCar = (id) => {
    if (this.props.isAuthenticated) {
      this.props.handleLikeUnLikeCarAction(id);
    } else {
      this.setState({
        isDialogOpen: true,
      });
    }
  }

  handleCloseDialog = () => {
    this.setState({
      isDialogOpen: false,
    })
  }

  handleChangeSearchTerm = (e) => {
    this.setState({
      searchTerm: e.target.value
    })
  }

  handleSearchSubmit = (e) => {
    e && e.preventDefault();
    this.props.getSearchedDataAction(this.state.searchTerm);
  }

  render() {
    const { classes, filterParams: { currentPage, limit }, totalResults, isLoading, isLoadingResults, dealerData, dealerCarsData, history } = this.props;
    const { isDialogOpen, searchTerm } = this.state;
    const dealerName = dealerData ? dealerData.businessname || dealerData.fullname : '';
    // console.log(dealerData);
    return (
      <div className={`${classes.root} mx-auto`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{dealerName} - {process.env.REACT_APP_NAME}</title>
        </Helmet>
        <Breadcrumbs className="mx-3 mt-4" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
            Home
          </Link>
          <Link to="/dealers" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
            Dealers
          </Link>
          <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">Dealer Detail</Typography>
        </Breadcrumbs>
        <CssBaseline />
        <div className="d-flex justify-content-end">
          <Paper component="form" className={classes.rootPaper} onSubmit={this.handleSearchSubmit}>
            <InputBase
              className={classes.input}
              placeholder="Search Your Cars"
              inputProps={{ 'aria-label': 'search your cars' }}
              value={searchTerm}
              onChange={this.handleChangeSearchTerm}
            />
            <IconButton className={classes.iconButton} aria-label="search" onClick={this.handleSearchSubmit}>
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        {(isLoading || !dealerData) ?
          <Container className="my-4 d-flex justify-content-center align-items-center" component="main" style={{ minHeight: 'calc(100vh -  659px)' }} maxWidth="sm">
            <CircularProgress />
          </Container>
          :
          <div className={`my-4 ${classes.paper} dealer-detail`} component="main" style={{ minHeight: 'calc(100vh -  659px)' }}>
            <div className="dealer-detail__sidebar">
              <Avatar alt="Remy Sharp" src={dealerData.logourl || "https://lh3.googleusercontent.com/-RqAwlnHyVX0/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3rdUZqw0-Y23JfXnbxGPFt8cTrWlYQ.CMID/s400-c/photo.jpg"} className={classes.large} />
              <div className="dealer-detail__sidebar_cards">
                <Card className={`${classes.card} dealer-detail__sidebar_card shadow`}>
                  <CardContent className="p-3">
                    <List className="p-0" dense={true}>
                      <ListItem className="px-0 pb-2 pt-0">
                        <ListItemText
                          primary={
                            <Typography className={classes.title} color="textSecondary">
                              Email
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          {dealerData.email ? <CheckCircleOutlineIcon /> : <HighlightOffIcon />}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem className="px-0 pb-2 pt-0">
                        <ListItemText
                          primary={
                            <Typography className={classes.title} color="textSecondary">
                              Mobile Number
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          {dealerData.gsm ? <CheckCircleOutlineIcon /> : <HighlightOffIcon />}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem className="px-0 pb-0 pt-0">
                        <ListItemText
                          primary={
                            <Typography className={classes.title} color="textSecondary">
                              Business Details
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          {dealerData.businessname && dealerData.address ? <CheckCircleOutlineIcon /> : <HighlightOffIcon />}
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
                <Card className={`${classes.card} dealer-detail__sidebar_card shadow`}>
                  <CardContent className="p-3">
                    <List className="p-0" dense={true}>
                      <ListItem className="px-0 pb-0 pt-0">
                        <ListItemText
                          primary={
                            <Typography className={classes.title} color="textSecondary">
                              Total Cars
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          {dealerData.listingCarsCount}
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </div>
            </div>
            <div className="dealer-detail__content">
              <Container maxWidth="md">
                <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary" component="h2" variant="h5">
                  {`${dealerData.fullname} - ${dealerData.businessname}`}
                </Typography>
                <UIDecorLine className="ml-0 mb-1" />
                <MuPagination
                  className="mt-1 ml-2 mr-2 mb-3 text-right"
                  limit={limit}
                  offset={(currentPage - 1) * limit}
                  total={totalResults}
                  onClickPageNo={(e, offset, pageNumber) => this.handleUpdatePageNumber(pageNumber)}
                />
                {isLoadingResults ?
                  <Container className="d-flex justify-content-center align-items-center">
                    <CircularProgress />
                  </Container>
                  :
                  <Grid container spacing={3} wrap="wrap">
                    {dealerCarsData.map(car => {
                      return <CarCard key={car._id} carData={car} featured={car.featured} handleLikeUnLikeCar={this.handleLikeUnLikeCar} />;
                    })}
                  </Grid>
                }
              </Container>
            </div>
          </div>
        }
        <GotoLoginDialog open={isDialogOpen} handleClose={this.handleCloseDialog} history={history} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.DealerDetail,
    isAuthenticated: state.Login.isAuthenticated
  };
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DealerDetail));
