import Home from "./Home/Reducer";
import SearchResults from "./Results/Reducer";
import Dealers from "./Dealers/Reducer";
import DealerDetail from "./DealerDetail/Reducer";
import Login from "./Login/Reducer";
import Register from "./Register/Reducer";
import UserVerification from "./UserVerification/Reducer";
import ForgotPassword from "./ForgotPassword/Reducer";
import ResetPassword from "./ResetPassword/Reducer";
import Profile from "./Profile/Reducer";
import Notification from "./Notification/Reducer";
import Chat from "./Chat/Reducer";
import PostAd from "./PostAd/Reducer";
import Subscriptions from "./Subscriptions/Reducer";
import CarDetail from "./CarDetail/Reducer";
import BookTestDrive from "./BookTestDrive/Reducer";
import ContactUs from "./ContactUs/Reducer";
import BlogDetail from "./BlogDetail/Reducer";

export default {
  Home,
  SearchResults,
  Dealers,
  DealerDetail,
  Login,
  Register,
  UserVerification,
  ForgotPassword,
  ResetPassword,
  Profile,
  Notification,
  Chat,
  PostAd,
  Subscriptions,
  CarDetail,
  BookTestDrive,
  ContactUs,
  BlogDetail,
}