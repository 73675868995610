import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

import { Link } from 'react-router-dom';
import formatNumber from '../../helpers/formatNumber';

const useStyles = makeStyles(theme => ({
  card: {
    background: '#fff none repeat scroll 0 0',
    boxShadow: '0 2px 5px -1px rgba(0, 0, 0, 0.16)',
    transition: 'unset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    '&:hover': {
      boxShadow: '0 2px 5px -1px rgba(0, 0, 0, 0.4)',
    }
  },
  cardContent: {
    flexGrow: 1
  },
  media: {
    height: 270,
    position: 'relative',
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
}));

const statuses = [
  { label: 'Active', value: 'active' },
  { label: 'Sold', value: 'sold' },
  { label: 'InActive', value: 'inactive' },
];

const MyCar = ({ carData, handleDeleteCar, handleChangeCarStatus }) => {
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChangeStatus = (e) => {
    handleChangeCarStatus(carData._id, e.target.value);
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className={`${classes.card} mx-auto`}>
        <div className={classes.cardContent}>
          <Link to={`/listing/${carData.slug}`}>
            <CardMedia
              className={classes.media}
              image={carData.featured_image || "https://material-ui.com/static/images/cards/contemplative-reptile.jpg"}
              title="Contemplative Reptile"
            >
              <div className="featured-ribbon">
                <span className="bg-primary">{carData.status}</span>
              </div>
              <div className="bg-primary price-tag">{formatNumber(carData.price, 2)}</div>
            </CardMedia>
          </Link>
          <CardContent className={`${classes.cardContent}`}>
            <Typography gutterBottom variant="h6" component="h3">
              {carData.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="div" className="car-location d-flex">
              <LocationOnIcon fontSize="small" /> <span className="pl-2">{carData.address}</span>
            </Typography>
            <Typography variant="body2" color="textSecondary" component="div" className="car-location d-flex pt-2">
              <VisibilityIcon fontSize="small" /> <span className="pl-2">Views: {carData.clicks}</span>
            </Typography>
          </CardContent>
        </div>
        <CardActions>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
              Change Ad Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              onChange={handleChangeStatus}
              labelWidth={labelWidth}
              defaultValue={carData.status}
            >
              {statuses.map(status => {
                return <MenuItem key={status.value} value={status.value}>{status.label}</MenuItem>
              })}
            </Select>
          </FormControl>
          <div className="d-flex">
            <IconButton
              component={Link}
              to={`/post/ad/${carData.slug}`}
              className="p-2"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              style={{ color: '#ff0000' }}
              className="mx-2 p-2"
              onClick={() => handleDeleteCar(carData._id)}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </CardActions>
      </Card>
    </Grid>
  );
}

export default MyCar;