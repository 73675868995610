import styled from "styled-components";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

export const ExpansionPanelWrapper = styled(ExpansionPanel)`
  &.MuiPaper-elevation1 {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2);
  }

  &.MuiExpansionPanel-root.Mui-expanded {
    margin: 0;
    /* box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2); */
  }

  &.MuiExpansionPanel-root.Mui-expanded:before, &.MuiExpansionPanel-root.Mui-expanded:after, &.MuiExpansionPanel-root:before, &.MuiExpansionPanel-root:after {
    opacity: 1;
  }

  &.MuiExpansionPanel-root.Mui-expanded + &.MuiExpansionPanel-root:before {
    display: block;
  }

  & .MuiExpansionPanelSummary-root {
    padding: 0 16px;
    min-height: 70px;
  }

  & .MuiExpansionPanelDetails-root {
    padding: 0 16px 16px;
  }
`;

export const ClearAllButton = styled(Button)`
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.4);

  &.MuiButton-textPrimary {
    color: #f7c413 !important;
    border: 1px solid;
  }

  &.MuiButton-textPrimary:hover {
    background-color: #f5c82a38 !important;
  }

`;

export const SaveButton = styled(IconButton)`
  &.MuiIconButton-colorPrimary {
    color: #F5C82A;
  }

  &.MuiIconButton-colorPrimary:hover {
    background-color: #f1c62f2e;
  }
`;