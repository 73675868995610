import React, { useState } from 'react';
import MailOutline from '@material-ui/icons/MailOutline';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import CallIcon from '@material-ui/icons/Call';
import ChatIcon from '@material-ui/icons/Chat';
import ContactSellerViaEmailDialog from './ContactSellerViaEmailDialog';
import ContactSellerViaChatDialog from './ContactSellerViaChatDialog';
import ContactDelear from './ContactDelear';

const ContactSeller = ({ isAuthenticated, carData, handleSubmitData, handleContinueToChat }) => {
  const [show, setIsShow] = useState(false);
  return (
    <React.Fragment>
      {/* <div className="category-list-icon shadow">
        <div className="category-icon-green">
          <MailOutline
            style={{
              fontSize: 40,
              color: '#fff'
            }}
          />
        </div>
        <div className="category-list-title">
          <h5>
            <ContactSellerViaEmailDialog isAuthenticated={isAuthenticated} handleSubmitData={handleSubmitData} carData={carData} />
          </h5>
        </div>
      </div> */}
      <div className="category-list-icon shadow">
        <div className="category-icon-yellow">
          
          <CallIcon
            style={{
              fontSize: 40,
              color: '#fff'
            }}
          />
        </div>
        <div className="category-list-title">
          {show ===true ? 
          <h5>{carData.creator.gsm !== '' 
          ? 
          <a className="footer-contacts__phone" style={{ color: '#000', textDecoration: 'none' }} href={`tel:+27-${carData.creator.gsm}`}>{`+27-${carData.creator.gsm}`}</a> 
          : 
          'Not Available'}</h5>
          :
          <h5 onClick={() => setIsShow(true)}> Show number </h5>
          }
        </div>
      </div>
      <div className="contact-delear-card shadow">
      {/* <ContactSellerViaEmailDialog isAuthenticated={isAuthenticated} handleSubmitData={handleSubmitData} carData={carData} /> */}
        <ContactDelear  isAuthenticated={isAuthenticated} handleSubmitData={handleSubmitData} carData={carData} />
      </div>
      <div className="category-list-icon shadow">
        <div className="category-icon-yellow">
          <ChatIcon
            style={{
              fontSize: 40,
              color: '#fff'
            }}
          />
        </div>
        <div className="category-list-title">
          <h5>
            <ContactSellerViaChatDialog isAuthenticated={isAuthenticated} handleSubmitData={handleContinueToChat} />
          </h5>
        </div>
      </div>
    </React.Fragment>
  )
};

export default ContactSeller;
