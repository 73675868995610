import React from 'react';

const SafetyTips = () => {
  const safetyTips = [
    'Use a safe location to meet seller',
    'Avoid cash transactions',
    'Beware of unrealistic offers'
  ];
  return (
    <div className="widget shadow">
      <div className="widget-heading">
        <h4 className="panel-title">Safety tips for deal</h4>
      </div>
      <div className="widget-saftey">
        <ol>{safetyTips.map((name, i) => <li key={i}>{name}</li>)}</ol>
      </div>
    </div>
  );
};

export default SafetyTips;
