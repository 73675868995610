import React, { useEffect } from 'react'

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/Subscriptions/Actions';


import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import formatNumber from '../../helpers/formatNumber';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import { CardHeaderWrapper } from "./Subscription.style";

import { Formik, Form } from 'formik';

// Document Head Manager
import { Helmet } from "react-helmet";
// import MetaTags from 'react-meta-tags';

import "./subscription.scss";
import { Link } from 'react-router-dom';
import { postToURL, makeRandomId } from '../../helpers/payment';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
  },
  formControl: {
    padding: theme.spacing(2),
    maxWidth: 300,
    width: '100%',
    textAlign: 'center',
    margin: '0 auto',
    display: 'flex'
  },
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&.makeActive': {
      backgroundColor: '#000000d6',
      color: '#fff',
    },
    '&.makeActive .makePriceActive h2, &.makeActive .makePriceActive h6, &.makeActive .MuiSelect-icon': {
      color: '#fff',
    },
    '&.makeActive .MuiSelect-select, &.makeActive .MuiOutlinedInput-notchedOutline, &.makeActive .MuiOutlinedInput-notchedOutline:hover, &.makeActive .MuiOutlinedInput-notchedOutline:focus': {
      borderColor: '#fff',
      color: '#fff',
    }
  },
  cardContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardHeader: {
    fontWeight: 900,
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  selectRoot: {
    borderBottom: '1px solid white',
  },
  selectIcon: {
    fill: 'white',
  },
  buyButton: {
    '&.MuiButton-containedPrimary': {
      padding: theme.spacing(2, 0, 2),
      backgroundColor: '#F5C82A',
      color: '#000',
      fontWeight: 900,
      '&:hover': {
        backgroundColor: '#F5C82A',
        color: '#000',
      },
    },
    '&.MuiButton-outlinedPrimary': {
      color: '#000',
      padding: theme.spacing(2, 0, 2),
      border: '2px solid #F5C82A',
      '&:hover': {
        border: '2px solid #F5C82A',
        backgroundColor: '#F5C82A',
        color: '#000',
      }
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#F5C82A',
    color: '#000',
    fontWeight: 900,
    '&:hover': {
      backgroundColor: '#F5C82A',
      color: '#000',
    }
  },
}));

const tiers = [
  {
    _id: "5b70670a6f9f143ca5cad740",
    title: 'basic_subscription',
    name: 'Basic Subscription',
    price: '0',
    description: ['All the awesomeness', 'Up to 2 Cars', 'Unlimited tasks'],
    buttonText: 'Select Plan',
    buttonVariant: 'outlined',
  },
  {
    _id: "5b75d6bfbcc2626f0d97a498",
    title: 'gold_subscription',
    type: 'gold',
    name: 'Gold Package',
    price: '2500',
    description: ['All the awesomeness', 'Up to 50 Cars', 'Unlimited tasks'],
    buttonText: 'Select Plan',
    buttonVariant: 'contained',
  },
  {
    _id: "5bca1afdf1db4d6970da5610",
    title: 'promo',
    name: 'Promotion',
    type: 'promotion',
    price: '3500',
    description: ['All the awesomeness', 'Up to 100 Cars', 'Unlimited tasks'],
    buttonText: 'Select Plan',
    buttonVariant: 'outlined',
  },
  {
    _id: "5b75d6dcbcc2626f0d97a499",
    title: 'platinum_subscription',
    name: 'Platinum Package',
    type: 'platinum',
    price: '5000',
    description: ['All the awesomeness', 'Up to 1000 Cars', 'Unlimited tasks'],
    buttonText: 'Select Plan',
    buttonVariant: 'outlined',
  },
  {
    _id: "5e3eb47be184676b3a23c455",
    title: 'featured_dealership',
    name: 'Featured Dealership',
    type: 'featured',
    price: '500',
    description: ['Display dealership on our home page', '', ''],
    buttonText: 'Select Plan',
    buttonVariant: 'outlined',
  },
];

const availableLimit = [
  { text: '1 Month', value: 1 },
  { text: '2 Months', value: 2 },
  { text: '3 Months', value: 3 },
  { text: '4 Months', value: 4 },
  { text: '5 Months', value: 5 },
  { text: '6 Months', value: 6 },
  { text: '7 Months', value: 7 },
  { text: '8 Months', value: 8 },
  { text: '9 Months', value: 9 },
  { text: '10 Months', value: 10 },
  { text: '11 Months', value: 11 },
  { text: '1 Year', value: 12 },
  { text: '2 Years', value: 24 },
];

const Subscriptions = (props) => {
  const {
    isAuthenticated,
    user,
    applySubscriptionOrderAction,
    setLoadingAction,
    resetAllSubscriptionOptionAction,
    fetchAllSubscriptionsAction,
    selectedPlanDetail,
    appliedOrderDetail,
    isLoading,
    message,
    hasError,
    hasSuccess,
    subscriptionsData,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    fetchAllSubscriptionsAction();
  }, [fetchAllSubscriptionsAction]);

  useEffect(() => {
    return () => {
      resetAllSubscriptionOptionAction();
    };
  }, [resetAllSubscriptionOptionAction]);

  useEffect(() => {
    if(isAuthenticated && isLoading && hasSuccess && selectedPlanDetail && appliedOrderDetail) {
      const { packageType, months } = selectedPlanDetail;
      const { _id } = appliedOrderDetail;
      const packageDetail = tiers.find(x => x.type === packageType);
      const subscription = subscriptionsData.find(x => x._id === packageDetail._id);
      const payloadParams = {
        merchant_id: process.env.REACT_APP_PAYFAST_MERCHANT_ID,
        merchant_key: process.env.REACT_APP_PAYFAST_MERCHANT_KEY,
        return_url: `${process.env.REACT_APP_PAYMENT_RETURN_URL}`,
        cancel_url: `${process.env.REACT_APP_PAYMENT_CANCEL_URL}`,
        notify_url: `${process.env.REACT_APP_PAYMENT_NOTIFY_URL}`,
        name_first: user.fullname,
        email_address: user.email,
        m_payment_id: makeRandomId(40),
        custom_str1: _id,
        amount: subscription.price * months,
        passphrase: '',
        item_name: packageDetail.name,
        custom_str2: packageType,
        email_confirmation: 1,
        confirmation_address: '',
      };
      // postToURL(process.env.REACT_APP_PAYMENT_SANDBOX_REDIRECT_URL, payloadParams)
      postToURL(process.env.REACT_APP_PAYMENT_LIVE_REDIRECT_URL, payloadParams)
    }
  }, [isAuthenticated, isLoading, hasSuccess, selectedPlanDetail, appliedOrderDetail, user, subscriptionsData])

  const handleFormSubmit = (packageType, months) => {
    if(!isAuthenticated) {
      props.history.push('/login', { packageType, months });
    } else {
      setLoadingAction();
      const packageDetail = tiers.find(x => x.type === packageType);
      const selectedPlanDetail = {
        packageType,
        months
      };

      const payload = {
        subs_id: packageDetail._id,
        duration: months
      };

      applySubscriptionOrderAction(payload, selectedPlanDetail);
    }
  }

  return (
    <div className={`${classes.root} mx-auto mb-5`}>
      <CssBaseline />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Subscriptions | Car Listing Subscription Cost | Chats2Cars</title>
        <meta name="title" content="Subscriptions | Car Listing Subscription Cost | Chats2Cars" />
        {/* <title>Subscriptions | {process.env.REACT_APP_NAME}</title> */}
        <meta property="og:title" content="Subscriptions | Car Listing Subscription Cost | Chats2Cars" />
        <meta name="description" content="Check out the car listing subscription cost - Chat2Cars provide different affordable packages, after subscription you are able to check available car prices." />
        <meta property="og:image" content="https://chat2cars.co.za/logo.png" />

      </Helmet>
      <Breadcrumbs className="mt-4" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
          Home
        </Link>
        <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">Subscriptions</Typography>
      </Breadcrumbs>
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Select Package
        </Typography>
        {hasError &&
          <React.Fragment>
            <Alert className="mt-4" style={{ width: '100%' }} severity="error">
              <AlertTitle>Error</AlertTitle>
              {message}
            </Alert>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="default"
              className={classes.submit}
              component={Link}
              to="/subscriptions"
            >
              Refresh
            </Button>
          </React.Fragment>
        }
      </Container>
      <Container component="main">
        <Grid container justify="center">
          {isLoading ?
            <CircularProgress className="mb-4 mt-3" />
            :
            <Formik
              enableReinitialize
              initialValues={{
                gold: 1,
                promotion: 1,
                platinum: 1,
                featured: 1,
              }}
              onSubmit={handleFormSubmit}
            >
              {(renderProps) => {
                const { values: formValues } = renderProps;
                return (
                  <Form className={classes.form} onSubmit={renderProps.handleSubmit}>
                    <Grid container spacing={3} alignItems="stretch">
                      {tiers.map(tier => {
                        const subscription = subscriptionsData.find(x => x._id === tier._id);
                        return (
                          <Grid item key={tier.title} xs={12} sm={6} md={4}>
                            <Card className={`${classes.card} ${tier.type === 'gold' ? 'makeActive' : ''}`}>
                              <CardHeaderWrapper
                                title={tier.name}
                                subheader={tier.subheader}
                                titleTypographyProps={{ align: 'center' }}
                                subheaderTypographyProps={{ align: 'center' }}
                                action={tier.title === 'Pro' ? <StarIcon /> : null}
                                className={classes.cardHeader}
                              />
                              <CardContent className={classes.cardContent}>
                                <div>
                                  <div className={`${classes.cardPricing} ${tier.type === 'gold' ? 'makePriceActive' : ''}`}>
                                    <Typography component="h2" variant="h4" color="textPrimary">
                                      {formatNumber(subscription.price, 2)}
                                    </Typography>
                                    <Typography variant="h6" color="textSecondary">
                                      /mo
                                    </Typography>
                                  </div>
                                  <ul>
                                    {tier.description.map((line, index) => (
                                      <Typography component="li" variant="subtitle1" align="center" key={`${line}-${index}`}>
                                        {line}
                                      </Typography>
                                    ))}
                                  </ul>
                                </div>
                                {tier.title !== 'basic_subscription' &&
                                  <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      className="select-duration-subscription"
                                      inputProps={{
                                        classes: {
                                          root: classes.border,
                                          icon: classes.icon,
                                        },
                                      }}
                                      value={formValues[tier.type]}
                                      onChange={e => renderProps.setFieldValue(tier.type, e.target.value)}
                                    >
                                      {availableLimit.map(limit => (
                                        <MenuItem key={`${limit.value}-${tier.title}`} value={limit.value}>{limit.text}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                }
                              </CardContent>
                              <CardActions>
                                {/* <Button
                                  className={classes.buyButton}
                                  fullWidth
                                  variant={tier.buttonVariant}
                                  color="primary"
                                  endIcon={<ArrowRightAltIcon />}
                                  onClick={() => handleFormSubmit(tier.type, formValues[tier.type])}
                                >
                                  {tier.buttonText}
                                </Button> */}
                                <Button
                                  fullWidth
                                  variant={tier.buttonVariant}
                                  color="primary"
                                  className={classes.buyButton}
                                  endIcon={<ArrowRightAltIcon />}
                                  onClick={() => handleFormSubmit(tier.type, formValues[tier.type])}
                                >
                                  {tier.buttonText}
                                </Button>
                              </CardActions>
                            </Card>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          }
        </Grid>
      </Container>
    </div>
  )
}

const mapStateToProps = (state) => ({
  ...state.Subscriptions,
  isAuthenticated: state.Login.isAuthenticated,
  user: state.Login.user
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions)
