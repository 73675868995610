import React, { useEffect } from 'react';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(5),
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}));

const PrivacyPolicy = (props) => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <main
      className="mx-1200 my-4 mx-auto"
      style={{ minHeight: 'calc(100vh -  608px)', paddingLeft: 20, paddingRight: 20 }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <Breadcrumbs className="mt-4" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
          Home
        </Link>
        <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">
          Privacy Policy
        </Typography>
      </Breadcrumbs>
      <Paper elevation={3} className={`${classes.paper} shadow-lg`}>
        <Typography className="text-dark font-weight-bold pb-3" component="h2" variant="h5">
          Privacy Policy
        </Typography>
        <div className="privacy-policy__content">
          <p>August 2018</p>
          <p>
            This privacy policy is complementary to and should be read and understood together with, the
              terms and conditions of use of Chat2cars. <br />
            Your privacy is an important factor that Chat2cars (that's us) considers in the development
            of each of our products and services. We are committed to being transparent and open. This
            Chat2cars Privacy Policy explains generally how we receive information about you, and what
            we do with that information once we have it.
            </p>
          <p>
            <strong>What do we mean by "personal information?"(Personal data)</strong>
          </p>
          <p>
            For us, "personal information" means information which identifies you, for example your
            name, plate number of your vehicle, and cell phone number.
            </p>
          <p>Any information that falls outside of this is "non-personal information."</p>
          <p>
            If we store your personal information with information that is non-personal, we will
            consider the combination as personal information.
              <br />If we remove all personal information from a set of data then the remaining is
            non-personal information.
            </p>
          <p>
            <strong>What type of personal information do we collect?</strong>
          </p>
          <ol>
            <li>
              Name, telephone number, plate number of your vehicle, feedback ratings and payment
              information of the app user;
              </li>
            <li>
              Geographical location of the user, the time of provision of service and the destination;
              </li>
            <li>Identification data of the device on which the Chat2cars app has been installed;</li>
            <li>
              Cookies of the Chat2cars website save the website user’s telephone number and geographic
              location data.
              </li>
          </ol>
          <p>
            <strong>The purpose of collecting personal information</strong>
          </p>
          <ol>
            <li>
              We collect and process personal information for the purpose of matching up a user with
              technical support and other services the application and website provide.
              </li>
            <li>
              We forward the data on geographic location and telephone number of the user to the
              technical support team to enable to decide whether the user is located nearby;
              </li>
            <li>
              We may use the personal information to resolve service quality issues relating to
              technical support;
              </li>
            <li>We use contact details to notify you of updates to the Chat2cars app;</li>
            <li>
              We collect data on the routes taken by the Chat2cars app users to analyse the geographic
              coverage in order to make recommendations to the technical support team;
              </li>
            <li>
              We collect personal information to provide your details to the seller of the products
              and /or services you wish to inspect/ purchase;
              </li>
            <li>We collect personal information to contact you in the future at your request;</li>
            <li>
              The personal information collected via the Chat2cars app will not be used for
              identification of persons or their locations, nor for the purpose of behavioural
              marketing.
              </li>
          </ol>
          <p>
            <strong>How do we learn information about you?</strong>
          </p>
          <p>We learn information about you when:</p>
          <ol>
            <li>
              Your Account Information. You provide your plate number or email address to create a
              Chat2cars account. You may also add other information to your account, such as a profile
              name, profile picture, and status message.
              </li>
            <li>
              Your Messages. We do not retain your messages in the ordinary course of providing our
              Services to you. Once your messages (including your chats, photos, videos, voice
              messages, files, and share location information) are delivered, they are deleted from
              our servers. Your messages are stored on your own device. If a message cannot be
              delivered immediately (for example, if you are offline), we may keep it on our servers
              for up to 30 days as we try to deliver it. If a message is still undelivered after 30
              days, we delete it. To improve performance and deliver media messages more efficiently,
              such as when many people are sharing a popular photo or video, we may retain that
              content on our servers for a longer period of time. We also offer end-to-end encryption
              for our Services, which is on by default.
              </li>
            <li>
              Customer Support. You may provide us with information related to your use of our
              Services, including copies of your messages, and how to contact you so we can provide
              you customer support. For example, you may send us an email with information relating to
              our app performance or other issues.
              </li>
            <li>
              Usage and Log Information. We collect service-related, diagnostic, and performance
              information. This includes information about your activity (such as how you use our
              Services, how you interact with others using our Services, and the like), log files, and
              diagnostic, crash, website, and performance logs and reports.
              </li>
            <li>
              Transactional Information. If you pay for our services, we may receive information and
              confirmations, such as payment receipts, including from app stores or other third
              parties processing your payment.
              </li>
            <li>
              Device and Connection Information. We collect device-specific information when you
              install, access, or use our Services. This includes information such as hardware model,
              operating system information, browser information, IP address, mobile network
              information including phone number, and device identifiers. We collect device location
              information if you use our location features, such as when you choose to share your
              location with your contacts, view locations nearby or those others have shared with you,
              and the like, and for diagnostics and troubleshooting purposes such as if you are having
              trouble with our app’s location features.
              </li>
            <li>
              Cookies. We use cookies to operate and provide our Services, including to provide our
              Services that are web-based, improve your experiences, understand how our Services are
              being used, and customize our Services. For example, we use cookies to provide Chat2cars
              for web and desktop and other web-based services. We may also use cookies to understand
              which of our FAQs are most popular and to show you relevant content related to our
              Services. Additionally, we may use cookies to remember your choices, such as your
              language preferences, and otherwise to customize our Services for you.
              </li>
            <li>
              we collect it automatically through our products and services (e.g., when your sign up
              on our browser or mobile applications);
              </li>
            <li>someone else tells us information about you; or</li>
            <li>
              When we try and understand more about you based on information you've given to us e.g.,
              when we use your IP address to customize language for some of our services).
              </li>
          </ol>
          <p>
            <strong>What do we do with your information once we have it?</strong>
          </p>
          <p>
            We use all the information we have to help us operate, provide, improve, understand,
            customize, support, and market our Services.
            </p>
          <ol>
            <li>
              Our Services. We operate and provide our Services, including providing customer support,
              and improving, fixing, and customizing our Services. We understand how people use our
              Services, and analyse and use the information we have to evaluate and improve our
              Services, research, develop, and test new services and features, and conduct
              troubleshooting activities. We also use your information to respond to you when you
              contact us. We use cookies to operate, provide, improve, understand, and customize our
              Services.
              </li>
            <li>
              Safety and Security. We verify accounts and activity, and promote safety and security on
              and off our Services, such as by investigating suspicious activity or violations of our
              Terms, and to ensure our Services are being used legally.
              </li>
            <li>
              Communications about Our Services. We communicate with you about our Services and
              features and let you know about our terms and policies and other important updates. We
              may provide you marketing for our Services.
              </li>
            <li>
              No Third-Party Banner Ads. We do not allow third-party banner ads on Chat2cars.if we
              ever do, we will update this policy.
              </li>
            <li>
              Commercial Messaging. We will allow you and third parties, like businesses and also car
              dealers to communicate with each other using Chat2cars, such as through order,
              transaction, and appointment information, delivery and shipping notifications, product
              and service updates, and marketing. For example, you may receive flight status
              information for upcoming travel, a receipt for something you purchased, or a
              notification when a delivery will be made. Messages you may receive containing marketing
              could include an offer for something that might interest you. We do not want you to have
              a spammy experience; as with all of your messages, you can manage these communications,
              and we will honor the choices you make.
              </li>
            <li>
              When you give us information, we will use it in the ways in which you've given us
              permission. Generally, we use your information to help us provide and improve our
              products and services for you.
              </li>
          </ol>
          <p>
            <strong>When do we share your information with others?</strong>
          </p>
          <ol>
            <li>When we have asked and received your permission to share it.</li>
            <li>
              For processing or providing products and services to you, but only if those entities
              receiving your information are contractually obligated to handle the data in ways that
              are approved by Chat2cars
              </li>
            <li>
              When we are fulfilling our mission of being open. We sometimes release information to
              make our products better and foster an open web, but when we do so, we will remove your
              personal information and try to disclose it in a way that minimizes the risk of you
              being re-identified.
              </li>
            <li>
              When the law requires it. We follow the law whenever we receive requests about you from
              a government or related to a lawsuit. We'll notify you when we're asked to hand over
              your personal information in this way unless we're legally prohibited from doing so.
              When we receive requests like this, we'll only release your personal information if we
              have a good faith belief that the law requires us to do so. Nothing in this policy is
              intended to limit any legal defences or objections that you may have to a third party's
              request to disclose your information.
              </li>
            <li>
              When we believe it is necessary to prevent harm to you or someone else. We will only
              share your information in this way if we have a good faith belief that it is reasonably
              necessary to protect the rights, property or safety of you, our other users, Chat2cars
              or the public.
              </li>
            <li>
              If our organizational structure or status changes (if we undergo a restructuring, are
              acquired, or go bankrupt) we may pass your information to a successor or affiliate.
              </li>
          </ol>
          <p>
            <strong>Managing your Information</strong>
          </p>
          <p>
            If you would like to manage, change, limit, or delete your information, we allow you to do
            that through the following tools:
            </p>
          <ol>
            <li>
              Services Settings. You can change your Services settings to manage certain information
              available to other users. You can manage your contacts, groups, and broadcast lists, or
              use our block feature to manage the users with whom you communicate.
              </li>
            <li>
              Changing Your Plate Number, Username (Profile Name), and Picture, and Status Message.
              You must change your plate number or username using our in-app change number feature and
              transfer your account to your new plate number. You can also change your profile name,
              profile picture, and status message at any time.
              </li>
            <li>
              Deleting Your Chat2cars Account. You may delete your Chat2cars account at any time
              (including if you want to revoke your consent to our use of your information) using our
              in-app delete my account feature. When you delete your Chat2cars account, your
              undelivered messages are deleted from our servers as well as any of your other
              information we no longer need to operate and provide our Services. Be mindful that if
              you only delete our Services from your device without using our in-app delete my account
              feature, your information may be stored with us for a longer period. Please remember
              that when you delete your account, it does not affect the information other users have
              relating to you, such as their copy of the messages you sent them.
              </li>
          </ol>
          <p>
            <strong>
              Personal Information held By or disclosed By You Or Chat2cars to a Third Party
              </strong>
          </p>
          <p>
            Because Chat2cars is not responsible for any representations or information or warranties or
            content on any third party website (including third party websites linked to the Site,
            websites facilitated by us or websites that serve as social networks like Facebook or
            Twitter), Chat2cars does not exercise control over the privacy policies of these third
            parties and you should refer to the privacy policy of these third parties to see how they
            protect your privacy.
            </p>
          <p>
            <strong>How do we store and protect your personal information?</strong>
          </p>
          <ol>
            <li>
              We are committed to protecting your personal information once we have it. We implement
              physical, business and technical security measures. Despite our efforts, if we learn of
              a security breach, we'll notify you so that you can take appropriate protective steps.
              </li>
            <li>
              We also don't want your personal information for any longer than we need it, so we only
              keep it long enough to do what we collected it for. Once we don't need it, we take steps
              to destroy it unless we are required by law to keep it longer.
              </li>
            <li>
              We will hold and process your personal data only for the period your account is
              activated.
              </li>
          </ol>
          <p>
            <strong>What if we change this privacy policy or any of our privacy notices?</strong>
          </p>
          <p>
            We may need to change this policy and our notices. The updates will be posted online. If the
            changes are substantive, we will announce the update through Chat2cars’s usual channels for
            such announcements such as blog posts and forums. Your continued use of the product or
            service after the effective date of such changes constitutes your acceptance of such changes
            </p>

          <p>
            <strong>Which Laws Apply To This Privacy Policy</strong>
          </p>
          <p>
            This privacy policy is governed by the laws of the Republic of South Africa, and you consent
            to the jurisdiction of the South African courts in respect of any dispute which may arise
            out of or in connection with the formation, interpretation, substance or application of this
            privacy policy.
            </p>
        </div>
      </Paper>
    </main>
  );
};

export default PrivacyPolicy;
