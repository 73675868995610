import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const GotoLoginDialog = ({ open, handleClose, history }) => {
  const handleSendToLogin = () => {
    history.push('/login');
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Not Logged In"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You need to be logged in to mark this car as a favorite.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSendToLogin} color="primary" autoFocus>
          Go to Login
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GotoLoginDialog;