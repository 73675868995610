import {
  FETCH_DEALER_DETAIL,
  RESET_DEALER_DETAIL,
  UPDATE_PAGE_NUMBER_FILTER,
  LIKE_UNLIKE_CAR,
  SEARCH_TERM_ADDED,
} from './Constants';
// import { ADDED_NOTIFICATION } from "./../Notification/Constants";
import Api from './../../helpers/ApiHandler';

const api = new Api();

export const fetchDealerDataAction = (id, payload, isProfileLoaded) => async dispatch => {
  // id - dealer slug id
  try {
    let dealerData = undefined;
    if (!isProfileLoaded) {
      dealerData = await api.get(`users/get-dealer-profile/${id}`);
    }
    let carResults = await api.post(`cars/get-car-list`, { data: payload });
    dispatch({
      type: FETCH_DEALER_DETAIL,
      data: {
        dealerCarsData: carResults.success ? carResults.data : [],
        totalResults: carResults.success ? carResults.count_records : 0,
        dealerData: dealerData && dealerData.success ? { ...dealerData.data, soldCarsCount: dealerData.sold_cars_count, listingCarsCount: dealerData.listing_cars_count, } : undefined
      },
    })
  } catch (err) {
    // console.log('err', err);
  }
}

export const updatePageNoAction = (pageNo) => dispatch => {
  dispatch({
    type: UPDATE_PAGE_NUMBER_FILTER,
    data: pageNo
  })
}

export const resetDealerDetailAction = () => dispatch => {
  dispatch({
    type: RESET_DEALER_DETAIL
  });
}

export const handleLikeUnLikeCarAction = (id) => dispatch => {
  api.post(`cars/like-dislike-car`, { data: { car_id: id } })
    .then((result) => {
      if(result.success) {
        dispatch({
          type: LIKE_UNLIKE_CAR,
          data: result.data
        });
      } else {
        // console.log('result', result);
      }
    })
    .catch((err) => {
      // console.log('err', err);
    });
}

export const getSearchedDataAction = () => dispatch => {
  dispatch({
    type: SEARCH_TERM_ADDED
  })
}