import {
  GET_CURRENT_USER_PROFILE,
  // GET_CURRENT_USER_PROFILE_FAILURE,
  UPDATE_CURRENT_USER_PROFILE,
  CAR_LIST_UPDATED,
  FAVOURITE_LIST_UPDATED,
  INQUIRY_LIST_UPDATED,
  // UPDATE_CURRENT_USER_PROFILE_FAILURE,
  RESET_LOADING_FLAG,
  DELETE_MY_CAR,
  UPDATE_CAR_FAV_UNFAV_STATUS,
  CHANGE_CAR_STATUS,
  LOAD_PROVINCES,
  LOAD_CITIES,
} from './Constants';
import { ADDED_NOTIFICATION } from "../Notification/Constants";
import Api from '../../helpers/ApiHandler';

const api = new Api();

export const getCurrentUserProfileAction = (payloadMyCar, payloadFav, payloadInquiry,payloadTestDrive) => dispatch => {
  let profileData = api.get(`users/get-user-profile-data`)
  let subscriptionHistory = api.get(`users/get-my-subscription-history`);
  let favouriteList = api.post(`cars/get-favorite-list`, { data: payloadFav });
  let carList = api.post(`users/my-car-list`, { data: payloadMyCar });
  let inquiryList = api.post(`users/enquiry/my-cars-enquiry-list`, { data: payloadInquiry });
  let testDriveList = api.post(`cars/get-testDrive-list`,{data:payloadTestDrive});
  Promise.all([profileData, subscriptionHistory, favouriteList, carList, inquiryList,testDriveList])
    .then(result => {
      dispatch({
        type: GET_CURRENT_USER_PROFILE,
        data: {
          userData: result[0].success ? result[0].data : undefined,
          subscriptionHistory: result[1].success ? result[1].data : [],
          favouriteList: result[2].success ? result[2].data : [],
          favouriteListTotalResults: result[2].success ? result[2].count_records : 0,
          carList: result[3].success ? result[3].data : [],
          carListTotalResults: result[3].success ? result[3].totalCount : 0,
          inquiryList: result[4].success ? result[4].data : [],
          inquiryListTotalResults: result[4].success ? result[4].totalCount : 0,
          testDriveList: result[5].success ? result[5].data : [],
          testDriveListTotalResults:result[5].success ? result[5].count_records : 0,

        },
      })
    },
    // console.log("favouriteList", favouriteList)
    )
    .catch(err => {
      // console.log('err', err);
    });
}

export const updateCarListAction = (payload) => dispatch => {
  api.post(`users/my-car-list`, { data: payload })
    .then(result => {
      if(result.success) {
        dispatch({
          type: CAR_LIST_UPDATED,
          data: {
            carList: result.data,
            carListTotalResults: result.totalCount,
            carListCurrentPage: payload.page
          },
        })
      } else {
        dispatch({
          type: CAR_LIST_UPDATED,
          data: {
            carList: [],
            carListTotalResults: 0,
            carListCurrentPage: payload.page
          },
        })
      }
    })
    .catch(err => {
      // console.log('err', err);
    });
}

export const updateFavouriteListAction = (payload) => dispatch => {
  api.post(`cars/get-favorite-list`, { data: payload })
    .then(result => {
      if(result.success) {
        dispatch({
          type: FAVOURITE_LIST_UPDATED,
          data: {
            favouriteList: result.data,
            favouriteListTotalResults: result.count_records,
            favouriteListCurrentPage: payload.page
          },
        })
      } else {
        // console.log('result', result);
      }
    })
    .catch(err => {
      // console.log('err', err);
    });
}

export const updateInquiryListAction = (payload) => dispatch => {
  api.post(`users/enquiry/my-cars-enquiry-list`, { data: payload })
    .then(result => {
      if(result.success) {
        dispatch({
          type: INQUIRY_LIST_UPDATED,
          data: {
            inquiryList: result.data,
            inquiryListTotalResults: result.totalCount,
            inquiryListCurrentPage: payload.page
          },
        })
      } else {
        // console.log('result', result);
      }
    })
    .catch(err => {
      // console.log('err', err);
    });
}

export const updateCurrentUserProfileAction = (payload) => dispatch => {
  api.put(`users/update-user-details`, { data: payload, isMultipart: true })
    .then(result => {
      if(result.success) {
        dispatch({
          type: UPDATE_CURRENT_USER_PROFILE,
          data: result.data
        });
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'success',
            message: result.msg,
          },
        })
      } else {
        // dispatch({
        //   type: UPDATE_CURRENT_USER_PROFILE_FAILURE,
        //   data: result,
        // })
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'error',
            message: result.msg,
          },
        })
      }
    })
    .catch(err => {
      // console.log('err', err);
    });
}

export const updateFavouriteStatusAction = (payload) => dispatch => {
  api.post(`cars/like-dislike-car`, { data: payload })
    .then(result => {
      if (result.success) {
        dispatch({
          type: UPDATE_CAR_FAV_UNFAV_STATUS,
          data: payload.car_id,
        })
      } else {
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'error',
            message: result.msg,
          }
        })
      }
    })
    .catch(err => {
      // console.log('err', err);
    });
}

export const deleteMyCarAction = (id) => dispatch => {
  api.delete(`cars/delete-car/${id}`)
    .then(result => {
      if(result.success) {
        dispatch({
          type: DELETE_MY_CAR,
          data: id
        })
      } else {
        // console.log('result', result);
      }
    })
    .catch(err => {
      // console.log('err', err);
    });
}

export const resetLoadingFlagAction = () => dispatch => {
  dispatch({
    type: RESET_LOADING_FLAG
  })
}

export const changeCarStatusAction = (payload) => dispatch => {
  api.post(`cars/update-car-details`, { data: payload })
    .then(result => {
      if(result.success) {
        dispatch({
          type: CHANGE_CAR_STATUS,
          data: result.data
        })
      } else {
        // console.log('result', result);
      }
    })
    .catch(err => {
      // console.log('err', err);
    });
}

export const loadProvincesAction = () => dispatch => {
  api.get('city_state/get-all-states', { skipAuth: true })
    .then((result) => {
      if(result.success) {
        dispatch({
          type: LOAD_PROVINCES,
          data: result.data
        })
      } else {
        // console.log('result', result)
      }
    })
    .catch((err) => {
      // console.log('err', err);
    });
}

export const loadCitiesAction = (id) => dispatch => {
  api.post('city_state/get-cities-by-state-ids', { data: {state_ids: id}, skipAuth: true })
    .then((result) => {
      if(result.success) {
        dispatch({
          type: LOAD_CITIES,
          data: result.data
        })
      } else {
        // console.log('result', result)
      }
    })
    .catch((err) => {
      // console.log('err', err);
    });
}
