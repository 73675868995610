import {
  FETCH_BLOG,
  FETCH_BLOG_ERROR,
  RESET_BLOG,
  FETCH_BLOG_LIST,
  UPDATE_PAGE_NUMBER,
} from "./Constants";

import { ADDED_NOTIFICATION } from "./../Notification/Constants";
import Api from "./../../helpers/ApiHandler";

const api = new Api();

export const fetchBlogDetailAction = (slug) => (dispatch) => {
  api
    .get(`blogs/get-blog-by-slug/${slug}`, { skipAuth: true })
    .then((result) => {
      if (result.success) {
        dispatch({
          type: FETCH_BLOG,
          data: result.data,
        });
      } else {
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: "error",
            message: "Blog not available",
          },
        });
        dispatch({
          type: FETCH_BLOG_ERROR,
        });
      }
    })
    .catch((err) => {
      return err
    });
};

// export const fetchAllBlogData = () => async (dispatch) => {
//   console.log("Action called ::::::::::::");
//   try {
//     let blogs = await api.post(`blogs/list-blogs`, { skipAuth: true });
//     dispatch({
//       type: FETCH_BLOG_LIST,
//       data: {
//         blogsData: blogs.success ? blogs.data : [],
//       },
//     });
//   } catch (err) {
//     console.log("err", err);
//   }
// };

export const fetchAllBlogData = (pageNumber, limit, search) => (dispatch) => {
  // console.log("Action called ::::::::::::");

  api
    .post(`blogs/list-blogs`, {
      skipAuth: true,
      data: { page: pageNumber, limit, search },
    })
    .then((result) => {
      if (result.success) {
        
        dispatch({
          type: FETCH_BLOG_LIST,
          data: {
            blogsData: result.data,
            totalResults: result.count_records,
            searchResults: result.data,

          },
        });
      }
    })
    .catch((err) => {
      return err
    });
};

export const resetBlogDetailAction = () => (dispatch) => {
  dispatch({
    type: RESET_BLOG,
  });
};

export const changePageNumberAction = (pageNumber, limit) => async (
  dispatch
) => {
  let payload = { page: pageNumber, limit: limit };
  
  let blogResult = await api.post(`blogs/list-blogs`, {
    data: payload,
    skipAuth: true,
  });

  //  console.log(blogResult);
  dispatch({
    type: UPDATE_PAGE_NUMBER,
    data: {
      searchResults: blogResult.data,
      totalResults: blogResult.count_records,
      payload: payload,
    },
  });
};

