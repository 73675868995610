import {
  GET_ALL_CHATS,
  RESET_ALL_CHAT_OPTION,
} from './Constants';
import { ADDED_NOTIFICATION } from "./../Notification/Constants";
import Api from './../../helpers/ApiHandler';

const api = new Api();

export const getAllChatsAction = () => dispatch => {
  api.get(`chat/my-chat-list`)
    .then((result) => {
      if(result.success) {
        dispatch({
          type: GET_ALL_CHATS,
          data: result.data
        });
      } else {
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'error',
            message: 'You don\'t have any chats available'
          }
        });
      }
    })
    .catch((err) => {
      // console.log('err', err);
    });
}

export const resetAllChatOptionAction = () => dispatch => {
  dispatch({
    type: RESET_ALL_CHAT_OPTION
  })
}