export const createElementForPayment = (function() {
    // Detect IE using conditional compilation
    // if (/*@cc_on@*/ /*@if (@_win32)!/*@end@*/ false) {
    //   // Translations for attribute names which IE would otherwise choke on
    //   let attrTranslations = {
    //     class: 'className',
    //     for: 'htmlFor'
    //   };

    //   const setAttribute = (element, attr, value) => {
    //     if (attrTranslations.hasOwnProperty(attr)) {
    //       element[attrTranslations[attr]] = value;
    //     } else if (attr == 'style') {
    //       element.style.cssText = value;
    //     } else {
    //       element.setAttribute(attr, value);
    //     }
    //   };

    //   return (tagName, attributes) => {
    //     attributes = attributes || {};

    //     // See http://channel9.msdn.com/Wiki/InternetExplorerProgrammingBugs
    //     if (
    //       attributes.hasOwnProperty('name') ||
    //       attributes.hasOwnProperty('checked') ||
    //       attributes.hasOwnProperty('multiple')
    //     ) {
    //       let tagParts = ['<' + tagName];
    //       if (attributes.hasOwnProperty('name')) {
    //         tagParts[tagParts.length] = ' name="' + attributes.name + '"';
    //         delete attributes.name;
    //       }
    //       if (attributes.hasOwnProperty('checked') && '' + attributes.checked == 'true') {
    //         tagParts[tagParts.length] = ' checked';
    //         delete attributes.checked;
    //       }
    //       if (attributes.hasOwnProperty('multiple') && '' + attributes.multiple == 'true') {
    //         tagParts[tagParts.length] = ' multiple';
    //         delete attributes.multiple;
    //       }
    //       tagParts[tagParts.length] = '>';

    //       let element = document.createElement(tagParts.join(''));
    //     } else {
    //       let element = document.createElement(tagName);
    //     }

    //     for (let attr in attributes) {
    //       if (attributes.hasOwnProperty(attr)) {
    //         setAttribute(element, attr, attributes[attr]);
    //       }
    //     }

    //     return element;
    //   };
    // } else {
    // All other browsers
    return (tagName, attributes) => {
        attributes = attributes || {};
        let element = document.createElement(tagName);
        for (let attr in attributes) {
            if (attributes.hasOwnProperty(attr)) {
                element.setAttribute(attr, attributes[attr]);
            }
        }
        return element;
    };
    // }
})();


export const postToURL = (url, values) => {
    values = values || {};

    let form = createElementForPayment('form', {
        action: url,
        method: 'POST',
        style: 'display: none'
    });

    for (let property in values) {
        if (values.hasOwnProperty(property)) {
            let value = values[property];
            if (value instanceof Array) {
                for (let i = 0, l = value.length; i < l; i++) {
                    form.appendChild(
                        createElementForPayment('input', {
                            type: 'hidden',
                            name: property,
                            value: value[i]
                        })
                    );
                }
            } else {
                form.appendChild(
                    createElementForPayment('input', {
                        type: 'hidden',
                        name: property,
                        value: value
                    })
                );
            }
        }
    }
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
}

export const makeRandomId = (length) => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}