import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// COMPONENTS
import MainLayout from './../components/MainLayout';

// ROUTING COMPONENTS
import RoutesList from './RoutesList';
import withTracker from './../hoc/withTracker';

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        {RoutesList.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={route.render}
            component={(propsComponent) => {
              const RouteComponent = withRouter((withRouterProps) => {
                return (
                  <MainLayout {...propsComponent} {...withRouterProps}>
                    <route.component {...propsComponent} {...withRouterProps} />
                  </MainLayout>
                )
              });
              return <RouteComponent />;
            }}
          />
        ))}
      </Switch>
    );
  }
}

export default withRouter(connect(null, null)(withTracker(Routes)));
