import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  RESET_REGISTER_PARAMS,
  LOAD_PROVINCES,
  LOAD_CITIES,
} from './Constants';

const initialState = {
  hasError: false,
  errorMessage: '',
  user: undefined,
  provinces: [],
  cities: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        hasError: false,
        errorMessage: ''
      }
    case REGISTER_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.data.message
      }
    case LOAD_PROVINCES:
      return {
        ...state,
        provinces: action.data
      }
    case LOAD_CITIES:
      return {
        ...state,
        cities: action.data
      }
    case RESET_REGISTER_PARAMS:
      return {
        ...state,
        hasError: false,
        errorMessage: '',
        provinces: [],
        cities: [],
      }
    default:
      return state
  }
}
