import React, { useEffect, useState,useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Modal from '@material-ui/core/Modal';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Api from '../../helpers/ApiHandler';
// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/PostAd/Actions';

// Document Head Manager
import { Helmet } from "react-helmet";

import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, Redirect } from 'react-router-dom';
import { DropzoneAreaBase } from 'material-ui-dropzone';

// Custom Components
import { SubscribeField, UIDecorLine } from './../../components/Footer/Footer.style';
import { YellowCheckbox } from './../../components/SidebarFilter/index';

import './PostAd.css';
import ProfileImageUpload from './../../components/Profile/ProfileImageUpload';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1200,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  aiBtnParent:{textAlign:"right"},
  aiModalBtnParent:{display:"flex",justifyContent:"center",marginTop:5,marginBottom:8},
  aiModalBtnMargin:{marginLeft:10},

  aiBtnBackground:{background:"#F5C82A",color:"#fff"},
  
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#F5C82A',
    color: '#000',
    fontWeight: 900,
    '&:hover': {
      backgroundColor: '#F5C82A',
      color: '#000',
    }
  },
}));

const PostAdValidationSchema = Yup.object().shape({
  title: Yup.string().required('Please enter a title'),
  condition: Yup.string().required('Please select a condition'),
  year: Yup.string().required('Please select a year'),
  make: Yup.object().required('Please select a make'),
  model: Yup.object().required('Please select a model'),
  bodytype: Yup.string().required('Please select a body type'),
  mileage: Yup.string().required('Please enter mileage'),
  fueltype: Yup.string().required('Please select a fuel type'),
  engine: Yup.string().required('Please enter a engine litre'),
  transmission: Yup.string().required('Please select a transmission'),
  drive: Yup.string().required('Please select a drive'),
  exteriorcolor: Yup.string().required('Please select a exterior color'),
  interiorcolor: Yup.string().required('Please select a interior color'),
  address: Yup.string().required('Please enter a address'),
  carfeatures: Yup.array().min(1, 'Please select atleast one car feature'),
  description: Yup.string().required('Please enter a description'),
  price: Yup.string().required('Please enter a price'),
  images: Yup.array().min(1, 'Please upload atleast 1 image'),
  featured_image: Yup.mixed().required('Please select a featured image'),
});

const PostAdValidationSchemaUpdate = Yup.object().shape({
  title: Yup.string().required('Please enter a title'),
  condition: Yup.string().required('Please select a condition'),
  year: Yup.string().required('Please select a year'),
  make: Yup.object().required('Please select a make'),
  model: Yup.object().required('Please select a model'),
  bodytype: Yup.string().required('Please select a body type'),
  mileage: Yup.string().required('Please enter mileage'),
  fueltype: Yup.string().required('Please select a fuel type'),
  engine: Yup.string().required('Please enter a engine litre'),
  transmission: Yup.string().required('Please select a transmission'),
  drive: Yup.string().required('Please select a drive'),
  exteriorcolor: Yup.string().required('Please select a exterior color'),
  interiorcolor: Yup.string().required('Please select a interior color'),
  address: Yup.string().required('Please enter a address'),
  carfeatures: Yup.array().min(1, 'Please select atleast one car feature'),
  description: Yup.string().required('Please enter a description'),
  price: Yup.string().required('Please enter a price'),
});

const currentYear = (new Date()).getFullYear();
const years = [...new Array(25)].map((value, index) => (currentYear + index - 24).toString());

const exteriorcolors = ['Ash Gold', 'Blue', 'Bottle Green', 'Brown', 'Deep Blue Pearl', 'Dolphin Grey', 'Flame Red', 'Green', 'Grey', 'Maroon', 'Midnight Silver Metallic', 'Navy', 'Obsidian Black Metallic', 'Orange', 'Pearl White', 'Red', 'Red Multi-Coat', 'Silver Metallic', 'Solid Black', 'Solid White', 'Titanium Metallic', 'Turqoise', 'Turquoise', 'Yellow']
const interiorcolors = ['Beige', 'Black', 'Blue', 'Brown', 'Gold', 'Green', 'Grey', 'Jet-Black', 'Lime', 'Magenta', 'Maroon', 'Multi-pattern', 'Navy Blue', 'Orange', 'Pink', 'Purple', 'Red', 'Silver', 'Teal', 'White', 'Yellow'];
const carfeatures = ['A/C: Front', 'A/C: Rear', 'Backup Camera', 'Cruise Control', 'Navigation', 'Power Locks', 'Power Steering', 'AM/FM Stereo', 'CD Player', 'DVD System', 'MP3 Player', 'Portable Audio', 'Premium Audio', 'Airbag: Driver', 'Airbag: Passenger', 'Antilock Brakes', 'Bluetooth', 'Hands-Free', 'Fog Lights', 'Security System', 'Bucket Seats', 'Heated Seats', 'Leather Interior Seats', 'Memory Seats', 'Power Seats', 'Third Row Seats', 'Power Windows', 'Windows Defroster', 'Rear Window', 'Wiper Tinted Glass', 'Alloy Wheels', 'Sunroof', 'Tow Package', 'Trailer Hitch', 'Warranty'];

export const sidebar = () => {
 

  return (
    <React.Fragment>
      <Typography className="font-weight-bold text-dark" component="h3" variant="h6">
        Saftey Tips
      </Typography>
      <UIDecorLine />
      <Typography className="font-size-16" component="h5" variant="h6">
        Posting an ad on Chat2Cars is free! However, all ads must follow our rules:
      </Typography>
      <List className="pt-3">
        <Divider component="li" />
        <ListItem className="pl-0 pr-0" alignItems="flex-start">
          <ListItemText primary={"Make sure you post in the correct category."} />
        </ListItem>
        <Divider component="li" />
        <ListItem className="pl-0 pr-0" alignItems="flex-start">
          <ListItemText primary={"Do not post the same ad more than once or repost an ad within 48 hours."} />
        </ListItem>
        <Divider component="li" />
        <ListItem className="pl-0 pr-0" alignItems="flex-start">
          <ListItemText primary={"Do not upload pictures with watermarks."} />
        </ListItem>
        <Divider component="li" />
        <ListItem className="pl-0 pr-0" alignItems="flex-start">
          <ListItemText primary={"Do not post ads containing multiple items unless it's a package deal."} />
        </ListItem>
        <Divider component="li" />
        <ListItem className="pl-0 pr-0" alignItems="flex-start">
          <ListItemText primary={"Do not put your email or phone numbers in the title or description."} />
        </ListItem>
      </List>
    </React.Fragment>
  );
}
const API_KEY = "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";
const systemMessage = {
  "role": "system", "content": "Act like you are a professional Ad Description Generator."
}
function AlertMsg(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const PostAd = (props) => {
  const {
    isAuthenticated,
    isLoading,
    message,
    hasError,
    hasSuccess,
    editCarData,
    carTypes,
    vehicleBrandsList,
    vehicleModelsList,
    fetchVehicleTypesAction,
    fetchVehicleBrandAction,
    fetchVehicleModelAction,
    resetAllPostAdOptionAction,
    removeModelsAction,
    sendNotificationAction,
    setLoadingAction,
    addCarAction,
    fetchCarDataAction,
    deleteCarImageAction,
    updateCarDataAction,
    match: { params }
  } = props;
  const classes = useStyles();
  const [messages, setMessages] = useState([]);
  const [featuredImageData, setFeaturedImageData] = useState();
  const timeoutRef = useRef(null);
  const [imagesData, setImagesData] = useState([]);
  const [isImageLoading,setIsImageLoading]=useState(false)
  const [isFeaturedImageLoading,setIsFeaturedImageLoading]=useState(false)
  const [isAllImageLoading,setIsAllImageLoading]=useState(false)
  const  [previousAllImages,setPreviousAllImages]=useState([])
  const [showErrorAlert, setShowErrorAlert] = React.useState(false);
  const [showErrorSubscriptionAlert, setShowErrorSubscriptionAlert] = React.useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [fileObjects, setFileObjects] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [allFormValues,setAllFormValues]=useState(null)
  const api = new Api();
  // Your form validation schema and other functions
  const handleOpen = async(values) => {
    try{
    if (values.address.length==0 || values.bodytype.length==0 || values.condition.length==0 || values.drive.length==0 || values.engine.length==0 || values.exteriorcolor.length==0 || values.fueltype.length==0 || values.interiorcolor.length==0 || values.mileage.length==0 ||values.price.length==0 ||values.title.length==0 ||values.transmission.length==0 || values.model==null || values.make==null ){
setShowErrorAlert(true);
return;
    }
    const newMessage = {
      message:`Can you help me write an eye catching and human like description for my ad for a car, the ad title is ${values?.title}, car model is ${values?.model?.modeltitle}, it's status is ${values?.model?.status}, fuel type is ${values?.fueltype}, engine is ${values?.engine}, it' s exterior color is ${values?.exteriorcolor}, it's interior color is ${values?.interiorcolor}, it's mileage is ${values?.mileage}, it's transmission is ${values?.transmission}, it's condition is ${values?.condition}, its drive is ${values.drive} and its sailing price is ${values?.price}`,
      direction: 'outgoing',
      sender: "user"
    };
    const newMessages = [...messages, newMessage];
    setMessages(newMessages);
    // Initial system message to determine ChatGPT functionality
    // How it responds, how it talks, etc.
    setIsTyping(true);
    setOpen(true);
    let apiMessages = newMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message}
    });
    const apiRequestBody = {
      "model": "gpt-3.5-turbo",
      "max_tokens": 3000,
      "temperature": 0.7,
      "messages": [
        systemMessage,  // The system message DEFINES the logic of our chatGPT
        ...apiMessages // The messages from our chat with ChatGPT
      ]
    }
    await fetch("https://api.openai.com/v1/chat/completions", 
    {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + API_KEY,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(apiRequestBody)
    }).then((data) => {
      return data.json();
    }).then((data) => {
      setMessages([...newMessages, {
        message: data?.choices[0]?.message?.content,
        sender: "ChatGPT"
      }]);
      setIsTyping(false);
    });
  }catch(error){
    return error
  }
   };
  const handleClose = () => setOpen(false);
  const bodyType = carTypes.map(x => x.name);
/*images set in case of update*/ 
  useEffect(()=>{
if (editCarData?.images?.length>0){
  setImagesData((prevValue=>([...prevValue,...editCarData.images])))
}
if (editCarData?.featured_image &&editCarData?.featured_image!=""){
  setFeaturedImageData(editCarData?.featured_image)
}
  },[editCarData])
  /*Fuction to save/update car*/ 
  const saveCarData = (values) => {
    const imageFileNames = imagesData.map(image => {
      if (typeof image === 'string') {
        return image.split('/').pop();
      }
      return image.fileName;
    });
      let newValues = {
      ...values,
      make: values.make.maketitle,
      model: values.model.modeltitle,
    }
    const formData = new FormData();
    if(params.id) {
      const car_id = editCarData._id;
      newValues = {
        ...newValues,
        car_id
      }
      Object.keys(newValues).forEach(key => {
        if (key === 'images') {
          imageFileNames.forEach(image => {
            formData.append('images', image);
          });
        }else if(key === 'featured_image') {
          formData.append('featured_image', featuredImageData)
        } else {
          formData.append(key, newValues[key])
        }
      })
     updateCarDataAction(formData)
        } else {
      Object.keys(newValues).forEach(key => {
        if (key === 'images') {
          imageFileNames.forEach(image => {
            formData.append('images', image);
          });
        }else if(key === 'featured_image') {
          formData.append('featured_image', featuredImageData)
        } else {
          formData.append(key, newValues[key])
        }
      })
    addCarAction(formData) 
    }
  };
  /*will save/update car until image completly uploaded*/ 
 const handleFormSubmit = (values) => {
    if (isFeaturedImageLoading || isAllImageLoading) {
      setIsImageLoading(true);
      timeoutRef.current = setTimeout(() => {
        handleFormSubmit(values);
      }, 2000);
    } else {
      setIsFeaturedImageLoading(false);
      setIsAllImageLoading(false)
      saveCarData(values);
  }
  };
  /*stop timeout when all images uploaded*/ 
  useEffect(() => {
    if (!isFeaturedImageLoading && !isAllImageLoading && isImageLoading) {
      clearTimeout(timeoutRef.current);
      handleFormSubmit(allFormValues)
    }
  }, [isFeaturedImageLoading, isAllImageLoading]);
  /*remove duplicate images*/ 
  const removeDuplicates = (files) => {
    const uniqueFiles = [];
    const fileMap = new Map();
    // Convert previousAllImages array to a Map for efficient lookup
    const previousImagesMap = new Map();
    previousAllImages.forEach(file => {
      const fileSignature = `${file.name}-${file.size}`;
      previousImagesMap.set(fileSignature, true);
    });
    files.forEach(file => {
      const fileSignature = `${file.name}-${file.size}`;
      if (!fileMap.has(fileSignature) && !previousImagesMap.has(fileSignature)) {
        fileMap.set(fileSignature, true);
        uniqueFiles.push(file);
      }
    });
    return uniqueFiles;
  }
  /*upload featured & dropzone images*/ 
  const handleImageUpload = (files, key, formValues, setFieldValue) => {
    if (key=="featured_image"){
      setIsFeaturedImageLoading(true)
       var formData = new FormData();
           formData.append(key,files)
           api
           .post('cars/upload-featured-images', { data: formData, isMultipart: true })
           .then((result) => {
             if (result.success){
             setFeaturedImageData(result.data[0])
             setIsFeaturedImageLoading(false)
             }else{
               setShowErrorSubscriptionAlert(true) 
               setIsFeaturedImageLoading(false)
         }
           })
           .catch((err) => {
            console.log(err);
            setIsFeaturedImageLoading(false)
           });
     } else if (key === "images") {
      // Remove duplicates
      setIsAllImageLoading(true)
      const uniqueFiles = removeDuplicates(files);
      const formData = new FormData();
      uniqueFiles.forEach(file => {
        formData.append(key, file);
      });
   if(uniqueFiles.length>0){
    api.post('cars/upload-other-car-images', { data: formData, isMultipart: true })
    .then((result) => {
      if (result.success) {
        setImagesData((prevValue) => ([...prevValue, ...result.data]));
      setPreviousAllImages((prevValue) => ([...prevValue, ...files]));
        setIsAllImageLoading(false)
      } else {
        setShowErrorSubscriptionAlert(true);
        setIsAllImageLoading(false)
      }
    })
    .catch((err) => {
      setIsAllImageLoading(false)
    });
   }    
    }
  };
  /* delete dropzone image from frontend as well as backend */
  const handleDeleteImageDropzone = (deletedFileObject, index) => {
    setFileObjects((prevFileObjects) => {
      const updatedFileObjects = prevFileObjects.filter((_, i) => i !== index);
      return updatedFileObjects;
    });

    setImagesData((prevImagesData) => {
      const updatedImagesData = prevImagesData.filter((item) => item.originalName !== deletedFileObject.file.name);
      return updatedImagesData;
    });
  
    setPreviousAllImages((prevPreviousAllImages) => {
      const updatedPreviousAllImages = prevPreviousAllImages.filter((prevFile) => prevFile.name !== deletedFileObject.file.name);
      return updatedPreviousAllImages;
    });
    if (imagesData.length>0){
   const updatedImagesData = imagesData.filter((item) => item.originalName == deletedFileObject.file.name);
    let fileName=updatedImagesData[0].fileName;
    api.delete(`cars/delete-car-image-backend`, { data:{fileName}  })
    .then(result => {
      console.log(result)
    })
    .catch(err => {
      console.log('err', err);
    })
  }
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowErrorAlert(false);
  };
  const handleCloseSubscriptionAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowErrorSubscriptionAlert(false);
  };
  const onChangeBrand = brand => {
    fetchVehicleModelAction(brand)
  }
  useEffect(() => {
    if(editCarData && vehicleBrandsList.length > 0 && vehicleModelsList.length === 0) {
      const brand = vehicleBrandsList.find(x => x.maketitle === editCarData.make);
      fetchVehicleModelAction(brand);
    }
  }, [editCarData, vehicleBrandsList, vehicleModelsList, fetchVehicleModelAction])
  useEffect(() => {
    fetchVehicleBrandAction();
    fetchVehicleTypesAction();
    if(params.id) {
      fetchCarDataAction(params.id)
    }
    return () => {
      resetAllPostAdOptionAction();
    };
  }, [fetchVehicleBrandAction, fetchVehicleTypesAction, resetAllPostAdOptionAction, fetchCarDataAction, params])
  const handleDeleteImage = (image_url) => {
    const payload = {
      image_url,
      car_id: editCarData._id
    }
    deleteCarImageAction(payload);
  }
  const isCarDataLoading = params.id && !editCarData;
  return (
    !isAuthenticated ?
      <Redirect to="/login" />
      :
      <div className={`${classes.root} mx-auto`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Post Ad | {process.env.REACT_APP_NAME}</title>
        </Helmet>
        <Breadcrumbs className="mt-4" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
            Home
          </Link>
          <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">Post Ad</Typography>
        </Breadcrumbs>
        <Typography className="font-weight-bold mt-2" component="h1" variant="h5">
          Post Your Ad
        </Typography>
        <div className="post-ad__main-div my-4">
          <div className="post-ad__form-block shadow">
            {isLoading || isCarDataLoading ?
              <Container className="my-4 d-flex justify-content-center align-items-center" component="main" style={{ minHeight: 'calc(100vh -  608px)' }} maxWidth="sm">
                <CircularProgress />
              </Container>
              :
              hasError ?
                <React.Fragment>
                  <Alert className="mt-4" style={{ width: '100%' }} severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {message}
                  </Alert>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="default"
                    className={classes.submit}
                    component={Link}
                    to="/post/ad"
                  >
                    Refresh
                  </Button>
                </React.Fragment>
                :
                hasSuccess ?
                  <React.Fragment>
                    <Alert className="mt-4" style={{ width: '100%' }} severity="success">
                      <AlertTitle>{message}</AlertTitle>
                      <p>
                        You can now check it under profile page.
                      </p>
                    </Alert>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="default"
                      className={classes.submit}
                      component={Link}
                      to={params.id ? '/profile' : "/"}
                    >
                      Back to {params.id ? 'Profile' : 'Home'} Page
                    </Button>
                  </React.Fragment>
                  :
                  <Formik
                    enableReinitialize
                    initialValues={{
                      title: editCarData ? editCarData.title : '',
                      condition: editCarData ? editCarData.condition : '',
                      year: editCarData ? editCarData.year : '',
                      make: editCarData ? { maketitle: editCarData.make } : null,
                      model: editCarData ? { modeltitle: editCarData.model } : null,
                      bodytype: editCarData ? editCarData.bodytype : '',
                      mileage: editCarData ? editCarData.mileage : '',
                      fueltype: editCarData ? editCarData.fueltype : '',
                      engine: editCarData ? editCarData.engine : '',
                      transmission: editCarData ? editCarData.transmission : '',
                      drive: editCarData ? editCarData.drive : '',
                      exteriorcolor: editCarData ? editCarData.exteriorcolor : '',
                      interiorcolor: editCarData ? editCarData.interiorcolor : '',
                      address: editCarData ? editCarData.address : '',
                      carfeatures: editCarData ? editCarData.carfeatures : [],
                      description: editCarData ? editCarData.description : '',
                      price: editCarData ? editCarData.price : '',
                      images: [],
                      featured_image: undefined,
                    }}
                    onSubmit={handleFormSubmit}
                    validationSchema={params.id ? PostAdValidationSchemaUpdate : PostAdValidationSchema}
                    validateOnChange={true}
                  >
                    {(renderProps) => {
                      const { values: formValues, touched, errors } = renderProps;
                      setAllFormValues(formValues)
                      return (
                        <Form className={classes.form}>                      
                        <Snackbar open={showErrorAlert} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleCloseAlert}>
                        <AlertMsg onClose={handleCloseAlert} severity="error">
                         Fill all the fields!
                        </AlertMsg>
                      </Snackbar>
                      <Snackbar open={showErrorSubscriptionAlert} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={handleCloseSubscriptionAlert}>
                        <AlertMsg onClose={handleCloseSubscriptionAlert} severity="error">
                        Oops! Sorry! You dont have subscription left!
                        </AlertMsg>
                      </Snackbar> 
                      {isImageLoading ? 
                        <Container className="my-4 d-flex justify-content-center align-items-center" component="main" style={{ minHeight: 'calc(100vh -  608px)' }} maxWidth="sm">
                      <CircularProgress />
                    </Container>:<>
                    <div className="mt-3">
                    <FormLabel className="font-size-14 mb-2" component="legend">Featured Image</FormLabel>
                    <ProfileImageUpload
                      className="business-profile"
                      uploadFile={(file) => {renderProps.setFieldValue('featured_image', file);
                      handleImageUpload(file, 'featured_image', formValues, renderProps.setFieldValue)}}
                      receiveError={(err) => sendNotificationAction('error', err)}
                      // needValidation
                    />
                    <ErrorMessage className="text-error font-size-12 mt-2" name="featured_image" component="div" />
                  </div>
                  {editCarData &&
                    <div className="mt-3 delete-images">
                      {editCarData.images.map(image => {
                        return (
                          <div key={image} className="position-relative delete-image-div">
                            <img className="object-fit-cover" src={image} width={100} height={100} alt="" />
                            <IconButton onClick={() => handleDeleteImage(image)} aria-label="delete" className="delete-image-button">
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        )
                      })}
                    </div>
                  }
                  <DropzoneAreaBase
                  fileObjects={fileObjects}
                    dropzoneClass="mt-3"
                    onAdd={newFileObjs => {
                      setFileObjects([].concat(fileObjects, newFileObjs));
                    }}
                    onDelete={(deletedFileObject, index) => {
                      handleDeleteImageDropzone(deletedFileObject, index);
                    }}
                    showPreviewsInDropzone={true}
                    showFileNamesInPreview={true}
                    onDrop={(files) => {
                      renderProps.setFieldValue('images', files);
                      handleImageUpload(files, 'images', formValues, renderProps.setFieldValue);
                    }}
                    maxFileSize={30000000}
                    filesLimit={10}
                  />
                  <ErrorMessage className="text-error font-size-12 mt-2" name="images" component="div" />
                          <SubscribeField
                            variant="outlined"
                            className="news-letter-input"
                            label="Car Title"
                            style={{ width: '100%' }}
                            margin="normal"
                            fullWidth
                            id="title"
                            name="title"
                            autoFocus
                            newsletter={true}
                            value={formValues.title}
                            onChange={e => renderProps.setFieldValue('title', e.target.value)}
                            helperText={touched.title && errors.title}
                            error={touched.title && errors.title ? true : false}
                          />
                          <div className="post-ad__multiple-fields">
                            <Autocomplete
                              options={['Used', 'New']}
                              getOptionLabel={option => option}
                              autoComplete
                              includeInputInList
                              renderInput={params =>
                                <SubscribeField
                                  {...params}
                                  variant="outlined"
                                  label="Condition"
                                  className="news-letter-input mt-0"
                                  margin="normal"
                                  fullWidth
                                  newsletter={true}
                                  helperText={touched.condition && errors.condition}
                                  error={touched.condition && errors.condition ? true : false}
                                />
                              }
                              value={formValues.condition === '' ? null : formValues.condition}
                              onChange={(event, value) => renderProps.setFieldValue('condition', value)}
                            />
                            <Autocomplete
                              options={years}
                              getOptionLabel={option => option.toString()}
                              autoComplete
                              includeInputInList
                              renderInput={params =>
                                <SubscribeField
                                  {...params}
                                  variant="outlined"
                                  label="Year"
                                  className="news-letter-input mt-0"
                                  margin="normal"
                                  fullWidth
                                  newsletter={true}
                                  helperText={touched.year && errors.year}
                                  error={touched.year && errors.year ? true : false}
                                />
                              }
                              value={formValues.year === '' ? null : formValues.year}
                              onChange={(event, value) => renderProps.setFieldValue('year', value)}
                            />
                          </div>
                        <div className="post-ad__multiple-fields">
                            <Autocomplete
                              options={vehicleBrandsList}
                              getOptionLabel={option => option.maketitle}
                              renderInput={params => (
                                <SubscribeField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Make"
                                  fullWidth
                                  label="Make"
                                  className="news-letter-input mt-0"
                                  margin="normal"
                                  newsletter={true}
                                  helperText={touched.make && errors.make}
                                  error={touched.make && errors.make ? true : false}
                                />
                              )}
                              value={formValues.make}
                              onChange={(event, value) => {
                                if (value) {
                                  onChangeBrand(value);
                                } else {
                                  removeModelsAction();
                                }
                                renderProps.setFieldValue('make', value);
                                renderProps.setFieldValue('model', null);
                              }}
                            />
                            <Autocomplete
                              options={vehicleModelsList}
                              getOptionLabel={option => option.modeltitle}
                              renderInput={params => (
                                <SubscribeField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Model"
                                  fullWidth
                                  label="Model"
                                  className="news-letter-input mt-0"
                                  margin="normal"
                                  newsletter={true}
                                  helperText={touched.model && errors.model}
                                  error={touched.model && errors.model ? true : false}
                                />
                              )}
                              value={formValues.model}
                              onChange={(event, value) => renderProps.setFieldValue('model', value)}
                            />
                          </div>
                          <div className="post-ad__multiple-fields">
                            <Autocomplete
                              options={bodyType}
                              getOptionLabel={option => option}
                              renderInput={params => (
                                <SubscribeField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Body Type"
                                  fullWidth
                                  label="Body Type"
                                  className="news-letter-input mt-0"
                                  margin="normal"
                                  newsletter={true}
                                  helperText={touched.bodytype && errors.bodytype}
                                  error={touched.bodytype && errors.bodytype ? true : false}
                                />
                              )}
                              value={formValues.bodytype}
                              onChange={(event, value) => renderProps.setFieldValue('bodytype', value)}
                            />
                            <SubscribeField
                              variant="outlined"
                              className="news-letter-input mt-0"
                              label="Mileage"
                              style={{ width: '100%' }}
                              margin="normal"
                              fullWidth
                              name="mileage"
                              newsletter={true}
                              value={formValues.mileage}
                              onChange={e => renderProps.setFieldValue('mileage', e.target.value)}
                              helperText={touched.mileage && errors.mileage}
                              error={touched.mileage && errors.mileage ? true : false}
                            />
                          </div>
                          <div className="post-ad__multiple-fields">
                            <Autocomplete
                              options={['Diesel', 'Electric', 'Ethanol', 'Petrol']}
                              getOptionLabel={option => option}
                              renderInput={params => (
                                <SubscribeField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Fuel Type"
                                  fullWidth
                                  label="Fuel Type"
                                  className="news-letter-input mt-0"
                                  margin="normal"
                                  newsletter={true}
                                  helperText={touched.fueltype && errors.fueltype}
                                  error={touched.fueltype && errors.fueltype ? true : false}
                                />
                              )}
                              value={formValues.fueltype}
                              onChange={(event, value) => renderProps.setFieldValue('fueltype', value)}
                            />
                            <SubscribeField
                              variant="outlined"
                              className="news-letter-input mt-0"
                              label="Engine"
                              style={{ width: '100%' }}
                              margin="normal"
                              fullWidth
                              id="engine"
                              name="engine"
                              newsletter={true}
                              value={formValues.engine}
                              onChange={e => renderProps.setFieldValue('engine', e.target.value)}
                              helperText={touched.engine && errors.engine}
                              error={touched.engine && errors.engine ? true : false}
                            />
                          </div>
                          <div className="post-ad__multiple-fields">
                            <Autocomplete
                              options={['Automatic', 'Manual', 'Semi-Autometic']}
                              getOptionLabel={option => option}
                              renderInput={params => (
                                <SubscribeField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Transmission"
                                  fullWidth
                                  label="Transmission"
                                  className="news-letter-input mt-0"
                                  margin="normal"
                                  newsletter={true}
                                  helperText={touched.transmission && errors.transmission}
                                  error={touched.transmission && errors.transmission ? true : false}
                                />
                              )}
                              value={formValues.transmission}
                              onChange={(event, value) => renderProps.setFieldValue('transmission', value)}
                            />
                            <Autocomplete
                              options={['4WD', 'AWD', 'FWD', 'RWD']}
                              getOptionLabel={option => option}
                              renderInput={params => (
                                <SubscribeField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Drive"
                                  fullWidth
                                  label="Drive"
                                  className="news-letter-input mt-0"
                                  margin="normal"
                                  newsletter={true}
                                  helperText={touched.drive && errors.drive}
                                  error={touched.drive && errors.drive ? true : false}
                                />
                              )}
                              value={formValues.drive}
                              onChange={(event, value) => renderProps.setFieldValue('drive', value)}
                            />
                          </div>
                          <div className="post-ad__multiple-fields">
                            <Autocomplete
                              options={exteriorcolors}
                              getOptionLabel={option => option}
                              renderInput={params => (
                                <SubscribeField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Exterior Color"
                                  fullWidth
                                  label="Exterior Color"
                                  className="news-letter-input mt-0"
                                  margin="normal"
                                  newsletter={true}
                                  helperText={touched.exteriorcolor && errors.exteriorcolor}
                                  error={touched.exteriorcolor && errors.exteriorcolor ? true : false}
                                />
                              )}
                              value={formValues.exteriorcolor}
                              onChange={(event, value) => renderProps.setFieldValue('exteriorcolor', value)}
                            />
                            <Autocomplete
                              options={interiorcolors}
                              getOptionLabel={option => option}
                              renderInput={params => (
                                <SubscribeField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Interior Color"
                                  fullWidth
                                  label="Interior Color"
                                  className="news-letter-input mt-0"
                                  margin="normal"
                                  newsletter={true}
                                  helperText={touched.interiorcolor && errors.interiorcolor}
                                  error={touched.interiorcolor && errors.interiorcolor ? true : false}
                                />
                              )}
                              value={formValues.interiorcolor}
                              onChange={(event, value) => renderProps.setFieldValue('interiorcolor', value)}
                            />
                          </div>
                          <SubscribeField
                            variant="outlined"
                            className="news-letter-input"
                            label="Zip Code or Address"
                            style={{ width: '100%' }}
                            margin="normal"
                            fullWidth
                            name="address"
                            newsletter={true}
                            value={formValues.address}
                            onChange={e => renderProps.setFieldValue('address', e.target.value)}
                            helperText={touched.address && errors.address}
                            error={touched.address && errors.address ? true : false}
                          />
                          <SubscribeField
                            variant="outlined"
                            className="news-letter-input"
                            label="Price"
                            style={{ width: '100%' }}
                            margin="normal"
                            fullWidth
                            name="price"
                            newsletter={true}
                            InputProps={{
                              type: 'number',
                            }}
                            value={formValues.price}
                            onChange={e => renderProps.setFieldValue('price', e.target.value)}
                            helperText={touched.price && errors.price}
                            error={touched.price && errors.price ? true : false}
                          />
                          <div className={classes.aiBtnParent}>
                          <Button className={classes.aiBtnBackground} variant="contained" onClick={()=>handleOpen(formValues)}>Generate AI Description</Button>
                          </div>
                          <SubscribeField
                            variant="outlined"
                            className="news-letter-input"
                            label="Ad Description"
                            style={{ width: '100%' }}
                            margin="normal"
                            fullWidth
                            id="description"
                            name="description"
                            multiline
                            rowsMax="5"
                            rows="5"
                            newsletter={true}
                            value={formValues.description}
                            onChange={e => renderProps.setFieldValue('description', e.target.value)}
                            helperText={touched.description && errors.description}
                            error={touched.description && errors.description ? true : false}
                          />
                          <div className="mt-3">
                          <FormLabel className="font-size-14 mb-2" component="legend">Featured Image {params.id ? '(Uploading featured image will replace old image)' : ''}</FormLabel>
                          <div className="d-flex flex-wrap">
                            {carfeatures.map(feature => {
                              return (
                                <FormControlLabel
                                  key={feature}
                                  className="d-block car-feature-checkbox"
                                  control={
                                    <YellowCheckbox
                                      checked={formValues.carfeatures.includes(feature)}
                                      onChange={(e) => renderProps.setFieldValue('carfeatures', formValues.carfeatures.includes(feature) ? formValues.carfeatures.filter(x => x !== feature) : [...formValues.carfeatures, feature])}
                                    />
                                  }
                                  label={feature}
                                />
                              )
                            })}
                          </div>
                          <ErrorMessage className="text-error font-size-12 mt-2" name="carfeatures" component="div" />
                        </div>
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="default"
                        className={classes.submit}
                      >
                        {params.id ? 'Update My Ad' : 'Publish My Ad'}
                      </Button>
                          </>}       
                          {/* <Typography style={{color:"red"}}>{messageData}</Typography> */}
                          <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="simple-modal-title"
                          aria-describedby="simple-modal-description"
                        >
                          <div className="aiModal">
                            <h4>AI Generated Message</h4>

                            <div className='aiMessageParent'>
                            {isTyping  ?
                              <Container className="my-4 d-flex justify-content-center align-items-center" component="main" style={{ minHeight: 'calc(100vh -  608px)' }} maxWidth="sm">
                                <CircularProgress />
                              </Container>:
                           <p> {messages.length>0&&messages[messages.length - 1].message}</p>
                            }
                          </div>
                          <div className={classes.aiModalBtnParent}>
                          <Button className={classes.aiBtnBackground} variant="contained" onClick={()=>{
                            if(!isTyping){
                              renderProps.setFieldValue('description', messages[messages.length - 1].message);
                            }
                            setOpen(false)
                          }}>Use</Button>
                          <Button className={[classes.aiBtnBackground,classes.aiModalBtnMargin]} variant="contained" onClick={()=>handleOpen(formValues)} >Regenerate</Button>
   </div>
     </div>
                        </Modal>
                       
                        </Form>
                      )
                    }}
                 
                  </Formik>
            }
          </div>
          <div className="post-ad__sidebar shadow">
            {sidebar()}
          </div>
         
        </div>
      </div>
  )
}

const mapStateToProps = (state) => ({
  ...state.PostAd,
  isAuthenticated: state.Login.isAuthenticated
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostAd)