import React from 'react'

import Skeleton from '@material-ui/lab/Skeleton';

const SidebarFilterSkeleton = () => {
  return (
    <div className={`car-results__filter p-2`}>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
    </div>
  )
}

export default SidebarFilterSkeleton;
