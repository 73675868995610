import {
  FETCH_BLOG,
  FETCH_BLOG_ERROR,
  RESET_BLOG,
  FETCH_BLOG_LIST,
  UPDATE_PAGE_NUMBER,
  
} from "./Constants";

const initialState = {
  isLoading: true,
  blogData: undefined,
  blogsData: [],
  hasError: false,
  totalResults: 0,
  currentPage: 1,
  limit: 12,
  
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BLOG:
      return {
        ...state,
        blogData: action.data,
        isLoading: false,
      };
    case FETCH_BLOG_LIST:
      // console.log("action.data", action.data);
      return {
        ...state,
        blogData: action.data,
        isLoading: false,
        currentPage: 1,
        limit: 12,
        totalResults:action.data.totalResults
      };
    case FETCH_BLOG_ERROR:
      return {
        ...state,
        hasError: true,
      };
    case RESET_BLOG:
      return {
        ...state,
        isLoading: true,
        blogData: undefined,
        hasError: false,
        currentPage: 1,
        limit: 12
      };
      case UPDATE_PAGE_NUMBER:
      
      return {
        ...state,
        isloading: true,
        currentPage: action.data.payload.page,
        // totalResults:action.data.totalResults,
        searchResults:action.data.searchResults,
        
      }
      
    default:
      return state;
  }
};
