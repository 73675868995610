import {
  FETCH_SUBSCRIPTIONS,
  APPLY_SUBSCRIPTION_ORDER,
  APPLY_SUBSCRIPTION_ORDER_FAILURE,
  RESET_SUBSCRIPTIONS_OPTION,
  SET_LOADING,
} from './Constants';
import Api from '../../helpers/ApiHandler';

const api = new Api();

export const fetchAllSubscriptionsAction = () => dispatch => {
  api.get(`admin_master/get-subscription-list`)
    .then(result => {
      if (result.success) {
        dispatch({
          type: FETCH_SUBSCRIPTIONS,
          data: {
            subscriptionsData: result.data,
          }
        })
      } else {
        dispatch({
          type: FETCH_SUBSCRIPTIONS,
          data: {
            subscriptionsData: [],
          }
        })
      }
    })
    .catch(err => {
      // console.log('err', err)
    });
}

export const applySubscriptionOrderAction = (payload, selectedPlanDetail) => dispatch => {
  api.post('users/apply-subscription-order', { data: payload })
    .then((result) => {
      if(result.success) {
        dispatch({
          type: APPLY_SUBSCRIPTION_ORDER,
          data: {
            appliedOrderDetail: result.data,
            selectedPlanDetail
          }
        })
      } else {
        dispatch({
          type: APPLY_SUBSCRIPTION_ORDER_FAILURE,
          data: result.msg
        })
      }
    })
    .catch((err) => {
      // console.log('err', err);
    });
}

export const setLoadingAction = () => dispatch => {
  dispatch({
    type: SET_LOADING
  })
}

export const resetAllSubscriptionOptionAction = () => dispatch => {
  dispatch({
    type: RESET_SUBSCRIPTIONS_OPTION
  })
}
