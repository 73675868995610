import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  RESET_REGISTER_PARAMS,
  LOAD_PROVINCES,
  LOAD_CITIES,
} from './Constants';
import { LOGIN_SUCCESS } from './../Login/Constants';
// import { ADDED_NOTIFICATION } from "./../Notification/Constants";
import Api from '../../helpers/ApiHandler';

const api = new Api();

export const handleRegisterRequestAction = (payload, history) => dispatch => {
  api.post('users/sign-up', { data: payload })
    .then((result) => {
      if(result.success) {
        dispatch({
          type: REGISTER_SUCCESS,
        })
      history.push('/login'); 
        // dispatch({
        //   type: LOGIN_SUCCESS,
        //   data: {
        //     user: result.data.UserDetails,
        //     token: result.data.token
        //   }
        // })
      } else {
        dispatch({
          type: REGISTER_FAILURE,
          data: {
            message: result.msg
          }
        })
      }
    })
    .catch((err) => {
      // console.log('err', err);
    });
}

export const loadProvincesAction = () => dispatch => {
  api.get('city_state/get-all-states', { skipAuth: true })
    .then((result) => {
      if(result.success) {
        dispatch({
          type: LOAD_PROVINCES,
          data: result.data
        })
      } else {
        // console.log('result', result)
      }
    })
    .catch((err) => {
      // console.log('err', err);
    });
}

export const loadCitiesAction = (id) => dispatch => {
  api.post('city_state/get-cities-by-state-ids', { data: {state_ids: id}, skipAuth: true })
    .then((result) => {
      if(result.success) {
        dispatch({
          type: LOAD_CITIES,
          data: result.data
        })
      } else {
        // console.log('result', result)
      }
    })
    .catch((err) => {
      // console.log('err', err);
    });
}

export const resetRegisterParamsAction = () => dispatch => {
  dispatch({
    type: RESET_REGISTER_PARAMS
  })
}