import React, { Component } from 'react';
import Header from './../Header';
import Footer from './../Footer';
import Notification from './../Notification/index';

class MainLayout extends Component {
  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        <Header {...this.props} />
        {children}
        <Footer />
        <Notification />
      </React.Fragment>
    )
  }
}

export default MainLayout;