export const FETCH_VEHICLE_TYPES_POSTAD = 'PostAd/FETCH_VEHICLE_TYPES_POSTAD';
export const FETCH_VEHICLE_BRAND_POSTAD = 'PostAd/FETCH_VEHICLE_BRAND_POSTAD';
export const FETCH_VEHICLE_MODEL_POSTAD = 'PostAd/FETCH_VEHICLE_MODEL_POSTAD';
export const FETCH_CAR_DATA = 'PostAd/FETCH_CAR_DATA';
export const FETCH_CAR_DATA_FAILURE = 'PostAd/FETCH_CAR_DATA_FAILURE';
export const REMOVE_MODEL_OPTIONS = 'PostAd/REMOVE_MODEL_OPTIONS';
export const RESET_POSTAD_OPTION = 'PostAd/RESET_POSTAD_OPTION';
export const ADD_CAR = 'PostAd/ADD_CAR';
export const ADD_CAR_FAILURE = 'PostAd/ADD_CAR_FAILURE';
export const UPDATE_CAR = 'PostAd/UPDATE_CAR';
export const UPDATE_CAR_FAILURE = 'PostAd/UPDATE_CAR_FAILURE';
export const SET_LOADING = 'PostAd/SET_LOADING';
export const DELETE_CAR_IMAGE = 'PostAd/DELETE_CAR_IMAGE';