import React from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

export const FooterWrapper = styled.footer`
  position: relative;
  padding-top: ${props => props.isabout ? '35px' : '75px'};
  padding-bottom: ${props => props.isabout ? '35px' : '85px'};
  color: #cfcfcf;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* background-color: #111;
    opacity: 0.8; */
    background-color: #000;
    opacity: 1;
  }
`;

export const UIDecorLine = styled.i`
  display: inline-block;
  position: relative;
  width: 100px;
  height: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 20px;
  background-color: ${props => props.forfeatured ? '#000' : '#F5C82A'};
`;

export const SubscribeField = styled((({newsletter, ...props}) => <TextField {...props} />))`
  fieldset, fieldset:hover, fieldset:focus, legend, .MuiInputBase-formControl:hover, .MuiFormLabel-root.Mui-focused {
    border-color: ${props => props.newsletter ? '#000' : '#fff !important'};
    color: ${props => props.newsletter ? '#000' : '#fff !important'};
  }

  .MuiInput-underline:after {
    border-color: ${props => props.newsletter ? '#000' : '#fff !important'};
  }

  .MuiInput-underline:before {
    border-color: ${props => props.newsletter ? '#000' : '#fff !important'};
  }

  .MuiInputBase-input {
    color: ${props => props.newsletter ? '#000' : '#fff !important'};
  }

  label {
    color: ${props => props.newsletter ? '#000000b3 !important' : '#ffffff61 !important'};
  }
`;
