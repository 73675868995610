import {
  FETCH_DEALER_DETAIL,
  RESET_DEALER_DETAIL,
  UPDATE_PAGE_NUMBER_FILTER,
  LIKE_UNLIKE_CAR,
  SEARCH_TERM_ADDED,
} from './Constants';

const initialState = {
  dealerData: undefined,
  isLoading: true,
  isLoadingResults: true,
  dealerCarsData: [],
  totalResults: 0,
  filterParams: {
    currentPage: 1,
    limit: 9
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEALER_DETAIL:
      return {
        ...state,
        dealerData: action.data.dealerData ? action.data.dealerData : state.dealerData,
        dealerCarsData: action.data.dealerCarsData,
        totalResults: action.data.totalResults,
        isLoading: false,
        isLoadingResults: false
      }
    case UPDATE_PAGE_NUMBER_FILTER:
      return {
        ...state,
        filterParams: {
          ...state.filterParams,
          currentPage: action.data
        },
        isLoadingResults: true
      }
    case LIKE_UNLIKE_CAR:
      return {
        ...state,
        dealerCarsData: state.dealerCarsData.map(x => {
          if(x._id === action.data._id) {
            return action.data
          }

          return x;
        })
      }
    case SEARCH_TERM_ADDED:
      return {
        ...state,
        isLoadingResults: true,
        filterParams: {
          currentPage: 1,
          limit: 9
        },
      }
    case RESET_DEALER_DETAIL:
      return {
        dealerData: undefined,
        isLoading: true,
        isLoadingResults: true,
        dealerCarsData: [],
        totalResults: 0,
        filterParams: {
          currentPage: 1,
          limit: 9
        }
      }
    default:
      return state
  }
}
