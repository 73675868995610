import {
  SAVE_CONTACT_DETAIL,
  SAVE_CONTACT_DETAIL_FAILURE,
  SET_LOADING,
  RESET_CONTACT_OPTION,
} from './Constants';

const initialState = {
  isLoading: false,
  hasError: false,
  hasSuccess: false,
  message: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case SAVE_CONTACT_DETAIL:
      return {
        ...state,
        message: action.data,
        isLoading: false,
        hasError: false,
        hasSuccess: true,
      };
    case SAVE_CONTACT_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        hasSuccess: false,
        message: action.data
      }
    case RESET_CONTACT_OPTION:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        hasSuccess: false,
        message: '',
      }
    default:
      return state;
  }
}