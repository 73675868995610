import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { UIDecorLine } from '../Footer/Footer.style';
import { SliderWrapper } from './../LatestListings/LatestListing.style';

import { Link } from 'react-router-dom';

import './featured.css';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  media: {
    height: 270,
    objectFit: 'cover',
    width: '100%',
  },
  imgResponsive: {
    display: 'block',
    maxWidth: '90%',
  }
}));

const FeaturedListing = ({ classNames, featuredDealerships }) => {
  const featuredLength = featuredDealerships.length;
  const classes = useStyles();

  const options = {
    slidesToShow: featuredLength >= 3 ? 3 : 1,
    slidesToScroll: featuredLength >= 3 ? 3 : 1,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: featuredLength >= 3 ? 2 : 1,
          slidesToScroll: featuredLength >= 3 ? 2 : 1,
          autoplaySpeed: 3000,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 3000,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 3000,
        }
      }
    ]
  };

  return (
    <section className={`latest-listings latest-featured-listings pt-5 pb-5`}>
      <div className={classNames}>
        <div className="section-head ml-3">
          <h2 className="text-uppercase section-title">
            Featured Dealerships
          </h2>
          <UIDecorLine forfeatured />
        </div>
        <div className={classes.root}>
          <SliderWrapper className="mt-3" {...options} noMaxHeight isFeaturedDealership>
            {featuredDealerships.map(image => {
              return (
                <div key={`featured-listing-${image._id}`} className="mx-auto" style={{ outline: 'none' }}>
                  <Link to={`/dealer/${image._id}`}>
                    <img className={`${classes.imgResponsive} mx-auto ${classes.media}`} src={image.avatarurl} alt={image.fullname} />
                  </Link>
                </div>
              )
            })}
          </SliderWrapper>
        </div>
      </div>
    </section>
  )
}

export default FeaturedListing
