import React, { Component } from 'react';

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/CarDetail/Actions';

import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

// Custom Components
import SingleCarSlider from '../../components/SingleCarSlider';
import SingleCarKeyFeatures from '../../components/SingleCarKeyFeatures';
import SingleCarDescription from '../../components/SingleCarDescription';
import SingleCarSidebar from '../../components/SingleCarSidebar';

import formatNumber from './../../helpers/formatNumber';
import { Link } from 'react-router-dom';

import firebase from 'firebase/app';
import '@firebase/firestore';

import Helmet from 'react-helmet';

import './CarDetail.css';

const styles = (theme) => ({
  root: {
    maxWidth: 1200
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#F5C82A',
    color: '#000',
    fontWeight: 900,
    '&:hover': {
      backgroundColor: '#F5C82A',
      color: '#000',
    }
  },
});

class CarDetail extends Component {

  componentWillMount() {
    const { match: { params }, fetchCarDataAction } = this.props;
    fetchCarDataAction(params.slug)
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  async componentDidUpdate() {
    if (this.props.isMessageRequestSent) {
      const chatExists = await this.chatExists();
      chatExists ? this.sendMessageToOldChat() : this.newChatSubmit();
      this.props.history.push('/chat');
    }
  }

  componentWillUnmount() {
    this.props.resetAllCarDetailOptionAction();
  }
  handleSubmitData = (values) => {
    const { sendMessageToSellerAction } = this.props;
    sendMessageToSellerAction(values);
    // console.log(values);
  }

  handleContinueToChat = () => {
    const { isAuthenticated, user, carData, sendMessageRequest, sendNotificationAction, history } = this.props;
    if (isAuthenticated) {
      if (carData.creator._id === user.id) {
        sendNotificationAction('error', 'You cannot send message to yourself.');
      } else {
        const payload = {
          reciever: carData.creator._id
        }
        sendMessageRequest(payload);
      }
    } else {
      history.push('/login');
    }
  }

  buildDocKey = (sender, receiver) => [sender, receiver].sort().join(':');

  sendMessageToOldChat = async () => {
    const { carData, user } = this.props;
    const docKey = this.buildDocKey(user.id, carData.creator._id);
    await
      firebase
        .firestore()
        .collection('chats')
        .doc(docKey)
        .update({
          messages: firebase.firestore.FieldValue.arrayUnion({
            sender: user.id,
            message: `Hey I want to know about the car ${carData.title} you have posted.`,
            timestamp: Date.now()
          }),
          receiverHasRead: false
        });
  }

  newChatSubmit = async () => {
    const { carData, user } = this.props;
    const docKey = this.buildDocKey(user.id, carData.creator._id);
    await
      firebase
        .firestore()
        .collection('chats')
        .doc(docKey)
        .set({
          messages: [{
            message: `Hey I want to know about the car ${carData.title} you have posted.`,
            sender: user.id,
            timestamp: Date.now()
          }],
          users: [user.id, carData.creator._id],
          receiverHasRead: false
        })
  }

  chatExists = async () => {
    const { carData, user } = this.props;

    const docKey = this.buildDocKey(user.id, carData.creator._id);
    const chat = await
      firebase
        .firestore()
        .collection('chats')
        .doc(docKey)
        .get();
    return chat.exists;
  }

  handleGoBack = () => {
    window.history.back();
  }

  render() {

    const { isLoading, isAuthenticated, hasError, message, classes, carData, recentList } = this.props;
    return (
      <div className="mx-1200 mx-auto pt-3">
        <Helmet>
          {(!isLoading && !hasError) &&
            <meta name="description" content="Buy & Sell Used Cars with Chat2Cars throughout South Africa. Find the best second hand car deals with us." />
          }

          {isLoading ? 
            <title>Loading - {process.env.REACT_APP_NAME}</title>
            :
            hasError ?
              <title>404 not found - {process.env.REACT_APP_NAME}</title>
              :
              [
                <title key="meta-title">{carData.condition} {carData.title} - {process.env.REACT_APP_NAME}</title>,
                <meta key="meta-description" name="description" content={carData.description.substring(0, 160)} />
              ]
          }
        </Helmet>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator=">"
          style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 10 }}
        >
          <Link className="MuiTypography-root MuiLink-root MuiTypography-colorInherit" color="inherit" to="/">
            Home
            </Link>
          <Link className="MuiTypography-root MuiLink-root MuiTypography-colorInherit" color="inherit" onClick={this.handleGoBack}>
            Listing
            </Link>
          <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">Ad Details</Typography>
        </Breadcrumbs>
        {isLoading ?
          <Container className="my-4 d-flex justify-content-center align-items-center" component="main" style={{ minHeight: 'calc(100vh -  658px)' }} maxWidth="sm">
            <CircularProgress />
          </Container>
          :
          hasError ?
            <React.Fragment>
              <Alert className="mt-4" style={{ width: '100%' }} severity="error">
                <AlertTitle>Error</AlertTitle>
                {message}
              </Alert>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="default"
                className={classes.submit}
                component={Link}
                to={`/second-hand-cars/${carData?.slug}`}
              >
                Refresh
              </Button>
            </React.Fragment>
            :
            <React.Fragment>
              <div className="pricing-area mt-3">
                <div className="single-car-heading">
                  <div className="heading-zone">
                    <Typography className="text-dark font-weight-bold text-capitalize" color="textPrimary" component="h1" variant="h5">
                      {carData.title}
                    </Typography>
                    <div className="short-history">
                      <Typography className="text-gray" color="textPrimary">Category: {carData.bodytype}</Typography>
                    </div>
                  </div>
                </div>
                <div className="detail-price">
                  <div className="single-price-tag"><span>{formatNumber(carData.price, 2)}</span></div>
                </div>
              </div> 
              <div className="single-car-detail">
                <div className="single-car-content">
                  <SingleCarSlider carData={carData} />
                  <SingleCarKeyFeatures carData={carData} />
                  <SingleCarDescription carData={carData} />
                </div>
                <div className="single-car-sidebar">
                  <SingleCarSidebar isAuthenticated={isAuthenticated} carData={carData} handleSubmitData={this.handleSubmitData} handleContinueToChat={this.handleContinueToChat} recentList={recentList} />
                </div>
              </div>
            </React.Fragment>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.CarDetail,
  isAuthenticated: state.Login.isAuthenticated,
  user: state.Login.user,
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CarDetail))
