import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import Slider from "react-slick";
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import Toolbar from '@material-ui/core/Toolbar';

export const Logo = styled.div`
  margin-bottom: 0px;
  padding-left: 16px;

  img {
    height: 50px;
  }

  @media (max-width: 424px) {
    img {
      height: 50px;
    }
  }
`;

export const AppBarWrapper = styled(AppBar)`
  background-color: ${props => props.backgroundcolor} !important;
  background-image: url(${process.env.REACT_APP_BASE_PATH}/media/header/background.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: ${props => props.fontcolor} !important;
  flex-direction: column !important;
  justify-content: center !important;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #111;
    opacity: 0.8;
  }
`;

export const SliderWrapper = styled(Slider)`
  z-index: 1;
  max-width: 590px;
  display: block !important;
  div& {
    /* min-height: 475px; */

    div {
      div {
        outline: none;
      }
    }

    .main_slider__main_div {
      /* min-height: 475px; */
      display: flex;
      /* flex-direction: column; */
      justify-content: center;
      align-items: center;
      /* max-width: 1200px; */
      margin: 0 auto;
      padding: 0 20px;
    }

    .main_slider__main_div > div {
      flex: 0 0 50%;
    }
  }

  .main-slider__title {
    color: #f4f4f4;
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    padding: 24px 0;
  }

  .main-slider__slogan {
    font-size: 24px;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 8.4px;
    text-align: center;
  }

  .slick-prev {
    left: 25px !important;
    z-index: 1;
  }

  .slick-next {
    right: 25px !important;
    z-index: 1;
  }

  @media (max-width: 1200px) {
    & {
      max-width: 500px;
    }
  }

  @media (max-width: 1100px) {
    & {
      max-width: 420px;
    }
  }

  @media (max-width: 1050px) {
    & {
      display: none !important;
    }
  }

  @media (max-width: 950px) {
    .main_slider__main_div {
      flex-direction: column;
      max-width: 100%;
    }

    .main_slider__main_div > div {
      flex: 1;
    }

    .main-slider__title {
      font-size: 40px;
    }

    .main-slider__slogan {
      font-size: 20px;
    }
  }
`;

export const MenuButton = styled(Button)`
  &.MuiButton-text {
    padding: 6px 10px;
    font-size: 1rem;
    font-weight: 900;
  }

  &.book-button, &.MuiButton-root.book-button:hover, &.MuiButton-root.book-button:active {
    padding: 5px 10px !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    border-radius: 40px;
    background-color: #000 !important;
    color: #fff !important;
  }

  @media (max-width: 424px) {
    &.MuiButton-text {
      padding: 6px 8px;
      font-size: 0.675rem;
    }

    &.book-button {
      padding: 8px !important;
      margin-top: 8px !important;
      margin-bottom: 8px !important;
    }
  }

  &:hover {
    background-color: #FCC602 !important;
    color: #000;
  }

  &.active {
    background-color: #FCC602 !important;
    color: #000;
  }

  &:not(:last-of-type):not(:first-of-type) {
    margin-right: 10px;
  }
`;

export const MenuListItem = styled(ListItem)`
  padding: 0 8px !important;

  &:hover {
    background-color: transparent !important;
  }

  & .active {
    background-color: #FCC602 !important;
    color: #000;
    padding: 8px;
    border-radius: 8px;
  }

  & .not-active {
    color: #000;
    padding: 8px;
    border-radius: 8px;
  }

  & .not-active:hover {
    background-color: #FCC602 !important;
  }


  & .active span, & .not-active span {
    font-weight: 900;
  }
`;

export const ToolbarWrapper = styled(Toolbar)`
  &.MuiToolbar-regular {
    min-height: 80px;
  }

  & .active:hover, & .active:active {
    border-radius: 0 !important;
  }

  & ${MenuButton}:not(:last-of-type) {
    margin-right: 0 !important;
  }

  & .active, & ${MenuButton}:hover, & ${MenuButton}:active {
    border-radius: 0 !important;
    background: none !important;
  }

  & ${MenuButton} .MuiButton-label {
    z-index: 1;
    padding-left: 8px;
    padding-right: 8px;
  }

  & .active .MuiTouchRipple-root, & ${MenuButton}:hover .MuiTouchRipple-root, & ${MenuButton}:active .MuiTouchRipple-root {
    transform: skewX(-20deg);
    background-color: #FCC602 !important;
    border-radius: 0;
  }
`;



