import React, { useEffect } from 'react'

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/ForgotPassword/Actions';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { Link, Redirect } from 'react-router-dom';

// Document Head Manager
import { Helmet } from "react-helmet";

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SubscribeField } from './../../components/Footer/Footer.style';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#F5C82A',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#F5C82A',
    color: '#000',
    fontWeight: 900,
    '&:hover': {
      backgroundColor: '#F5C82A',
      color: '#000',
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    minWidth: '100%',
    '& .MuiInput-underline': {
      '&:after': {
        borderBottom: '2px solid #000',
      }
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#000'
    }
  },
}));

const ForgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
          .email('Please enter a valid email')
          .required('Please enter an email'),
});

const ForgotPassword = (props) => {
  const { isAuthenticated, hasError, hasSuccess, errorMessage, requestResetPasswordAction, resetForgotPasswordParamsAction } = props;
  const classes = useStyles();

  useEffect(() => {
    return () => {
      resetForgotPasswordParamsAction();
    };
  }, [resetForgotPasswordParamsAction])

  const handleFormSubmit = values => {
    requestResetPasswordAction(values);
  }

  return (
    isAuthenticated ?
      <Redirect to="/" />
      :
      <Container className="my-4" component="main" style={{ minHeight: 'calc(100vh -  596px)' }} maxWidth="sm">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Forgot Password | {process.env.REACT_APP_NAME}</title>
        </Helmet>
        <Breadcrumbs className="mt-4" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
            Home
            </Link>
          <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">Forgot Password</Typography>
        </Breadcrumbs>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          {hasError ?
            <Alert className="mt-4" style={{ width: '100%' }} severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
            :
            hasSuccess ?
              <React.Fragment>
                <Alert className="mt-4" style={{ width: '100%' }} severity="success">
                  <AlertTitle>Recovery Email Sent!</AlertTitle>
                  <p>
                    Please check your email within the next 2 hours for next steps to reset your password.
                  </p>
                  <p>
                    If you can’t see the email, it might be in your spam folder, or your Chat2Cars account might be under a different email address.
                  </p>
                </Alert>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="default"
                  className={classes.submit}
                  component={Link}
                  to="/login"
                >
                  Back to Login
                </Button>
              </React.Fragment>
              :
              <Formik
                enableReinitialize
                initialValues={{
                  email: '',
                }}
                onSubmit={handleFormSubmit}
                validationSchema={ForgotPasswordValidationSchema}
              >
                {(renderProps) => {
                  const { values: formValues, touched, errors } = renderProps;
                  return (
                    <Form className={classes.form}>
                      <SubscribeField
                        className="news-letter-input"
                        label="Your Email Address"
                        style={{ width: '100%' }}
                        margin="normal"
                        fullWidth
                        id="email"
                        name="email"
                        autoComplete="email"
                        newsletter={true}
                        InputProps={{
                          type: 'email',
                          autoComplete: 'off'
                        }}
                        value={formValues.email}
                        onChange={e => renderProps.setFieldValue('email', e.target.value)}
                        helperText={touched.email && errors.email}
                        error={touched.email && errors.email ? true : false}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="default"
                        className={classes.submit}
                      >
                        Submit
                      </Button>
                      <Grid container>
                        <Grid item xs>
                          <Link to="/login">
                            Back to Login?
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link to="/register">
                            {"Don't have an account? Sign Up"}
                          </Link>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
          }
        </div>
      </Container>
  )
}

const mapStateToProps = (state) => ({
  ...state.ForgotPassword,
  isAuthenticated: state.Login.isAuthenticated
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
