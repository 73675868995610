import {
  GET_CURRENT_USER_PROFILE,
  // GET_CURRENT_USER_PROFILE_FAILURE,
  UPDATE_CURRENT_USER_PROFILE,
  CAR_LIST_UPDATED,
  FAVOURITE_LIST_UPDATED,
  INQUIRY_LIST_UPDATED,
  // UPDATE_CURRENT_USER_PROFILE_FAILURE,
  RESET_LOADING_FLAG,
  UPDATE_CAR_FAV_UNFAV_STATUS,
  DELETE_MY_CAR,
  CHANGE_CAR_STATUS,
  LOAD_PROVINCES,
  LOAD_CITIES,
} from './Constants';

const initialState = {
  userData: undefined,
  isLoading: true,
  subscriptionHistory: [],
  favouriteList: [],
  carList: [],
  provinces: [],
  cities: [],
  inquiryList: [],
  carListTotalResults: 0,
  carListCurrentPage: 1,
  carListLimit: 12,
  favouriteListTotalResults: 0,
  favouriteListCurrentPage: 1,
  favouriteListLimit: 9,
  inquiryListTotalResults: 0,
  testDriveList:[],
  testDriveListTotalResults: 0,
  testDriveListCurrentPage:1,
  testDriveListLimit:9,
  inquiryListCurrentPage: 1,
  inquiryListLimit: 9,
}

export default (state = initialState, action) => {
  let { favouriteList } = state;
  switch (action.type) {
    case GET_CURRENT_USER_PROFILE:
      return {
        ...state,
        ...action.data,
        isLoading: false
      }
    case UPDATE_CURRENT_USER_PROFILE:
      return {
        ...state,
        userData: action.data,
      }
    case UPDATE_CAR_FAV_UNFAV_STATUS:
      favouriteList = favouriteList.filter(x => x._id !== action.data);
      return {
        ...state,
        favouriteList
      }
    case CAR_LIST_UPDATED:
      return {
        ...state,
        ...action.data,
      }
    case FAVOURITE_LIST_UPDATED:
      return {
        ...state,
        ...action.data,
      }
    case INQUIRY_LIST_UPDATED:
      return {
        ...state,
        ...action.data,
      }
    case RESET_LOADING_FLAG:
      return {
        ...state,
        userData: undefined,
        subscriptionHistory: [],
        favouriteList: [],
        carList: [],
        provinces: [],
        cities: [],
        inquiryList: [],
        isLoading: true,
      }
    case DELETE_MY_CAR:
      return {
        ...state,
        carList: state.carList.filter(x => x._id !== action.data)
      }
    case LOAD_PROVINCES:
      return {
        ...state,
        provinces: action.data
      }
    case LOAD_CITIES:
      return {
        ...state,
        cities: action.data
      }
    case CHANGE_CAR_STATUS:
      return {
        ...state,
        carList: state.carList.map(car => {
          if(car._id === action.data._id) {
            return action.data
          }

          return car;
        })
      }
    default:
      return state
  }
}
