import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
    width: '100%'
  },
  media: {
    height: 270,
    position: 'relative',
    minWidth: 170,
  },
}));

let index = 0;

const CarListingSkeleton = () => {
  const classes = useStyles();

  return (
    <Container className="car-results__listing p-2">
      <Skeleton height={50} width="50%" style={{ marginBottom: 10, float: 'right' }} />
      <Grid container spacing={3}>
        {[...new Array(9)].map(() => {
          return (
            <Grid key={`skeleton-list-${index++}`} item xs={12} sm={6} md={4}>
              <Card className={`${classes.card} mx-auto`}>
                <Skeleton variant="rect" className={classes.media} />
                <CardContent>
                  <Skeleton height={10} style={{ marginBottom: 6 }} />
                  <Skeleton height={10} style={{ marginBottom: 6 }} width="80%" />
                  <Skeleton height={10} style={{ marginBottom: 6 }} width="80%" />
                  <Skeleton height={10} style={{ marginBottom: 6 }} width="80%" />
                  <Skeleton height={10} style={{ marginBottom: 6 }} width="80%" />
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>
      <Skeleton height={50} width="50%" style={{ float: 'right' }} />
    </Container>
  )
}

export default CarListingSkeleton
