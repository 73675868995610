import styled from "styled-components";
import Slider from "react-slick";

export const SliderWrapper = styled(Slider)`
  div {
    div {
      div {
        outline: none;
      }
    }
  }

  .review-main {
    max-height: ${props => props.noMaxHeight ? 'unset' : '350px'};
  }

  .offer-slider__img {
    padding: 25px;
    height: auto !important;
  }

  .slick-arrow {
    &:before {
      color: #000 !important;
    }
  }

  .slick-prev {
    left: 25px !important;
    z-index: 1;
  }

  .slick-next {
    right: 25px !important;
    z-index: 1;
  }

  /* .slick-slide {
    opacity: 0.5;
    transition: all 300ms ease;
    transform: scale(0.9);
  }

  .slick-center {
    opacity: 1;
    transform: scale(1.1);
  } */
`;
