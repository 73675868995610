import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

// Styled components
import { ServiceSlider, ServicesMain } from "./Services.style";

import "./services.css";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
}));

const Services = props => {
  const { classNames } = props;
  const classes = useStyles();

  const options = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <section className={`car-services`} style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_PATH}/media/services/service_placeholder_image.png)` }}>
      <div className={`${classNames} ${classes.root} pl-3 pr-3 pb-5 pt-5`}>
        <div className="section-head mb-5">
          <h2 className="text-uppercase section-title text-primary">
            Services
          </h2>
        </div>
        <ServiceSlider className="mt-3 mb-5" {...options} noMaxHeight>
          <div>
            <ServicesMain to="/services" className="m-2" bgimage={`${process.env.REACT_APP_BASE_PATH}/media/services/bg-6.jpg`}>
              <div className="services__main">
              </div>
            </ServicesMain>
          </div>
          <div>
            <ServicesMain to="/services" className="m-2" bgimage={`${process.env.REACT_APP_BASE_PATH}/media/services/bg-5.jpg`}>
              <div className="services__main">
              </div>
            </ServicesMain>
          </div>
          <div>
            <ServicesMain to="/services" className="m-2" bgimage={`${process.env.REACT_APP_BASE_PATH}/media/services/bg-4.jpg`}>
              <div className="services__main">
              </div>
            </ServicesMain>
          </div>
        </ServiceSlider>
      </div>
    </section>
  )
}

export default Services;
