import React, { useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import Avatar from '@material-ui/core/Avatar';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 10,
    maxHeight: '65vh',
    overflow: 'auto',
    flex: 1,
  },

  userSent: {
    padding: 16,
    wordWrap: 'break-word',
    marginTop: 10,
    marginRight: 10,
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: 10,
    alignSelf: 'flex-end'
  },

  friendSent: {
    padding: 16,
    wordWrap: 'break-word',
    marginTop: 10,
    marginLeft: 10,
    backgroundColor: '#F5C82A',
    color: '#000',
    borderRadius: 10,
    alignSelf: 'flex-start'
  },

  chatHeader: {
    width: 'calc(100% - 301px)',
    height: 50,
    backgroundColor: '#344195',
    position: 'fixed',
    marginLeft: 301,
    fontSize: 18,
    textAlign: 'center',
    color: 'white',
    paddingTop: 10,
    boxSizing: 'border-box'
  },

  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: 0,
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },

  iconButton: {
    padding: 10,
  },

  divider: {
    height: 28,
    margin: 4,
  },
}));

const ChatView = (props) => {
  const { isLoading, screenWidth, handleChangeToScreenOne, chatListFromFirebase, selectedChatId, chat, currentUser, listUser, handleMessageSend, messageRead } = props;
  const messageFieldRef = useRef(null);
  const classes = useStyles();

  const sender = {_id: currentUser.id, ...currentUser};
  const receiver = listUser ? listUser.sender._id !== currentUser.id ? listUser.sender : listUser.reciever : undefined;

  // let chatters = undefined;

  // if(receiver) {
  //   chatters = {
  //     [currentUser.id]: currentUser,
  //     [receiver._id]: receiver,
  //   }
  // }

  useEffect(() => {
    const container = document.getElementById('chatview-container');

    if(container) {
      container.scrollTo(0, container.scrollHeight);
    }

  }, [chat, selectedChatId]);

  const handleSubmitMessage = e => {
    e && e.preventDefault();
    const message = messageFieldRef.current.value.trim();
    if(message !== '') {
      handleMessageSend(message, sender, receiver);
      messageFieldRef.current.value = '';
    }
  }

  const userClickedInput = () => {
    messageRead();
  }

  return (
    selectedChatId === '' ?
      <div className="d-flex justify-content-center align-items-center h-100" style={{ minHeight: 300 }}>
        {!isLoading && chatListFromFirebase.length === 0 ?
          'No chats available'
          :
          'Please select chat from chat list'
        }
      </div>
      :
      !chat || !receiver ?
        <div className="d-flex justify-content-center align-items-center h-100">There was error retrieving your chat. Please try again later.</div>
        :
        <React.Fragment>
          {screenWidth <= 767 &&
            <Paper component="div" className={`${classes.root} d-flex shadow-none py-2 align-items-center`} style={{ paddingLeft: 10 }}>
              <IconButton onClick={handleChangeToScreenOne} className="mr-2">
                <KeyboardBackspaceIcon />
              </IconButton>
              <Avatar aria-label="recipe" src={receiver.avatarurl} className={`${classes.avatar} mr-2`} />
              <span>{receiver.fullname}</span>
            </Paper>
          }
          <main id="chatview-container" className={classes.content}>
            {chat.messages.map((chat, index) => {
              return (
                <div key={`${receiver._id}-${index}`} className={`${chat.sender === sender._id ? classes.userSent : classes.friendSent} shadow chat-bubble`}>
                  {chat.message}
                </div>
              );
            })}
          </main>
          <Paper component="form" className={classes.root} onSubmit={handleSubmitMessage}>
            <InputBase
              className={classes.input}
              placeholder="Write a Message"
              inputProps={{
                'aria-label': 'write a message',
                ref: messageFieldRef
              }}
              onFocus={userClickedInput}
            />
            <IconButton onClick={handleSubmitMessage} className={classes.iconButton} aria-label="search">
              <SendIcon />
            </IconButton>
          </Paper>
        </React.Fragment>
  )
}

export default ChatView;
