import {
  GET_ALL_DEALERS,
  UPDATE_PAGE_NUMBER,
  RESET_LOADING_FLAG,
  SEARCH_TERM_ADDED
} from './Constants';

const initialState = {
  dealersData: [],
  loading: true,
  totalResults: 0,
  currentPage: 1,
  limit: 12,
  
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_DEALERS:
      return {
        ...state,
        dealersData: action.data.dealersData,
        totalResults: action.data.totalResults,
        loading: false
      }
    case UPDATE_PAGE_NUMBER:
      // console.log(1)
      return {
        ...state,
        loading: true,
        currentPage: action.data
      }
    case SEARCH_TERM_ADDED:
      return {
        ...state,
        loading: true,
        currentPage: 1,
        limit: 12
      }
    case RESET_LOADING_FLAG:
      // console.log(1)
      return {
        ...state,
        dealersData: [],
        loading: true,
        totalResults: 0,
        currentPage: 1,
        limit: 12
      }
    default:
      return state
  }
}
