import React, { Component } from 'react'

import { withStyles } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/Chat/Actions';

import ChatSidebar from './../../components/Chat/ChatSidebar';
import ChatView from './../../components/Chat/ChatView';

import { Link, Redirect } from 'react-router-dom';

import firebase from 'firebase/app';
import '@firebase/firestore';


// Document Head Manager
import { Helmet } from "react-helmet";

import './chat.css';

const styles = theme => ({
  root: {
    maxWidth: 1200,
    padding: '0 16px'
  }
});

class Chat extends Component {

  state = {
    selectedChatId: '',
    chatListFromFirebase: [],
    screenWidth: 0,
    currentScreen: 1,
  }

  componentDidMount() {
    this.props.getAllChatsAction();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    const { isAuthenticated, user } = this.props;
    if (isAuthenticated && user && user.is_subscriber) {
      firebase
        .firestore()
        .collection('chats')
        .where('users', 'array-contains', user.id)
        .onSnapshot(res => {
          const chats = res.docs.map(doc => doc.data());
          this.setState({
            chatListFromFirebase: chats
          })
        })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    const screenWidth = window.innerWidth;
    this.setState((prevState) => ({
      screenWidth,
      currentScreen: screenWidth <= 767 && prevState.selectedChatId !== '' ? 2 : 1,
    }));
  }

  handleSelectChat = (chatIndex) => {
    this.setState({
      selectedChatId: chatIndex,
      currentScreen: 2
    }, () => {
      this.messageRead();
    })
  }

  buildDocKey = (sender, receiver) => [sender, receiver].sort().join(':');

  handleMessageSend = (message, sender, receiver) => {
    const docKey = this.buildDocKey(sender._id, receiver._id);
    firebase
      .firestore()
      .collection('chats')
      .doc(docKey)
      .update({
        messages: firebase.firestore.FieldValue.arrayUnion({
          sender: sender._id,
          message: message,
          timestamp: Date.now()
        }),
        receiverHasRead: false
      });
  }

  messageRead = () => {
    const { selectedChatId, chatListFromFirebase } = this.state;
    const { user } = this.props;
    const getChat = chatListFromFirebase.find(x => x.users[0] === selectedChatId || x.users[1] === selectedChatId);
    const clickedMessageWhereNotSender = getChat.messages[getChat.messages.length - 1].sender !== user.id;
    const docKey = this.buildDocKey(user.id, selectedChatId);
    if (clickedMessageWhereNotSender) {
      firebase
        .firestore()
        .collection('chats')
        .doc(docKey)
        .update({ receiverHasRead: true });
    }
  }

  handleChangeToScreenOne = () => {
    this.setState({
      currentScreen: 1,
      selectedChatId: '',
    })
  }

  render() {
    const { isAuthenticated, classes, chatList, user, isLoading } = this.props;
    const { selectedChatId, chatListFromFirebase, screenWidth, currentScreen } = this.state;
    let getChat = undefined;
    let listUser = undefined;
    if (selectedChatId !== '') {
      getChat = chatListFromFirebase.find(x => x.users[0] === selectedChatId || x.users[1] === selectedChatId);
      listUser = chatList.find(x => x.sender._id === selectedChatId || x.reciever._id === selectedChatId);
    }

    return (
      !isAuthenticated ?
        <Redirect to="/login" />
        :
        !user || !user.is_subscriber ?
          <Redirect to="/subscriptions" />
          :
          <div className={`${classes.root} mx-auto`}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Chats | {process.env.REACT_APP_NAME}</title>
            </Helmet>
            <Breadcrumbs className="mt-4" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
                Home
            </Link>
              <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">Chats</Typography>
            </Breadcrumbs>
            <div className="chats__main-div my-4">
              {screenWidth > 767 || (screenWidth <= 767 && currentScreen === 1) ?
                <ChatSidebar chatList={chatList} chatListFromFirebase={chatListFromFirebase} currentUser={user} isSidebarLoading={isLoading} handleSelectChat={this.handleSelectChat} selectedChatId={selectedChatId} />
                :
                null
              }
              {screenWidth > 767 || (screenWidth <= 767 && currentScreen === 2) ?
                <div className="chats__chat-user-messages shadow">
                  <ChatView screenWidth={screenWidth} handleChangeToScreenOne={this.handleChangeToScreenOne} selectedChatId={selectedChatId} chat={getChat} listUser={listUser} currentUser={user} handleMessageSend={this.handleMessageSend} messageRead={this.messageRead} isLoading={isLoading} chatListFromFirebase={chatListFromFirebase} />
                </div>
                :
                null
              }
            </div>
          </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.Chat,
  isAuthenticated: state.Login.isAuthenticated,
  user: state.Login.user,
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Chat))
