import {
  FETCH_VEHICLE_BRAND_HOMEPAGE,
  FETCH_VEHICLE_MODEL_HOMEPAGE,
  FETCH_HOMEPAGE_DATA,
  REMOVE_FROM_MODEL_LIST,
  RESET_HOMEPAGE
} from './Constants';
// import { ADDED_NOTIFICATION } from "./../Notification/Constants";
import Api from './../../helpers/ApiHandler';

const api = new Api();

export const fetchVehicleBrandAction = () => dispatch => {
  // console.log("object")
  api.get('common/get-all-makes', { skipAuth: true })
    .then((result) => {
      // console.log(result);
      dispatch({
        type: FETCH_VEHICLE_BRAND_HOMEPAGE,
        data: result.data
      })
    })
    .catch((err) => {
      return err
    });
}

export const fetchVehicleModelAction = (brand) => dispatch => {
  // console.log("object2");
  api.get(`common/get-single-make/${brand._id}`, { skipAuth: true })
    .then((result) => {
      // console.log(result);
      if (result.success) {
        dispatch({
          type: FETCH_VEHICLE_MODEL_HOMEPAGE,
          data: {
            vehicleModelsList: result.data.models,
            brand
          }
        })
      } else {
        dispatch({
          type: FETCH_VEHICLE_MODEL_HOMEPAGE,
          data: {
            vehicleModelsList: [],
            brand
          }
        })
      }
    })
    .catch((err) => {
      return err
    });
}

export const removeVehicleModelsFromListAction = (removedBrand) => dispatch => {
  dispatch({
    type: REMOVE_FROM_MODEL_LIST,
    data: removedBrand
  })
}

export const fetchOtherDataOnPage = () => async dispatch => {
  try {
    let carList = await api.post(`cars/get-car-list`, { data: { page: 1, limit: 10 }, skipAuth: true });
    let featured = await api.get(`users/get-dealership-list`, { skipAuth: true });
    let carTypes = await api.get(`common/get-all-car-types`, { skipAuth: true });
    let blogs = await api.post(`blogs/list-blogs`, { skipAuth: true });
  // let api5 = 'Our Testimonial => Fetch All';
  
  // console.log(carList);  
    dispatch({
      type: FETCH_HOMEPAGE_DATA,
      data: {
        carListing: carList.success ? carList.data : [],
        featuredDealerships: featured.success ? featured.data : [],
        awardNomineesData: [],
        blogsData: blogs.success ? blogs.data : [],
        testimonials: [],
        carTypes: carTypes.success ? carTypes.data : [],
      }
    })
  } catch (err) {
    console.log('err', err);
  };
  
}

export const resetHomePageAction = () => dispatch => {
  dispatch({ type: RESET_HOMEPAGE })
}