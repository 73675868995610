import {
  HomePage,
  CarResultsPage,
  DealersPage,
  CarDetailPage,
  LoginPage,
  RegisterPage,
  SubscriptionsPage,
  ProfilePage,
  DealerDetailPage,
  UserVerificationPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  ChatPage,
  PostAdPage,
  ServicesPage,
  AboutUsPage,
  PrivacyPolicyPage,
  TermsConditionsPage,
  FAQsPage,
  BlogDetailPage,
  BookTestDrivePage,
  ContactUsPage,
  PostAdPageTest
} from './../pages';
import Blogs from '../pages/Blogs';
import Insurance from '../pages/Insurance';
import ThankYou from '../pages/ThankYou'
import React from 'react';

export default [
  {
    path: '/',
    name: 'Home',
    exact: true,
    component: HomePage
  },
  // {
  //   path: '/index.html',
  //   name: 'Home',
  //   exact: true,
  //   component: HomePage
  // },
  {
    path: '/second-hand-cars',
    name: 'Second Hand Cars',
    exact: true,
    component: CarResultsPage
  },
  {
    path: '/second-hand-cars/:slug',
    name: 'Listing Result - Ad Detail',
    exact: true,
    component: CarDetailPage
  },
  {
    path: '/second-hand-cars/:selected',
    name: 'Listing Result - Ad Detail',
    exact: true,
    component: CarDetailPage
  },
  {
    path: '/dealers',
    name: 'Dealers',
    exact: true,
    component: DealersPage
  },
  {
    path: '/dealer/:id',
    name: 'Dealer Detail',
    exact: true,
    component: DealerDetailPage
  },
  {
    path: '/blog/:slug',
    name: 'Blog Detail',
    exact: true,
    component: BlogDetailPage
  },
  {
    path: '/blog',
    name: 'Blogs',
    exact: true,
    component: Blogs
  },
  {
    path: '/insurance',
    name: 'Insurance',
    exact: true,
    component: Insurance
  },
  {
    path: '/book-test-drive',
    name: 'Book Test Drive',
    exact: true,
    component: BookTestDrivePage
  },
  {
    path: '/login',
    name: 'Login',
    exact: true,
    component: LoginPage
  },
  {
    path: '/register',
    name: 'Register',
    exact: true,
    component: RegisterPage
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    exact: true,
    component: SubscriptionsPage
  },
  {
    path: '/profile',
    name: 'Profile',
    exact: true,
    component: ProfilePage
  },
  {
    path: '/user/verify/:id',
    name: 'Verify User',
    exact: true,
    component: UserVerificationPage
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    exact: true,
    component: ForgotPasswordPage
  },
  {
    path: '/reset-password/:id',
    name: 'Forgot Password',
    exact: true,
    component: ResetPasswordPage
  },
  {
    path: '/chat',
    name: 'Chat List',
    exact: true,
    component: ChatPage
  },
  {
    path: '/post/ad',
    name: 'Post Ad',
    exact: true,
    component: PostAdPage
  },
  {
    path: '/post/ad/test',
    name: 'Post Ad Test',
    exact: true,
    component: PostAdPageTest
  },
  {
    path: '/post/ad/:id',
    name: 'Post Ad',
    exact: true,
    component: PostAdPage
  },
  {
    path: '/services',
    name: 'Services',
    exact: true,
    component: ServicesPage
  },
  {
    path: '/about',
    name: 'About Us',
    exact: true,
    component: AboutUsPage
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    exact: true,
    component: PrivacyPolicyPage
  },
  {
    path: '/terms',
    name: 'Terms & Conditions',
    exact: true,
    component: TermsConditionsPage
  },
  {
    path: '/faqs',
    name: 'FAQs',
    exact: true,
    component: FAQsPage
  },
  {
    path: '/contact',
    name: 'Contact Us',
    exact: true,
    component: ContactUsPage
  },
  {
    path: '/thankyou',
    name: 'Thank You',
    exact: false,
    component: ThankYou,
    // render:(props) => <ThankYou {...props}/>
  },
];
