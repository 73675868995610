import React, { useEffect } from 'react';

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/UserVerification/Actions';

import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const UserVerification = (props) => {
  const { isLoading, hasError, errorMessage, successMessage, match, verifyUserAction, resetVerificationProcessAction } = props;

  useEffect(() => {
    verifyUserAction(match.params.id);
    return () => {
      resetVerificationProcessAction()
    };
  }, [verifyUserAction, resetVerificationProcessAction, match.params.id])

  return (
    isLoading ?
      <Container className="my-4 d-flex justify-content-center align-items-center" style={{ height: 'calc(100vh -  608px)' }} component="main" maxWidth="sm">
        <CircularProgress />
      </Container>
      :
      <Container className="my-4 d-flex justify-content-center align-items-center" component="main" style={{ minHeight: 'calc(100vh -  608px)' }} maxWidth="sm">
        {hasError ?
          <Alert style={{ flex: 1 }} severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
          :
          <Alert style={{ flex: 1 }} severity="success">
            <AlertTitle>Success</AlertTitle>
            {successMessage}
          </Alert>
        }
      </Container>
  )
}

const mapStateToProps = (state) => ({
  ...state.UserVerification
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserVerification)
