import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    boxShadow: 'none',
    borderRadius: 0,
    background: 'none'
  },
  media: {
    height: 200,
    position: 'relative',
    minWidth: 300,
  },
  content: {
    padding: 0,
    paddingTop: 16,
    marginBottom: 20
  },
  title: {
    fontWeight: 900,
    lineHeight: 1.3,
  },
  titleLink: {
    fontSize: '1.25rem',
  },
  header: {
    padding: 0,
  }
}));

const SingleDealer = ({ dealer }) => {
  const { _id, fullname, avatarurl, businessname, logourl, businessAddress, city, gsm, total_cars } = dealer;
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card className={`${classes.card} mx-auto`}>
        <Link to={`/dealer/${_id}`} title={businessname || fullname}>
          <CardMedia
            className={`${classes.media} capitalize`}
            image={logourl || "https://pi.tedcdn.com/r/talkstar-photos.s3.amazonaws.com/uploads/72bda89f-9bbf-4685-910a-2f151c4f3a8a/NicolaSturgeon_2019T-embed.jpg?w=512"}
            title={businessname || fullname}
          />
        </Link>
        <CardContent className={classes.content}>
          <Typography gutterBottom variant="h6" className={`car-title ${classes.title}`}>
            <Link className={classes.titleLink} to={`/dealer/${_id}`} title={businessname || fullname}>{businessname || fullname}</Link>
          </Typography>
          <Typography display="block" variant="subtitle1" color="textSecondary">
            {businessAddress}
          </Typography>
          {/* <Typography display="block" variant="subtitle1" color="textSecondary">
            Near Kargil Petrol pump
          </Typography> */}
          <Typography display="block" variant="subtitle2" color="textSecondary">
            {gsm || 'Mobile Not Available'}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {city}
          </Typography>
        </CardContent>
        <CardHeader
          className={`${classes.header} capitalize`}
          avatar={
            <Avatar
              alt={fullname}
              src={avatarurl}
            />
          }
          title={fullname}
          subheader={`Total Cars: ${total_cars}`}
        />
      </Card>
    </Grid>
  )
}

export default SingleDealer
