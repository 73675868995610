import {
  APPLY_SUBSCRIPTION_ORDER,
  APPLY_SUBSCRIPTION_ORDER_FAILURE,
  RESET_SUBSCRIPTIONS_OPTION,
  SET_LOADING,
  FETCH_SUBSCRIPTIONS,
} from './Constants';

const initialState = {
  subscriptionsData: [],
  selectedPlanDetail: undefined,
  appliedOrderDetail: undefined,
  isLoading: true,
  message: '',
  hasError: false,
  hasSuccess: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        ...action.data,
        isLoading: false,
      }
    case APPLY_SUBSCRIPTION_ORDER:
      return {
        ...state,
        hasSuccess: true,
        appliedOrderDetail: action.data.appliedOrderDetail,
        selectedPlanDetail: action.data.selectedPlanDetail
      }
    case APPLY_SUBSCRIPTION_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        message: action.data,
      }
    case SET_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case RESET_SUBSCRIPTIONS_OPTION:
      return state;
    default:
      return state;
  }
}