import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';
// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/Home/Actions';
// Custom components
import Services from '../../components/Services';
import FeaturedListing from './../../components/FeaturedListing';
import LatestListing from './../../components/LatestListings';
import NewsLetter from '../../components/NewsLetter';
// import AwardNominees from '../../components/AwardNominees';
import LatestBlogs from '../../components/LatestBlogs';
// Document Head Manager
import { Helmet } from "react-helmet";

const styles = theme => ({
  root: {
    maxWidth: 1200
  }
})

class Home extends Component {
 
  componentDidMount() {
    
    this.props.fetchVehicleBrandAction();
    this.props.fetchOtherDataOnPage();
  }

  componentWillUnmount() {
    this.props.resetHomePageAction();
  }

  handleFormSubmit = (values) => {
    const { vehicleType, vehicleModel, vehicleBrand, vehicleYear, maxPrice } = values;
    const { vehicleModelsList } = this.props;
    let redirectUrl = `/second-hand-cars`;
    this.props.history.push(redirectUrl, { type: vehicleType, selectedModels: vehicleModel, modelList: vehicleModelsList, selectedBrands: vehicleBrand, year: vehicleYear, price: maxPrice !== '' ? [0, 5000000] : [0, maxPrice] });
  }

  handleSelectVehicleBrand = (addedBrand) => {
    
    this.props.fetchVehicleModelAction(addedBrand);
  }

  handleRemoveSelectedBrand = (removeBrand) => {
    this.props.removeVehicleModelsFromListAction(removeBrand);
  }

  render() {
    const {
      classes,
      featuredDealerships,
      carListing,
      blogsData
    } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Chat2Cars | Buy and Sell Cars Online | BMW and Toyota Dealership</title>
          <meta name="title" content="Chat2Cars | Buy and Sell Cars Online | BMW and Toyota Dealership" />
          <meta property="og:title" content="Chat2Cars | Buy and Sell Cars Online | BMW and Toyota Dealership" />
          <meta name="description" content="Chat2cars is your no. 1 Online Car Marketplace where you can buy and sell cars online. We
also have the BMW and Toyota dealership in South Africa." />
          <meta property="og:image" content="https://chat2cars.co.za/logo.png" />

        </Helmet>
        
        <LatestListing classNames={`${classes.root} mx-auto`} carListing={carListing} />
        <FeaturedListing classNames={`${classes.root} mx-auto`} featuredDealerships={featuredDealerships} />
        <Services classNames={`${classes.root} mx-auto`} />
        <LatestBlogs classNames={`${classes.root} mx-auto`} blogsData={blogsData} />
        <NewsLetter />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.Home
  };
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home));
