import {
  GET_CAR_SEARCH_RESULT_DATA,
  GET_BRANDS,
  GET_PROVINANCE,
  GET_CARTYPES,
  // GET_BRAND_LIST_FOR_RESULTS,
  REMOVE_BRAND_FROM_SELECTED_LIST,
  UPDATE_BRANDS_FIELD_FILTER,
  UPDATE_MODELS_FIELD_FILTER,
  UPDATE_FUEL_TYPE_FIELD_FILTER,
  UPDATE_TRANSMISSION_FIELD_FILTER,
  UPDATE_PRICE_FIELD_FILTER,
  UPDATE_KMS_DRIVEN_FIELD_FILTER,
  UPDATE_YEARS_FIELD_FILTER,
  UPDATE_PAGE_NUMBER_FILTER,
  UPDATE_SORT_BY_FILTER,
  UPDATE_PROVINCE_FILTER,
  UPDATE_CITY_FILTER,
  UPDATE_BODY_TYPE_FIELD_FILTER,
  RESET_ALL_FITLERS,
  CLEAR_FILTER_AND_DATA,
  LIKE_UNLIKE_CAR,
  LOAD_CITIES,
} from './Constants';
// import { ADDED_NOTIFICATION } from "./../Notification/Constants";
import Api from './../../helpers/ApiHandler';

const api = new Api();

export const getSearchResultsDataAction = (payload, newFilters) => async dispatch => {
  
  try {
    let carResults = await api.post(`cars/get-car-list`, { data: payload, skipAuth: true });
    dispatch({
      type: GET_CAR_SEARCH_RESULT_DATA,
      data: {
        searchResults: carResults.success ? carResults.data : [],
        totalResults: carResults.success ? carResults.count_records : 0,
        newFilters
      },
    })
    
  } catch(err) {
  }
}

export const getBrandsDataAction = () => async dispatch => {
  try{
    let brands = await api.get('common/get-all-makes', { skipAuth: true });
    dispatch({
      type: GET_BRANDS,
      data: {
        brandsList: brands && brands.success ? brands.data : [],
      },
    })
  }catch(err){

  }
}

export const getProvinceDataAction = () => async dispatch => {
  try{
    let provinces = await api.get('city_state/get-all-states', { skipAuth: true });
    dispatch({
      type: GET_PROVINANCE,
      data: {
        provinces: provinces && provinces.success ? provinces.data : [],
      },
    })
  }catch(err){

  }
}

export const getCarTypesDataAction = () => async dispatch => {
  try{
    let carTypes = await api.get(`common/get-all-car-types`, { skipAuth: true });
    dispatch({
      type: GET_CARTYPES,
      data: {
        carTypes: carTypes && carTypes.success ? carTypes.data : [],
      },
    })
  }catch(err){

  }
}

export const updateBrandsFieldAction = (brands, newSelectedBrand) => dispatch => {

  

  if(!newSelectedBrand){
    return false;
  }
  api.get(`common/get-single-make/${newSelectedBrand._id}`, { skipAuth: true })
    .then((result) => {
      if(result.success) {
        dispatch({
          type: UPDATE_BRANDS_FIELD_FILTER,
          data: {
            brands,
            newSelectedBrand,
            models: result.data.models
          }
        })
      } else {
        dispatch({
          type: UPDATE_BRANDS_FIELD_FILTER,
          data: {
            brands,
            newSelectedBrand,
            models: []
          }
        })
      }
    })
    .catch((err) => {
      // console.log('err', err);
    });
}

export const loadCitiesAction = (id) => dispatch => {
  api.post('city_state/get-cities-by-state-ids', { data: {state_ids: id}, skipAuth: true })
    .then((result) => {
      if(result.success) {
        dispatch({
          type: LOAD_CITIES,
          data: result.data
        })
      } else {
        // console.log('result', result)
      }
    })
    .catch((err) => {
      // console.log('err', err);
    });
}

export const updateModelsFieldAction = (models) => dispatch => {
  dispatch({
    type: UPDATE_MODELS_FIELD_FILTER,
    data: models
  })
}

export const removeBrandFromSelectedAction = (removedBrand, selectedBrands) => dispatch => {
  // console.log("selected",selectedBrands)
  // console.log("remove",removedBrand)
  dispatch({
    type: REMOVE_BRAND_FROM_SELECTED_LIST,
    data: {
      removedBrand,
      selectedBrands
    }
  })
}

export const updateFuelTypeFieldAction = (fuelType) => dispatch => {
  dispatch({
    type: UPDATE_FUEL_TYPE_FIELD_FILTER,
    data: fuelType
  })
}

export const updateTransmissionFieldAction = transmission => dispatch => {
  dispatch({
    type: UPDATE_TRANSMISSION_FIELD_FILTER,
    data: transmission
  })
}

export const updatePriceFieldAction = (price) => dispatch => {
  dispatch({
    type: UPDATE_PRICE_FIELD_FILTER,
    data: price
  })
}

export const updateKmsDrivenFieldAction = (kmsDriven) => dispatch => {
  dispatch({
    type: UPDATE_KMS_DRIVEN_FIELD_FILTER,
    data: kmsDriven
  })
}

export const updateYearsFieldAction = (years) => dispatch => {
  dispatch({
    type: UPDATE_YEARS_FIELD_FILTER,
    data: years
  })
}

export const updateBodyTypeFilterAction = (bodyTypes) => dispatch => {
  dispatch({
    type: UPDATE_BODY_TYPE_FIELD_FILTER,
    data: bodyTypes
  })
}

export const updatePageNoAction = (pageNo) => dispatch => {
  // console.log("object");
  dispatch({
    type: UPDATE_PAGE_NUMBER_FILTER,
    data: pageNo
  })
}

export const updateSortByFilterAction = (sortBy) => dispatch => {
  dispatch({
    type: UPDATE_SORT_BY_FILTER,
    data: sortBy
  })
}

export const updateProvinceFilterAction = (province) => dispatch => {
  dispatch({
    type: UPDATE_PROVINCE_FILTER,
    data: province
  })
}

export const updateCityFilterAction = (city) => dispatch => {
  dispatch({
    type: UPDATE_CITY_FILTER,
    data: city
  })
}

export const resetAllFilterAction = () => dispatch => {
  dispatch({
    type: RESET_ALL_FITLERS
  });
}

export const handleLikeUnLikeCarAction = (id) => dispatch => {
  api.post(`cars/like-dislike-car`, { data: { car_id: id } })
    .then((result) => {
      if(result.success) {
        dispatch({
          type: LIKE_UNLIKE_CAR,
          data: result.data
        });
      } else {
        console.log('result', result);
      }
    })
    .catch((err) => {
      console.log('err', err);
    });
}

export const clearAllFilterAndDataAction = () => dispatch => {
  dispatch({
    type: CLEAR_FILTER_AND_DATA
  });
}