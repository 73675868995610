import React from 'react';

import Pagination from "material-ui-flat-pagination";
import styled from "styled-components";

const PaginationWrapper = styled(Pagination)`
  .MuiButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
  }

  .MuiButton-textPrimary {
    color: #f7c413 !important;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  .MuiButton-textPrimary:hover {
    background-color: #f5c82a38 !important;
  }

  .MuiButton-textSecondary {
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: bold;
    background-color: #f7c413 !important;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }

  .MuiButton-textSecondary:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
  }
`;

const MuPagination = ({ limit, offset, total, onClickPageNo, className }) => {
  return (
    <PaginationWrapper
      className={`m-2 ${className ? className : ''}`}
      limit={limit}
      offset={offset}
      total={total}
      onClick={onClickPageNo}
    />
  )
}

export default MuPagination;