import React from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ChatIcon from '@material-ui/icons/Chat';

import './ContactSellerViaEmailDialog.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  submit: {
    backgroundColor: '#F5C82A',
    color: '#000',
    fontWeight: 900,
    '&:hover': {
      backgroundColor: '#F5C82A',
      color: '#000',
    }
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2, 2)
  }
}))(MuiDialogContent);

const ContactSellerViaChatDialog = ({ handleSubmitData }) => {
  const [open, setOpen] = React.useState(false);

  const handleToggleDialog = () => {
    setOpen(!open);
  }

  const classes = useStyles();

  const handleFormSubmit = () => {
    handleToggleDialog();
    handleSubmitData();
  }

  return (
    <React.Fragment>
      <Button className="ContactSellerDialog" onClick={handleToggleDialog}>
        <h5>
          Chat with Dealer
        </h5>
      </Button>
      <Dialog onClose={handleToggleDialog} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleToggleDialog}>
          Tips for a safe deal
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.root}>
            <List className={classes.root}>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ThumbUpIcon />
                </ListItemIcon>
                <ListItemText primary="Never give money or product in advance" />
              </ListItem>
              <Divider component="li" />
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <ChatIcon />
                </ListItemIcon>
                <ListItemText primary="Keep discussions in Chat2Cars chat only" />
              </ListItem>
            </List>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="default"
              className={`${classes.submit} py-2 mt-2`}
              onClick={handleFormSubmit}
            >
              Continue To Chat
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default ContactSellerViaChatDialog;