import React, { useEffect } from 'react'

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/Register/Actions';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';

import { Link, Redirect ,useHistory} from 'react-router-dom';

// Document Head Manager
import { Helmet } from "react-helmet";

// Custom Components
import { SubscribeField } from './../../components/Footer/Footer.style';
import { YellowCheckbox } from './../../components/SidebarFilter/index';
import { Alert } from './../Login';

import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#F5C82A',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#F5C82A',
    color: '#000',
    fontWeight: 900,
    '&:hover': {
      backgroundColor: '#F5C82A',
      color: '#000',
    }
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    minWidth: '100%',
    '& .MuiInput-underline': {
      '&:after': {
        borderBottom: '2px solid #000',
      }
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#000'
    }
  },
}));

const RegisterValidationSchema = Yup.object().shape({
  fullName: Yup.string().required('Please enter full name'),
  province: Yup.string().required('Please select province'),
  city: Yup.string().required('Please select city'),
  email: Yup.string()
          .email('Please enter a valid email')
          .required('Please enter an email'),
  password: Yup.string()
            .required('Please enter a password') 
            .min(8, 'Password is too short - should be 8 chars minimum.'),
            gsm: Yup.number()
                .positive('Must be positive numbers')
                .test('len', 'Must be exactly 9 digits', val => val && val.toString().length === 9),
  terms: Yup.boolean()
          .required("Please accept terms and conditions")
          .oneOf([true], "Please accept terms and conditions")
});

const Register = (props) => {
  const { isAuthenticated, hasError, errorMessage, provinces, cities, resetRegisterParamsAction, loadProvincesAction, loadCitiesAction } = props;
  const classes = useStyles();
  const history=useHistory();
  useEffect(() => {
    if(provinces.length === 0) {
      loadProvincesAction();
    }
  }, [provinces, loadProvincesAction]);

  useEffect(() => {
    return () => {
      resetRegisterParamsAction();
    };
  }, [resetRegisterParamsAction])

  const handleChangeProvince = (id) => {
    loadCitiesAction(id);
  }

  const handleFormSubmit = values => {
    const payload = {
      fullname: values.fullName,
      email: values.email,
      password: values.password,
      province: values.province.state_name,
      city: values.city.city_name,
      gsm:values.gsm
    }

    props.handleRegisterRequestAction(payload, history);
  }

  return (
    isAuthenticated ?
      <Redirect to="/" />
      :
      <Container component="main" maxWidth="sm">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Register | {process.env.REACT_APP_NAME}</title>
        </Helmet>
        <Breadcrumbs className="mt-4" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
            Home
          </Link>
          <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">Register</Typography>
        </Breadcrumbs>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          {hasError &&
            <Alert className="mt-3 w-100" severity="error">{errorMessage}</Alert>
          }
          <Formik
            enableReinitialize
            initialValues={{
              fullName: '',
              province: null,
              city: null,
              gsm: '',
              email: '',
              password: '',
              terms: false,
            }}
            onSubmit={handleFormSubmit}
            validationSchema={RegisterValidationSchema}
          >
            {(renderProps) => {
              const { values: formValues, touched, errors } = renderProps;
              return (
                <Form className={classes.form}>
                  <SubscribeField
                    className="news-letter-input"
                    label="Full name"
                    style={{ width: '100%' }}
                    margin="normal"
                    fullWidth
                    autoFocus
                    newsletter={true}
                    value={formValues.fullName}
                    onChange={e => renderProps.setFieldValue('fullName', e.target.value)}
                    helperText={touched.fullName && errors.fullName}
                    error={touched.fullName && errors.fullName ? true : false}
                  />
                  <Autocomplete
                    options={provinces}
                    getOptionLabel={option => option.state_name}
                    autoComplete
                    includeInputInList
                    renderInput={params =>
                      <SubscribeField
                        {...params}
                        label="Province"
                        className="news-letter-input"
                        margin="normal"
                        fullWidth
                        newsletter={true}
                        helperText={touched.province && errors.province}
                        error={touched.province && errors.province ? true : false}
                      />
                    }
                    value={formValues.province || null}
                    onChange={(event, value) => {
                      if(value) {
                        handleChangeProvince(value._id)
                        renderProps.setFieldValue('city', null)
                      }
                      return renderProps.setFieldValue('province', value ? value : null)
                    }}
                  />
                  <Autocomplete
                    options={cities}
                    getOptionLabel={option => option.city_name}
                    autoComplete
                    includeInputInList
                    renderInput={params =>
                      <SubscribeField
                        {...params}
                        label="City"
                        className="news-letter-input"
                        margin="normal"
                        fullWidth
                        newsletter={true}
                        helperText={touched.city && errors.city}
                        error={touched.city && errors.city ? true : false}
                      />
                    }
                    value={formValues.city || null}
                    onChange={(event, value) => renderProps.setFieldValue('city', value ? value : null)}
                  />
                  <SubscribeField
                    className="news-letter-input"
                    label="Contact Number"
                    style={{ width: '100%' }}
                    margin="normal"
                    fullWidth
                    newsletter={true}
                    value={formValues.gsm}
                    onChange={e => renderProps.setFieldValue('gsm', e.target.value)}
                    helperText={touched.gsm && errors.gsm}
                    error={touched.gsm && errors.gsm ? true : false}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+27</InputAdornment>,
                      type: 'number'
                    }}
                  />
                  <SubscribeField
                    className="news-letter-input"
                    label="Your Email Address"
                    style={{ width: '100%' }}
                    margin="normal"
                    fullWidth
                    id="email"
                    name="email"
                    autoComplete="email"
                    newsletter={true}
                    InputProps={{
                      type: 'email',
                      autoComplete: 'off'
                    }}
                    value={formValues.email}
                    onChange={e => renderProps.setFieldValue('email', e.target.value)}
                    helperText={touched.email && errors.email}
                    error={touched.email && errors.email ? true : false}
                  />
                  <SubscribeField
                    className="news-letter-input"
                    label="Password"
                    style={{ width: '100%' }}
                    margin="normal"
                    fullWidth
                    id="password"
                    name="password"
                    newsletter={true}
                    InputProps={{
                      type: 'password',
                      autoComplete: 'off'
                    }}
                    value={formValues.password}
                    onChange={e => renderProps.setFieldValue('password', e.target.value)}
                    helperText={touched.password && errors.password}
                    error={touched.password && errors.password ? true : false}
                  />
                  <FormControlLabel
                    control={<YellowCheckbox color="primary" checked={formValues.terms} onChange={e => renderProps.setFieldValue('terms', e.target.checked || false)} />}
                    label={<span>I agree to <Link to="/terms" target="_blank">Terms of Services</Link> & <Link to="/privacy" target="_blank">Privacy Policy</Link></span>}
                  />
                  <ErrorMessage className="text-error" name="terms" component="div" />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="default"
                    className={classes.submit}
                  >
                    Sign Up
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link to="/forgot-password">
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/login">
                        {"Already have an account? Sign In"}
                      </Link>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
  )
}

const mapStateToProps = (state) => ({
  ...state.Register,
  isAuthenticated: state.Login.isAuthenticated
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
