import React from 'react';
import { Link } from 'react-router-dom';
import formatNumber from '../../helpers/formatNumber';

const RecentListings = ({ recentList }) => {
  return (
    <div className="widget shadow">
      <div className="widget-heading">
        <h4 className="panel-title">
          Recent Listings
        </h4>
      </div>
      {recentList.map((car, i) => (
        <div className="recent-ads-list" key={i}>
          <div className="recent-ads-container">
            <div className="recent-ads-list-image">
              <Link className="recent-ads-list-image-inner" to={`/second-hand-cars/${car.slug || ''}`}>
                <img alt={car.title} src={car.featured_image} />
              </Link>
            </div>
          </div>
          <div className="recent-ads-list-content">
            <h3 className="recent-ads-list-title">
              <Link to={`/second-hand-cars/${car.slug || ''}`}>{car.title}</Link>
            </h3>
            <ul className="recent-ads-list-location">
              <li>
                <Link to={`/second-hand-cars/${car.slug || ''}`}>{car.address}</Link>
              </li>
            </ul>
            <div className="recent-ads-list-price">{formatNumber(car.price, 2)}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default RecentListings;
