import styled from "styled-components";

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

export const FloatingButton = styled.button`
  position:fixed;
  width: 60px;
  height: 60px;
  bottom: ${props => props.bottom ? `${props.bottom}px` : '40px'};
  right: ${props => props.right ? `${props.right}px` : '40px'};
  background-color: ${props => props.bgcolor ? props.bgcolor : '#009394'};
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  box-shadow: none;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  transition: 0.1s;
  z-index: 11;

  &:active, &:hover {
    background-color: #006270;
  }

  &:focus {
    outline: none;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
  }
`;

export const SwipeableDrawerWrapper = styled(SwipeableDrawer)`
  &:after {
    opacity: 0.6;
    background: #000;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  & .MuiPaper-root.MuiPaper-elevation16 {
    height: 100vh;
    background-color: transparent;
    color: #fff;
  }

  .inner-container {
    position: relative;
    z-index: 1;
  }

  .car-results__filter {
    display: block;
    height: 100vh;
    overflow: auto;
  }
`;
