import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
// packeges imported by nayan
import $ from "jquery";
import ReactTagInput from "@pathofdev/react-tag-input";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Styled components
import {
  ExpansionPanelWrapper,
  ClearAllButton,
  SaveButton,
} from "./SidebarFilter.style";
import SidebarFilterSkeleton from "./SidebarFilterSkeleton";

import "./sidebar-filter.scss";
import { SubscribeField } from "./../Footer/Footer.style";

import "./sideBar_filter.css";
import { useLocation, useHistory } from "react-router-dom";
const SidebarFilterSchema = Yup.object().shape({
  // employee: Yup.object().required('Required'),
  // competencies: Yup.array().min(1).required('Required'),
  // comment: Yup.string().required('Required')
});

export const YellowCheckbox = withStyles({
  root: {
    color: "#f5c82a",
    "&$checked": {
      color: "#f5c82a",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(5),
    color: theme.palette.text.secondary,
    backgroundColor: "#F5C82A",
  },
  gridPosition: {
    marginTop: "-50px",
    position: "relative",
    maxWidth: 1200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
    flex: 1,
    alignSelf: "flex-end",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  searchButton: {
    margin: theme.spacing(1),
    backgroundColor: "#222222",
    height: 50,
    alignSelf: "flex-end",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#222222cc",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#222222",
      borderColor: "#005cbf",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
  listRoot: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  listSubHeader: {
    cursor: "pointer",
  },
  modelCheckbox: {
    padding: 3,
    marginLeft: 15,
  },
});

const PrettoSlider = withStyles({
  root: {
    color: "#f5c82a",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const fuelTypeArray = [
  { id: "Diesel", label: "Diesel" },
  { id: "Electric", label: "Electric" },
  { id: "Petrol", label: "Petrol" },
  { id: "Gasoline", label: "Gasoline" },
  { id: "Hybrid", label: "Hybrid" },
  { id: "LPG Autogas", label: "LPG Autogas" },
];

const transmissionArray = [
  { id: "Automatic", label: "Automatic" },
  { id: "Semi-Automatic", label: "Semi-Automatic" },
  { id: "Manual", label: "Manual" },
];

const ValueLabelComponent = (props) => {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const currentYear = new Date().getFullYear();
const years = [...new Array(15)]?.map((value, index) =>
  (currentYear + index - 14).toString()
);
years.unshift("older than 2010");

const SidebarFilter = ({
  isFilterDrawerOpen,
  closeFilterDrawer,
  brandsList,
  carTypes,
  province,
  city,
  provinces,
  cities,
  handleChangeOtherFieldsData,
  handleChangeProvince,
}) => {
  const [screenWidth, setScreenWidth] = useState(null);

  const bodyType = carTypes?.map((x) => x.name);

  const location = useLocation();
  const history = useHistory();

  const [params, setParams] = useState({
    brandParams: "",
    modelParams: "",
    yearParams: "",
    maxPriceParams: "",
    minPriceParams: "",
    bodyTypesParams: "",
    pageParams: "",
    fuelParams: "",
    transmissionParams: "",
    sortyByParams: "",
  });

  const [cars, setCars] = useState([]);
  const [tags, setTags] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [yearsData, setYearsData] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState([]);
  const [bodyData, setBodyData] = useState([]);
  const [fuelData, setFuelData] = useState([]);
  const [transmissionData, setTransmissionData] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    console.log(" ", searchParams)
    setParams({
      brandParams: searchParams.get("brands") ? searchParams.get("brands") : "",
      modelParams: searchParams.get("models") ? searchParams.get("models") : "",
      yearParams: searchParams.get("year") ? searchParams.get("year") : "",
      maxPriceParams: searchParams.get("maxprice")
        ? searchParams.get("maxprice")
        : 5000000,
      minPriceParams: searchParams.get("minprice")
        ? searchParams.get("minprice")
        : 0,
      bodyTypesParams: searchParams.get("types")
        ? searchParams.get("types")
        : "",
      pageParams: searchParams.get("page") ? searchParams.get("page") : 1,
      fuelParams: searchParams.get("fueltypes")
        ? searchParams.get("fueltypes")
        : "",
      transmissionParams: searchParams.get("transmissiontypes")
        ? searchParams.get("transmissiontypes")
        : "",
      sortyByParams: searchParams.get("sortby")
        ? searchParams.get("sortby")
        : "most_recent",
    });
  }, [location]);

  useEffect(() => {
    let brands = params.brandParams ? params.brandParams.split(",") : [];
    let models = params.modelParams ? params.modelParams.split(",") : [];
    let years = params.yearParams ? params.yearParams.split(",") : [];
    let bodyTypes = params.bodyTypesParams
      ? params.bodyTypesParams.split(",")
      : [];
    let fuelTypeData = params.fuelParams ? params.fuelParams.split(",") : [];
    let transmissionTypeData = params.transmissionParams
      ? params.transmissionParams.split(",")
      : [];

    let newPrice = [params.minPriceParams, params.maxPriceParams];

    setBrands(brands);
    setModels(models);
    setTags([...brands, ...models]);
    setSelectedPrice(newPrice);
    setYearsData(years);
    setBodyData(bodyTypes);
    setFuelData(fuelTypeData);
    setTransmissionData(transmissionTypeData);
  }, [params]);

  useEffect(() => {
    setCars(brandsList);
  }, [brandsList]);

  const handleFormSubmit = (values) => {
    // console.log("values", values);
  };

  const clearFilter = () => {
    history.push("/second-hand-cars");
  };

  const handleBrandsURL = async (brand) => {
    const brandParamsArray = params.brandParams
      ? params.brandParams.split(",")
      : [];
    if (brandParamsArray.includes(brand.maketitle)) {
      const index = brandParamsArray.indexOf(brand.maketitle);
      brandParamsArray.splice(index, 1);
    } else {
      brandParamsArray.push(brand.maketitle);
    }
    history.push(
      `/second-hand-cars?brands=${brandParamsArray.join(",")}&models=${
        params.modelParams
      }&year=${params.yearParams}&maxprice=${params.maxPriceParams}&minprice=${
        params.minPriceParams
      }&types=${params.bodyTypesParams}&fueltypes=${
        params.fuelParams
      }&transmissiontypes=${params.transmissionParams}&page=1&sortby=${
        params.sortyByParams
      }`
    );
  };

  const handleModelURL = (model) => {
    const modelParamsArray = params.modelParams
      ? params.modelParams.split(",")
      : [];
    if (modelParamsArray.includes(model.modeltitle)) {
      const index = modelParamsArray.indexOf(model.modeltitle);
      modelParamsArray.splice(index, 1);
    } else {
      modelParamsArray.push(model.modeltitle);
    }
    history.push(
      `/second-hand-cars?brands=${params.brandParams}&models=${modelParamsArray.join(
        ","
      )}&year=${params.yearParams}&maxprice=${params.maxPriceParams}&minprice=${
        params.minPriceParams
      }&types=${params.bodyTypesParams}&fueltypes=${
        params.fuelParams
      }&transmissiontypes=${params.transmissionParams}&page=1&sortby=${
        params.sortyByParams
      }`
    );
  };

  const handleYearsURL = (x) => {
    history.push(
      `/second-hand-cars?brands=${params.brandParams}&models=${params.modelParams}&year=${x}&maxprice=${params.maxPriceParams}&minprice=${params.minPriceParams}&types=${params.bodyTypesParams}&fueltypes=${params.fuelParams}&transmissiontypes=${params.transmissionParams}&page=1&sortby=${params.sortyByParams}`
    );
  };

  const handleBodyDataURL = (bodyTypes) => {
    history.push(
      `/second-hand-cars?brands=${params.brandParams}&models=${
        params.modelParams
      }&year=${params.yearParams}&maxprice=${params.maxPriceParams}&minprice=${
        params.minPriceParams
      }&types=${bodyTypes ? bodyTypes : ""}&fueltypes=${
        params.fuelParams
      }&transmissiontypes=${params.transmissionParams}&page=1&sortby=${
        params.sortyByParams
      }`
    );
  };

  const handleFuelURL = (e, x) => {
    let array = fuelData || [];
    if (array.includes(x.id)) {
      const index = array.indexOf(x.id);
      array.splice(index, 1);
    } else {
      array.push(x.id);
    }
    history.push(
      `/second-hand-cars?brands=${params.brandParams}&models=${
        params.modelParams
      }&year=${params.yearParams}&maxprice=${params.maxPriceParams}&minprice=${
        params.minPriceParams
      }&types=${params.bodyTypesParams}&fueltypes=${array.join(
        ","
      )}&transmissiontypes=${params.transmissionParams}&page=1&sortby=${
        params.sortyByParams
      }`
    );
  };

  const handleTransmissionURL = (e, x) => {
    const array = transmissionData || [];
    if (array.includes(x.id)) {
      const index = array.indexOf(x.id);
      array.splice(index, 1);
    } else {
      array.push(x.id);
    }
    history.push(
      `/second-hand-cars?brands=${params.brandParams}&models=${
        params.modelParams
      }&year=${params.yearParams}&maxprice=${params.maxPriceParams}&minprice=${
        params.minPriceParams
      }&types=${params.bodyTypesParams}&fueltypes=${
        params.fuelParams
      }&transmissiontypes=${array.join(",")}&page=1&sortby=${
        params.sortyByParams
      }`
    );
  };

  const handlePriceURL = (x) => {
    history.push(
      `/second-hand-cars?brands=${params.brandParams}&models=${
        params.modelParams
      }&year=${params.yearParams}&maxprice=${x[1] ? x[1] : 5000000}&minprice=${
        x[0] ? x[0] : 0
      }&types=${params.bodyTypesParams}&fueltypes=${
        params.fuelParams
      }&transmissiontypes=${params.transmissionParams}&page=1&sortby=${
        params.sortyByParams
      }`
    );
  };

  useEffect(() => {
    const updateWindowDimensions = () => {
      const width = window.innerWidth;
      setScreenWidth(width);

      if (width > 1024 && isFilterDrawerOpen) {
        closeFilterDrawer();
      }
    };
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, [isFilterDrawerOpen, closeFilterDrawer]);

  // CODE BY NAYAN BANODHA

  const listHandler = (elem) => {
    $(`#model-block-${elem}`).toggleClass("d-none");

    $(`#model-toggle-btn-${elem}`).toggleClass("show");
  };

  const dropdownContentHandler = () => {
    $(".options-boxx").toggleClass("d-none");
    $(".options-wrapperr").toggleClass("d-none");
  };
  $("#carResult_mainn").mouseup(function (e) {
    var container = $(".flex-column");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      $(".options-boxx").addClass("d-none");
      $(".options-wrapperr").addClass("d-none");
      //container.hide();
    }
  });

  const getSearchResults = (input_name) => {
    let value = input_name.toLowerCase();
    $(".options-boxx").filter(function () {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) == 0);
    });
  };

  $(".react-tag-input__input").on("keyup", () => {
    let value = $(".react-tag-input__input").val();
    if (value.length > 0) {
      $(".options-boxx").removeClass("d-none");
      $(".options-wrapperr").removeClass("d-none");
    } else {
      $(".options-boxx").addClass("d-none");
      $(".options-wrapperr").addClass("d-none");
    }
    getSearchResults(value);
  });

  return <Formik
      enableReinitialize
      initialValues={{
        vehicleType: [],
        vehicleModel: [],
        vehicleBrand: [],
        vehicleYear: [],
        fuelType: [],
        province: "",
        city: "",
        price: [0, 5000000],
        kmsDriven: [0, 200000],
      }}
      validationSchema={SidebarFilterSchema}
      onSubmit={handleFormSubmit}
    >
      {(renderProps) => {
        return (
          <div
            className={`car-results__filter p-2 ${
              screenWidth && screenWidth <= 1024 && isFilterDrawerOpen
                ? "car-results__filter-responsive d-block-animation"
                : ""
            }`}
          >
            <Form onSubmit={renderProps.handleSubmit}>
              <div className="car-results__filter-header text-uppercase">
                <IconButton
                  className="car-results__filter-header-icon p-1 cursor-pointer"
                  aria-label="close filter"
                  onClick={closeFilterDrawer}
                >
                  <CloseIcon />
                </IconButton>
                <div className="car-results__filter-header-name">Filters</div>
                <ClearAllButton
                  className="text-uppercase"
                  style={{ fontWeight: 900 }}
                  color="primary"
                  onClick={() => {
                    clearFilter();
                  }}
                >
                  Clear All
                </ClearAllButton>
              </div>
              <ExpansionPanelWrapper defaultExpanded={true}>
                <ExpansionPanelSummary
                  className="car-results__filter-header text-uppercase"
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="additional-actions3-content"
                  id="additional-actions3-header"
                >
                  Brand And Model
                </ExpansionPanelSummary>

                <ExpansionPanelDetails className="flex-column">
                  <div className="list-wrapper car_listing_main_bar">
                    <div
                      id="cars-and-models"
                      onClick={dropdownContentHandler}
                      className="d-flex ai-center"
                    >
                      <ReactTagInput
                        placeholder="Select Brand & Model"
                        tags={tags}
                        onChange={(newTags) => {
                          let newBrands = brands.filter((brand) =>
                            newTags.includes(brand)
                          );
                          let newModels = models.filter((model) =>
                            newTags.includes(model)
                          );
                          history.push(
                            `/second-hand-cars?brands=${
                              newBrands ? newBrands : ""
                            }&models=${newModels ? newModels : ""}&year=${
                              params.yearParams
                            }&maxprice=${params.maxPriceParams}&minprice=${
                              params.minPriceParams
                            }&types=${params.bodyTypesParams}&fueltypes=${
                              params.fuelParams
                            }&transmissiontypes=${
                              params.transmissionParams
                            }&page=1&sortby=${params.sortyByParams}`
                          );
                        }}
                      />

                      <svg
                        className="MuiSvgIcon-root"
                        id="tag-input-icon"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path d="M7 10l5 5 5-5z"></path>
                      </svg>
                    </div>

                    <div className="options-wrapperr d-none ">
                      {cars &&
                        cars.map((car) => (
                          <div className="options-boxx d-none">
                            <div className="d-flex ai-center py-2 jc-sb px-3 cars-list">
                              <span className="inline-block">
                                <div className="checkbox">
                                  <label className="mb-0">
                                    {car.maketitle}
                                    <input
                                      type="checkbox"
                                      className="mr-2"
                                      value=""
                                      name={`${car.maketitle}`}
                                      checked={
                                        tags?.includes(car.maketitle)
                                          ? true
                                          : false
                                      }
                                      id={car._id}
                                      onChange={() => {
                                        handleBrandsURL(car);
                                      }}
                                    />
                                    <span className="checkmark"></span>

                                    <small> [{car.count_models}]</small>
                                  </label>
                                </div>
                              </span>
                              <span
                                className="inline-block f-14 fun-link hide_show_link"
                                id={`model-toggle-btn-${car._id}`}
                                onClick={() => listHandler(car._id)}
                              ></span>
                            </div>
                            <div
                              className="models ml-5 d-none sub_dropdown_menu"
                              id={`model-block-${car._id}`}
                            >
                              {car?.models?.map((model, index) => (
                                <div className="checkbox">
                                  <label className="mb-2">
                                    <input
                                      type="checkbox"
                                      className="mr-2"
                                      name={`${model.modeltitle}`}
                                      id={`${car.maketitle.replace(
                                        " ",
                                        ""
                                      )}-${index}`}
                                      checked={
                                        tags?.includes(model.modeltitle)
                                          ? true
                                          : false
                                      }
                                      value={`${model.modeltitle}`}
                                      onChange={() => {
                                        handleModelURL(model);
                                      }}
                                    />
                                    <span className="checkmark"></span>
                                    {model.modeltitle}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanelWrapper>
              <ExpansionPanelWrapper defaultExpanded={true}>
                <ExpansionPanelSummary
                  className="car-results__filter-header text-uppercase"
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="additional-actions4-content"
                  id="additional-actions4-header"
                >
                  Price
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ flexDirection: "column" }}>
                  <div className="d-flex justify-content-between mb-3">
                    <TextField
                      id="minimum-price"
                      label="Minimum Price"
                      type="number"
                      className="mr-1"
                      value={selectedPrice[0]}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        setSelectedPrice([e.target.value, selectedPrice[1]])
                      }
                    />
                    <TextField
                      id="maximum-price"
                      label="Maximum Price"
                      type="number"
                      className="ml-1"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={selectedPrice[1]}
                      onChange={(e) =>
                        setSelectedPrice([selectedPrice[0], e.target.value])
                      }
                    />
                    <SaveButton
                      color="primary"
                      aria-label="save"
                      onClick={() => handlePriceURL(selectedPrice)}
                    >
                      <SaveIcon />
                    </SaveButton>
                  </div>
                  <Typography
                    component="div"
                    color="textPrimary"
                    className="position-relative"
                    style={{ width: "100%", left: 0 }}
                  >
                    <PrettoSlider
                      className="position-initial"
                      ValueLabelComponent={ValueLabelComponent}
                      valueLabelDisplay="auto"
                      getAriaLabel={(index) =>
                        index === 0 ? "Minimum price" : "Maximum price"
                      }
                      value={selectedPrice}
                      onChange={(event, values) => setSelectedPrice(values)}
                      max={5000000}
                      min={0}
                      step={50}
                    />
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanelWrapper>
              <ExpansionPanelWrapper defaultExpanded={true}>
                <ExpansionPanelSummary
                  className="car-results__filter-header text-uppercase"
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="additional-actions5-content"
                  id="additional-actions5-header"
                >
                  Year
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                  className="pl-1 pr-1"
                  style={{ flexDirection: "column", margin: "0 10px" }}
                >
                  <Autocomplete
                    multiple
                    id="grouped-demo-year"
                    options={years}
                    getOptionLabel={(option) =>
                      option === 2006 ? "Older than 2006" : option
                    }
                    className="mt-3"
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option === 2006 ? "Older than 2006" : option}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <SubscribeField
                        {...params}
                        variant="standard"
                        placeholder="Year"
                        fullWidth
                        label="Year"
                        className="news-letter-input mt-0"
                        margin="normal"
                        newsletter={true}
                      />
                    )}
                    value={yearsData}
                    onChange={(event, value) => handleYearsURL(value)}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanelWrapper>
              <ExpansionPanelWrapper defaultExpanded={true}>
                <ExpansionPanelSummary
                  className="car-results__filter-header text-uppercase"
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="additional-actions6-content"
                  id="additional-actions6-header"
                >
                  Fuel
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography component="div" color="textPrimary">
                    {fuelTypeArray.map((value, index) => {
                      return (
                        <FormControlLabel
                          key={value.id}
                          className="d-block"
                          control={
                            <YellowCheckbox
                              checked={
                                fuelData ? fuelData.includes(value.id) : false
                              }
                              onChange={(e) => handleFuelURL(e, value)}
                              value={value.id}
                            />
                          }
                          label={value.label}
                        />
                      );
                    })}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanelWrapper>
              <ExpansionPanelWrapper defaultExpanded={true}>
                <ExpansionPanelSummary
                  className="car-results__filter-header text-uppercase"
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="additional-actions6-content"
                  id="additional-actions6-header"
                >
                  Transmission
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography component="div" color="textPrimary">
                    {transmissionArray.map((value, index) => {
                      return (
                        <FormControlLabel
                          key={value.id}
                          className="d-block"
                          control={
                            <YellowCheckbox
                              checked={
                                transmissionData
                                  ? transmissionData.includes(value.id)
                                  : false
                              }
                              onChange={(e) => handleTransmissionURL(e, value)}
                              value={value.id}
                            />
                          }
                          label={value.label}
                        />
                      );
                    })}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanelWrapper>
              <ExpansionPanelWrapper defaultExpanded={true}>
                <ExpansionPanelSummary
                  className="car-results__filter-header text-uppercase"
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="additional-actions2-content"
                  id="additional-actions2-header"
                >
                  Body Type
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="flex-column">
                  <Autocomplete
                    multiple
                    id="grouped-demo-bodyType"
                    options={bodyType}
                    className="mt-3"
                    getOptionLabel={(option) => option}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <SubscribeField
                        {...params}
                        variant="standard"
                        placeholder="Body Type"
                        fullWidth
                        label="Body Type"
                        className="news-letter-input mt-0"
                        margin="normal"
                        newsletter={true}
                      />
                    )}
                    value={bodyData}
                    onChange={(event, value) => handleBodyDataURL(value)}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanelWrapper>
              <ExpansionPanelWrapper defaultExpanded={true}>
                <ExpansionPanelSummary
                  className="car-results__filter-header text-uppercase"
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="additional-actions2-content"
                  id="additional-actions2-header"
                >
                  Locations
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="flex-column">
                  <Autocomplete
                    options={provinces}
                    getOptionLabel={(option) => option.state_name}
                    autoComplete
                    includeInputInList
                    renderInput={(params) => (
                      <SubscribeField
                        {...params}
                        label="Province"
                        className="news-letter-input"
                        margin="normal"
                        fullWidth
                        newsletter={true}
                      />
                    )}
                    value={province || null}
                    onChange={(event, value) => {
                      if (value) {
                        handleChangeProvince(value._id);
                        handleChangeOtherFieldsData("city", null);
                      }
                      return handleChangeOtherFieldsData(
                        "province",
                        value ? value : null
                      );
                    }}
                  />
                  <Autocomplete
                    options={cities}
                    getOptionLabel={(option) => option.city_name}
                    autoComplete
                    includeInputInList
                    renderInput={(params) => (
                      <SubscribeField
                        {...params}
                        label="City"
                        className="news-letter-input"
                        margin="normal"
                        fullWidth
                        newsletter={true}
                      />
                    )}
                    value={city || null}
                    onChange={(event, value) =>
                      handleChangeOtherFieldsData("city", value ? value : null)
                    }
                  />
                </ExpansionPanelDetails>
              </ExpansionPanelWrapper>
            </Form>
          </div>
        );
      }}
    </Formik>
};

export default withStyles(styles)(SidebarFilter);
