import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import NotificationImportant from '@material-ui/icons/NotificationImportant';

const useStyles = makeStyles(theme => ({
  unreadMessage: {
    color: 'red',
    position: 'absolute',
    top: '0',
    right: '5px'
  }
}));

const ChatSidebar = (props) => {
  const { isSidebarLoading, selectedChatId, chatList, chatListFromFirebase, currentUser, handleSelectChat } = props;
  const classes = useStyles();

  return (
    isSidebarLoading || chatList.length === 0 || chatListFromFirebase === 0 ?
      <div className="chats__chat-users-list p-0">
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </div>
      :
      chatListFromFirebase.length > 0 ?
        <div className="chats__chat-users-list shadow">
          <List className="p-0">
            {chatListFromFirebase.map(chat => {
              const listUserId = chat.users.filter(x => x !== currentUser.id)[0];
              const listUser = listUserId ? chatList.find(x => x.sender._id === listUserId || x.reciever._id === listUserId) : undefined;
              const filterFromListUser = listUser ? listUser.sender._id === listUserId ? listUser.sender : listUser.reciever : undefined;
              return (
                filterFromListUser ?
                  <React.Fragment key={filterFromListUser._id}>
                    <ListItem className={filterFromListUser._id === selectedChatId ? "chat-active" : ''} button onClick={() => handleSelectChat(filterFromListUser._id)}>
                      <ListItemAvatar>
                        <Avatar
                          alt={`Avatar`}
                          src={filterFromListUser.avatarurl}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={filterFromListUser.fullname}
                        secondary={
                          <React.Fragment>
                            <Typography component="span" color="textPrimary">
                              {`${chat.messages[chat.messages.length - 1].message.substring(0, 30)}${chat.messages[chat.messages.length - 1].message.length > 30 ? '...':''}`}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      {chat.receiverHasRead === false && chat.messages[chat.messages.length - 1].sender !== currentUser.id &&
                        <ListItemIcon><NotificationImportant className={classes.unreadMessage}></NotificationImportant></ListItemIcon>
                      }
                    </ListItem>
                    <Divider component="li" />
                  </React.Fragment>
                  :
                  null
              )
            })}
            {/* {chatList.map(chat => {
              const listUser = chat.reciever._id !== currentUser.id ? chat.reciever : chat.sender;
              return (
                <ListItem key={listUser._id} className={listUser._id === selectedChatId ? "chat-active" : ''} button onClick={() => handleSelectChat(listUser._id)}>
                  <ListItemAvatar>
                    <Avatar
                      alt={`Avatar`}
                      src={listUser.avatarurl}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={listUser.fullname} />
                </ListItem>
              )
            })} */}
          </List>
        </div>
        :
        null
  )
}

export default ChatSidebar;
