import React from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { Link } from "react-router-dom";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SubscribeField } from './../Footer/Footer.style';

import './ContactSellerViaEmailDialog.css';
import formatNumber from '../../helpers/formatNumber';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  submit: {
    backgroundColor: '#F5C82A',
    color: '#000',
    fontWeight: 900,
    '&:hover': {
      backgroundColor: '#F5C82A',
      color: '#000',
    }
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const ContactSellerValidationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter your name'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Please enter an email'),
  countryCode: Yup.string().required(),
  gsm: Yup.number().positive('Must be positive numbers').required('Contact no. is required'),
    // .test('len', 'Must be exactly 9 digits', val => val && val.toString().length === 9),
  message: Yup.string().required('Please enter message'),
});

const ContactSellerViaEmailDialog = ({ carData, isAuthenticated, handleSubmitData }) => {
  const [open, setOpen] = React.useState(false);

  const handleToggleDialog = () => {
    setOpen(!open);
  }

  const classes = useStyles();

  const handleFormSubmit = ({ countryCode, ...values}) => {
    const newValues = {
      ...values,
      gsm: `${countryCode}${values.gsm}`,
      car_id: carData._id
    }

    handleToggleDialog();
    handleSubmitData(newValues);
  }

  return (
    <React.Fragment>
      <Button className="ContactSellerDialog" onClick={handleToggleDialog}>
        <h5>
          Contact Seller Via Email
      </h5>
      </Button>
      <Dialog onClose={handleToggleDialog} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleToggleDialog}>
          Email for Price
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                {/* <img src={`${process.env.REACT_APP_BASE_PATH}/media/singlecarslider/1.jpg`} className="img-fluid" alt="" /> */}
                <img src={carData.featured_image} className="img-fluid" alt={carData.title} />
              </Grid>
              <Grid item xs={6}>
                <div className="recent-ads-list-content pl-0">
                  <h3 className="recent-ads-list-title" id="subject">
                    <Link to="#">{carData.title}</Link>
                  </h3>
                  <ul className="recent-ads-list-location py-2">
                    <li>
                      <Link to="#">{carData.address}</Link>
                    </li>
                  </ul>
                  <div className="recent-ads-list-price py-2">{formatNumber(carData.price, 2)}</div>
                  <p className="m-0">
                    <Link to="#" className="RecentAdsName">
                      {carData.creator.fullname}
                    </Link>
                  </p>
                  <p className="RecentAdsMail">{isAuthenticated ? carData.creator.email || '-' : '-' }</p>
                </div>
              </Grid>
            </Grid>
            <Formik
              enableReinitialize
              initialValues={{
                name: '',
                email: '',
                gsm: '',
                message: '',
                countryCode: '+27',
              }}
              onSubmit={handleFormSubmit}
              validationSchema={ContactSellerValidationSchema}
            >
              {(renderProps) => {
                const { values: formValues, touched, errors } = renderProps;
                return (
                  <Form>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <SubscribeField
                          label="Your Name"
                          variant="outlined"
                          className="news-letter-input DialogTextBox mt-0"
                          newsletter={true}
                          autoFocus
                          value={formValues.name}
                          onChange={e => renderProps.setFieldValue('name', e.target.value)}
                          helperText={touched.name && errors.name}
                          error={touched.name && errors.name ? true : false}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SubscribeField
                          label="Email Address"
                          variant="outlined"
                          className="news-letter-input DialogTextBox mt-0"
                          newsletter={true}
                          InputProps={{
                            type: 'email',
                            autoComplete: 'off'
                          }}
                          value={formValues.email}
                          onChange={e => renderProps.setFieldValue('email', e.target.value)}
                          helperText={touched.email && errors.email}
                          error={touched.email && errors.email ? true : false}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          variant="outlined"
                          label="Country Code"
                          fullWidth
                          value={formValues.countryCode}
                          onChange={e => renderProps.setFieldValue('countryCode', e.target.value)}
                          // helperText={touched.countryCode && errors.countryCode}
                          error={touched.countryCode && errors.countryCode ? true : false}
                        >
                          <MenuItem value={'+27'}>+27 (South Africa)</MenuItem>
                          <MenuItem value={'+264'}>+264 (Namibia)</MenuItem>
                          <MenuItem value={'+267'}>+267 (Botswana)</MenuItem>
                          <MenuItem value={'+263'}>+263 (Zimbabwe)</MenuItem>
                          <MenuItem value={'+258'}>+258 (Mozambique)</MenuItem>
                          <MenuItem value={'+260'}>+260 (Zambia)</MenuItem>
                          <MenuItem value={'+265'}>+265 (Malawi)</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={8}>
                        <SubscribeField
                          label="Contact No"
                          variant="outlined"
                          margin="normal"
                          className="news-letter-input DialogTextBox mt-0"
                          newsletter={true}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">{formValues.countryCode}</InputAdornment>,
                            type: 'number'
                          }}
                          value={formValues.gsm}
                          onChange={e => renderProps.setFieldValue('gsm', e.target.value)}
                          helperText={touched.gsm && errors.gsm}
                          error={touched.gsm && errors.gsm ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SubscribeField
                          variant="outlined"
                          className="news-letter-input DialogTextBox mt-0"
                          label="Messsage"
                          style={{ width: '100%' }}
                          margin="normal"
                          fullWidth
                          id="Messsage"
                          name="Messsage"
                          multiline
                          rowsMax="5"
                          rows="5"
                          newsletter={true}
                          value={formValues.message}
                          onChange={e => renderProps.setFieldValue('message', e.target.value)}
                          helperText={touched.message && errors.message}
                          error={touched.message && errors.message ? true : false}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="default"
                          className={classes.submit}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default ContactSellerViaEmailDialog;