import React, { useEffect, useRef } from "react";

import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const SingleBlogListing = ({ title, image, slug }) => {
  const titleRef = useRef(null);

  useEffect(() => {
    const titleElement = titleRef.current;
    if (titleElement.scrollWidth > titleElement.offsetWidth) {
      titleElement.title = title;
    }
  }, [title]);
  return (
    <div className="post loop-short title-color-hover-design">
      <Link to={`/blog/${slug}`} className="thumb container-img">
        <picture
          className="img-responsive first"
          style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }}
        >
          <img
            style={{ maxWidth: "105%", width: "100%", height: "300px", borderRadius:"5px" }}
            className="blog-img"
            src={image}
            alt={title}
          />
        </picture>
      </Link>
      <div className="m-0 bg-transparent">
        <div className="content-txt-loop bg-transparent">
          <h4 className="title-article mt-3" ref={titleRef}>
            <Link
              to={`/blog/${slug}`}
              title={title}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {title}
            </Link>
          </h4>
          <div className="mt-2 d-flex justify-content-center align-items-center">
            <Button
              component={Link}
              to={`/blog/${slug}`}
              className="post-read-more-button"
            >
              Read More
            </Button>
            <FacebookShareButton
              className="mx-2"
              url={`${process.env.REACT_APP_PUBLIC_URL}/blog/${slug}`}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <LinkedinShareButton
              className="mx-2"
              url={`${process.env.REACT_APP_PUBLIC_URL}/blog/${slug}`}
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <TwitterShareButton
              className="mx-2"
              url={`${process.env.REACT_APP_PUBLIC_URL}/blog/${slug}`}
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton
              className="mx-2"
              url={`${process.env.REACT_APP_PUBLIC_URL}/blog/${slug}`}
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleBlogListing;
