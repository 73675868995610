import {
  SAVE_CONTACT_DETAIL,
  SAVE_CONTACT_DETAIL_FAILURE,
  SET_LOADING,
  RESET_CONTACT_OPTION,
} from './Constants';
// import { ADDED_NOTIFICATION } from "../Notification/Constants";
import Api from '../../helpers/ApiHandler';

const api = new Api();

export const submitContactDataAction = (payload) => dispatch => {
  api.post(`contact_us/save-contact-us-email`, { data: payload, skipAuth: true })
    .then((result) => {
      if (result.success) {
        dispatch({
          type: SAVE_CONTACT_DETAIL,
          data: result.msg
        });
      } else {
        dispatch({
          type: SAVE_CONTACT_DETAIL_FAILURE,
          data: result.msg
        })
      }
    })
    .catch((err) => {
      // console.log('err', err);
    });
}

export const setLoadingFlagAction = () => dispatch => {
  dispatch({
    type: SET_LOADING
  })
}

export const resetAllContactUsOptionAction = () => dispatch => {
  dispatch({
    type: RESET_CONTACT_OPTION
  })
}
