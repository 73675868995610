import React from 'react';

import { SliderWrapper } from './../LatestListings/LatestListing.style';
// import { UIDecorLine } from '../Footer/Footer.style';

import SingleBlogListing from './SingleBlogListing';

import './latest-blog.css';


const LatestBlogs = (props) => {
  const { classNames, blogsData } = props;

  const options = {
    centerPadding: '80px',
    slidesToShow: 3,
    // autoplay: true,
    // autoplaySpeed: 5000,
    infinite: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          arrows: true,
          centerPadding: '40px',
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerPadding: '40px',
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <section className={`latest-blogs__section pt-0`}>
      <div className="section-head py-4 bg-primary">
        <h2 className="text-uppercase section-title text-center w-100">
          Latest Blogs
        </h2>
        {/* <UIDecorLine /> */}
      </div>
      <SliderWrapper className={`${classNames} grid-list-post p-2 mt-5 text-center latest-blog_style`} {...options} noMaxHeight>
        {blogsData.map(blog => {
          return (
            <>
            <SingleBlogListing key={blog._id} title={blog.title} image={blog.image} slug={blog.slug} timeAgo={blog.time_ago} updatedAt={blog.updated_at} />
            </>)
        })}
      </SliderWrapper>
    </section>
  );
};

export default LatestBlogs;
