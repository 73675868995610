import styled from "styled-components";
import Slider from "react-slick";
import { Link } from 'react-router-dom';

export const ServiceSlider = styled(Slider)`
  overflow: hidden;
  div {
    div {
      div {
        outline: none;
      }
    }
  }
`;

export const ServicesMain = styled(Link)`
  position: relative;
  display: flex;
  height: 350px;
  padding: 40px 40px;
  color: #fff;
  background-size: cover;
  user-select: none;
  transition: all .3s;
  align-items: flex-end;
  background-image: ${props => `url(${props.bgimage || `${process.env.REACT_APP_BASE_PATH}/media/services/bg-1.jpg`})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  cursor: pointer;
`;