import React, { useEffect } from 'react'

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/Login/Actions';

// Material UI Components
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MuiAlert from '@material-ui/lab/Alert';

// Document Head Manager
import { Helmet } from "react-helmet";

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link, Redirect } from 'react-router-dom';

// Custom Components
import { SubscribeField } from './../../components/Footer/Footer.style';
import { YellowCheckbox } from './../../components/SidebarFilter/index';

export const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#F5C82A',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#F5C82A',
    color: '#000',
    fontWeight: 900,
    '&:hover': {
      backgroundColor: '#F5C82A',
      color: '#000',
    }
  },
}));

const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Please enter an email'),
  password: Yup.string().required('Please enter a password'),
});

const Login = (props) => {
  const { isAuthenticated, hasError, errorMessage, resetLoginParamsAction } = props;

  const classes = useStyles();

  const handleFormSubmit = (values) => {
    props.handleLoginRequestAction(values);
  }

  useEffect(() => {
    return () => {
      resetLoginParamsAction();
    };
  }, [resetLoginParamsAction])

  return (
    isAuthenticated ?
      <Redirect to="/" />
      :
      <Container component="main" maxWidth="sm">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Login | {process.env.REACT_APP_NAME}</title>
        </Helmet>
        <Breadcrumbs className="mt-4" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
            Home
            </Link>
          <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">Login</Typography>
        </Breadcrumbs>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {hasError &&
            <Alert className="mt-3 w-100" severity="error">{errorMessage}</Alert>
          }
          <Formik
            enableReinitialize
            initialValues={{
              email: '',
              password: '',
            }}
            onSubmit={handleFormSubmit}
            validationSchema={LoginValidationSchema}
            validateOnChange={false}
          >
            {(renderProps) => {
              const { values: formValues } = renderProps;
              return (
                <Form className={classes.form}>
                  <SubscribeField
                    className="news-letter-input"
                    label="Your Email Address"
                    style={{ width: '100%' }}
                    margin="normal"
                    fullWidth
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    newsletter={true}
                    value={formValues.email}
                    onChange={e => renderProps.setFieldValue('email', e.target.value)}
                    helperText={renderProps.errors.email || ''}
                    error={renderProps.errors.email ? true : false}
                  />
                  <SubscribeField
                    className="news-letter-input"
                    label="Password"
                    style={{ width: '100%' }}
                    margin="normal"
                    fullWidth
                    id="password"
                    name="password"
                    autoComplete="current-password"
                    newsletter={true}
                    InputProps={{
                      type: 'password',
                    }}
                    value={formValues.password}
                    onChange={e => renderProps.setFieldValue('password', e.target.value)}
                    helperText={renderProps.errors.password || ''}
                    error={renderProps.errors.password ? true : false}
                  />
                  <FormControlLabel
                    control={<YellowCheckbox value="remember" color="primary" />}
                    label="Remember me"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="default"
                    className={classes.submit}
                  >
                    Sign In
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link to="/forgot-password">
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/register">
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Container>
  )
}

const mapStateToProps = (state) => ({
  ...state.Login
})

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
