import React from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Redirect,Link } from 'react-router-dom';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { SubscribeField } from '../Footer/Footer.style';

import './ContactSellerViaEmailDialog.css';
import { CardContent, Card, FormControl } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  submit: {
    backgroundColor: '#F5C82A',
    color: '#000',
    fontWeight: 900,
    '&:hover': {
      backgroundColor: '#F5C82A',
      color: '#000',
    }
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const ContactSellerValidationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter your name'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Please enter an email'),
  countryCode: Yup.string().required(),
  gsm: Yup.number().positive('Must be positive numbers').required('Contact no. is required'),
    // .test('len', 'Must be exactly 9 digits', val => val && val.toString().length === 9),
  message: Yup.string().required('Please enter message'),
});

const ContactDealer = ({ carData, isAuthenticated, handleSubmitData }) => {
  const [open, setOpen] = React.useState(false);

  const handleToggleDialog = () => {
    setOpen(!open);
  }

  const classes = useStyles();
 
  const handleFormSubmit = ({ countryCode, ...values}) => {
    const newValues = {
      ...values,
      gsm: `${countryCode}${values.gsm}`,
      car_id: carData._id
    }

    // console.log(values.name);
  
    handleToggleDialog();
    handleSubmitData(newValues);
    nm_fetch(newValues);
    // console.log(newValues);
  }

  const nm_fetch=(fetch_value)=>{
  // console.log(fetch_value);
  }
  
  return (
    open ?
    <Redirect to={{pathname:"/thankyou",state:{carData} }} />
    :
    <React.Fragment>
          <div className={classes.root}>
            {/* {console.log('handleSubmitData', handleSubmitData)} */}
            <Formik
              enableReinitialize
              initialValues={{
                name: '',
                email: '',
                gsm: '',
                message: '',
                countryCode: '+27',
              }}
              onSubmit={handleFormSubmit}
              validationSchema={ContactSellerValidationSchema}
            >
              {(renderProps) => {
                const { values: formValues, touched, errors } = renderProps;
                // console.log(renderProps);
                return (
                  <Card className="delearContactCard" variant="outlined">
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h5">Contact Dealer </Typography>
                      <Form>
                        <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <SubscribeField
                              variant="outlined"
                              className="news-letter-input DialogTextBox mt-0 contact-input"
                              placeholder="I am interested  in a price quote on this vehicle. Please contact me at your earliest convenience with your best price."
                              style={{ width: '100%' }}
                              margin="normal"
                              fullWidth
                              id="Messsage"
                              name="Messsage"
                              multiline
                              rowsMax="5"
                              rows="5"
                              newsletter={true}
                              value={formValues.message}
                              onChange={e => renderProps.setFieldValue('message', e.target.value)}
                              helperText={touched.message && errors.message}
                              error={touched.message && errors.message ? true : false}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <SubscribeField
                              placeholder="First Name, Last Name"
                              variant="outlined"
                              className="news-letter-input DialogTextBox mt-0 contact-input"
                              newsletter={true}
                              value={formValues.name}
                              onChange={e => renderProps.setFieldValue('name', e.target.value)}
                              helperText={touched.name && errors.name}
                              error={touched.name && errors.name ? true : false}
                            />
                          </Grid>
                          <Grid item xs={12}>
                          
                            <SubscribeField
                              placeholder="Email Address"
                              variant="outlined"
                              className="news-letter-input DialogTextBox mt-0 contact-input"
                              newsletter={true}
                              InputProps={{
                                type: 'email',
                                autoComplete: 'off'
                              }}
                              value={formValues.email}
                              onChange={e => renderProps.setFieldValue('email', e.target.value)}
                              helperText={touched.email && errors.email}
                              error={touched.email && errors.email ? true : false}
                            />
                            </Grid>
                            
                          <Grid item xs={4}>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              variant="outlined"
                              className='country-code-select-border contact-input'
                              label="Country Code"
                              fullWidth
                              value={formValues.countryCode}
                              onChange={e => renderProps.setFieldValue('countryCode', e.target.value)}
                              // helperText={touched.countryCode && errors.countryCode}
                              error={touched.countryCode && errors.countryCode ? true : false}
                            >
                              <MenuItem value={'+27'}>+27 (South Africa)</MenuItem>
                              <MenuItem value={'+264'}>+264 (Namibia)</MenuItem>
                              <MenuItem value={'+267'}>+267 (Botswana)</MenuItem>
                              <MenuItem value={'+263'}>+263 (Zimbabwe)</MenuItem>
                              <MenuItem value={'+258'}>+258 (Mozambique)</MenuItem>
                              <MenuItem value={'+260'}>+260 (Zambia)</MenuItem>
                              <MenuItem value={'+265'}>+265 (Malawi)</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={8}>
                            <SubscribeField
                              placeholder="Phone Number"
                              variant="outlined"
                              margin="normal"
                              className="news-letter-input DialogTextBox mt-0 contact-input"
                              newsletter={true}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">{formValues.countryCode}</InputAdornment>,
                                type: 'number'
                              }}
                              value={formValues.gsm}
                              onChange={e => renderProps.setFieldValue('gsm', e.target.value)}
                              helperText={touched.gsm && errors.gsm}
                              error={touched.gsm && errors.gsm ? true : false}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              color="default"
                              className={classes.submit}
                            >
                              Send Message
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    </CardContent>
                  </Card>
                )
              }}
            </Formik>
          </div>
    </React.Fragment>
  );
}

export default ContactDealer;