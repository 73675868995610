import React from "react";

import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import SpeedIcon from '@material-ui/icons/Speed';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocalCarWashIcon from '@material-ui/icons/LocalCarWash';
import SettingsIcon from '@material-ui/icons/Settings';

import "./SingleCarKeyFeatures.css";

const SingleCarKeyFeatures = ({ carData }) => {
  return (
    <div className="key-features shadow">
      <div className="box-icon">
        <LocalGasStationIcon className="petrol" style={{ fontSize: 40 }} />
        <div>
          <span>{carData.fueltype}</span>
        </div>
      </div>
      <div className="box-icon">
        <SpeedIcon className="kilo-meter" style={{ fontSize: 40 }} />
        <div>
          <span>{carData.mileage} kms</span>
        </div>
      </div>
      <div className="box-icon">
        <img alt="C63 cc" src={`${process.env.REACT_APP_BASE_PATH}/media/singlecarslider/motor.svg`} style={{ width: 40 }} />
        <div>
          <span>{carData.engine}</span>
        </div>
      </div>
      <div className="box-icon">
        <DateRangeIcon className="reg-year" style={{ fontSize: 40 }} />
        <div>
          <span>{carData.year}</span>
        </div>
      </div>
      <div className="box-icon">
        <img alt="Automatic" src={`${process.env.REACT_APP_BASE_PATH}/media/singlecarslider/gearshift.svg`} style={{ width: 40 }} />
        <div>
          <span>{carData.transmission}</span>
        </div>
      </div>
      <div className="box-icon">
        <LocalCarWashIcon className="body-type" style={{ fontSize: 40 }} />
        <div>
          <span>{carData.bodytype}</span>
        </div>
      </div>
      <div className="box-icon">
        <SettingsIcon className="car-color" style={{ fontSize: 40 }} />
        <div>
          <span>{carData.exteriorcolor}</span>
        </div>
      </div>
    </div>
  );
}

export default SingleCarKeyFeatures;
