import React, { useEffect, Component } from "react";

// For redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "./../../redux/BlogDetail/Actions";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuPagination from "./../../components/MuPagination/index";
import "./blog.css";

import { useHistory } from "react-router-dom";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { Grid, ButtonBase, Button } from "@material-ui/core";
import { Helmet } from "react-helmet";
// import MetaTags from 'react-meta-tags';
import SingleBlogListing from "../../components/LatestBlogs/SingleBlogListing";
import Videoiframe from "../../components/Blog_videos/Videosiframe"

const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: "100%",
  },
  grid: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  imgResponsive: {
    display: "block",
    maxWidth: "80%",
    height: "auto",
  },
  root: {
    flexGrow: 1,
  },

  image: {
    width: "100%",
    height: "250px",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
}));
const Blogs = (props) => {
  const {
    isLoading,
    blogData,
    hasError,
    fetchAllBlogData,
    resetBlogDetailAction,
    match: { params },
    totalResults,
    searchResults,
    currentPage,
    limit,
  } = props;

  const classes = useStyles();
  var data = "";
  if (searchResults != undefined) {
    var data = searchResults;
  } else {
    var data = blogData && blogData.blogsData;
  }
  //const data = blogData && blogData.blogsData;
  // const history = useHistory();
  // console.log(data);
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      resetBlogDetailAction();
    };
  }, [resetBlogDetailAction]);

  useEffect(() => {
    const {
      isLoading,
      blogData,
      hasError,
      fetchAllBlogData,
      resetBlogDetailAction,
      match: { params },
      totalResults,
      searchResults,
      currentPage,
      limit,
    } = props;
    // console.log(blogData);

    if (isLoading && !hasError) {
      fetchAllBlogData(params.slug);
    }
  }, [isLoading, hasError, params.slug, fetchAllBlogData]);
  const handleChangePageNumber = (offset, pageNumber) => {
    props.changePageNumberAction(pageNumber, limit);
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs | Best Car Buying and Selling Tips | Chat2Cars</title>
        <meta
          name="title"
          content="Blogs | Best Car Buying and Selling Tips | Chat2Cars"
        />
        <meta
          name="description"
          content="Learn and get tips about car and vehicles - Here you can find the best car buying and selling
        tips, car modification tips, car decoration ideas, and much more!"
        />

        <meta
          property="og:title"
          content="Blogs | Best Car Buying and Selling Tips | Chat2Cars"
        />
        <meta property="og:image" content="https://chat2cars.co.za/logo.png" />
      </Helmet>
      {isLoading ? (
        <Container
          className="my-4 d-flex justify-content-center align-items-center"
          component="main"
          style={{ minHeight: "calc(100vh -  608px)" }}
          maxWidth="sm"
        >
          <CircularProgress />
        </Container>
      ) : (
        <Container maxWidth="lg">
          <div className="d-flex align-items-center justify-content-between flex-wrap pagination_cls_up">
            <MuPagination
              className="m-2"
              limit={limit}
              offset={(currentPage - 1) * limit}
              total={totalResults}
              onClickPageNo={(e, offset, pageNumber) =>
                handleChangePageNumber(offset, pageNumber)
              }
            />
          </div>
          <Grid
            container
            // className="mt-10"

            justify="center"
            spacing={6}
            className={classes.grid}
          >
            {data &&
              data.map((blog) => {
                return (
                  <Grid
                    key={blog._id}
                    xs={12}
                    sm={6}
                    md={4}
                    item
                    className="blog-item-style"
                  >
                    <Paper elevation={10} className={classes.paper}>
                      <SingleBlogListing
                        key={blog._id}
                        title={
                          blog.title.length > 25
                            ? blog.title.substring(0, 25 - 3) + "..."
                            : blog.title
                        }
                        image={blog.image}
                        slug={blog.slug}
                        timeAgo={blog.time_ago}
                        updatedAt={blog.updated_at}
                      />
                    </Paper>
                  </Grid>
                );
              })}
          </Grid>
          <div className="d-flex align-items-center justify-content-between flex-wrap pagination_cls">
            <MuPagination
              className="m-2"
              limit={limit}
              offset={(currentPage - 1) * limit}
              total={totalResults}
              onClickPageNo={(e, offset, pageNumber) =>
                handleChangePageNumber(offset, pageNumber)
              }
            />
          </div>
        </Container>
      )}
      <Videoiframe/>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.BlogDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
