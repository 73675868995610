import {
  GET_CAR_SEARCH_RESULT_DATA,
  GET_BRANDS,
  GET_CARTYPES,
  GET_PROVINANCE,
  // GET_BRAND_LIST_FOR_RESULTS,
  REMOVE_BRAND_FROM_SELECTED_LIST,
  UPDATE_BRANDS_FIELD_FILTER,
  UPDATE_MODELS_FIELD_FILTER,
  UPDATE_FUEL_TYPE_FIELD_FILTER,
  UPDATE_TRANSMISSION_FIELD_FILTER,
  UPDATE_PRICE_FIELD_FILTER,
  UPDATE_KMS_DRIVEN_FIELD_FILTER,
  UPDATE_YEARS_FIELD_FILTER,
  UPDATE_PAGE_NUMBER_FILTER,
  UPDATE_SORT_BY_FILTER,
  UPDATE_PROVINCE_FILTER,
  UPDATE_CITY_FILTER,
  UPDATE_BODY_TYPE_FIELD_FILTER,
  RESET_ALL_FITLERS,
  CLEAR_FILTER_AND_DATA,
  LIKE_UNLIKE_CAR,
  LOAD_CITIES,
} from './Constants';

// const currentYear = (new Date()).getFullYear();

const initialState = {
  searchResults: [],
  brandsList: [],
  modelList: [],
  selectedBrands: [],
  selectedModels: [],
  selectedYears: [],
  fuelType: [],
  transmission: [],
  body: [],
  carTypes: [],
  province: null,
  city: null,
  provinces: [],
  cities: [],
  price: [0, 5000000],
  kmsDriven: [0, 200000],
  loading: true,
  isSidebarLoading: true,
  totalResults: 0,
  filterParams: {
    sortBy: "most_recent",
    currentPage: 1,
    limit: 15
  }
}

export default (state = initialState, action) => {
  let { modelList, selectedModels } = state;

  switch (action.type) {
    case GET_CAR_SEARCH_RESULT_DATA:

      const newFilterFromHome = action.data.newFilters ? action.data.newFilters : {};
      return {
        ...state,
        searchResults: action.data.searchResults,
        totalResults: action.data.totalResults,
        ...newFilterFromHome,
        loading: false,
        isSidebarLoading: false,
      }

    case GET_BRANDS :
      return {
        ...state,
        brandsList: state.brandsList.length > 0 ? state.brandsList : action.data.brandsList,
      }

    case GET_PROVINANCE:
      return {
        ...state,
        provinces: state.provinces.length > 0 ? state.provinces : action.data.provinces,
      }

    case GET_CARTYPES:
      return{
        ...state,
        carTypes: state.carTypes.length > 0 ? state.carTypes : action.data.carTypes,
      }
    // case GET_BRAND_LIST_FOR_RESULTS:
    //   return {
    //     ...state,
    //     brandsList: action.data
    //   }
    case UPDATE_BRANDS_FIELD_FILTER:
      const fetchedModels = action.data.models.map(x => ({ ...x, brand: action.data.newSelectedBrand }));
      return {
        ...state,
        selectedBrands: action.data.brands,
        modelList: [...state.modelList, ...fetchedModels],
        filterParams: {
          ...state.filterParams,
          currentPage: 1,
        },
        loading: true
      }
    case UPDATE_MODELS_FIELD_FILTER:
      return {
        ...state,
        selectedModels: action.data,
        filterParams: {
          ...state.filterParams,
          currentPage: 1,
        },
        loading: true
      }
    case REMOVE_BRAND_FROM_SELECTED_LIST:
      //   console.log("modellist",modelList);
      //   console.log("selectedmodel",selectedModels);
      //   console.log(action.data)
      const newModelList = modelList.filter(x => x.brand._id !== action.data.removedBrand._id);
      // const newSelectedModel = selectedModels.filter(x => x.brand._id !== action.data.removedBrand._id);
      return {
        ...state,
        modelList: newModelList,
        // selectedModels: newSelectedModel,
        selectedBrands: action.data.selectedBrands,
        
        filterParams: {
          ...state.filterParams,
          currentPage: 1,
        },
        loading: true
      }
    case UPDATE_FUEL_TYPE_FIELD_FILTER:
      return {
        ...state,
        fuelType: action.data,
        filterParams: {
          ...state.filterParams,
          currentPage: 1,
        },
        loading: true
      }
    case UPDATE_TRANSMISSION_FIELD_FILTER:
      return {
        ...state,
        transmission: action.data,
        filterParams: {
          ...state.filterParams,
          currentPage: 1,
        },
        loading: true
      }
    case UPDATE_PRICE_FIELD_FILTER:
      return {
        ...state,
        price: action.data,
        filterParams: {
          ...state.filterParams,
          currentPage: 1,
        },
        loading: true
      }
    case UPDATE_KMS_DRIVEN_FIELD_FILTER:
      return {
        ...state,
        kmsDriven: action.data,
        filterParams: {
          ...state.filterParams,
          currentPage: 1,
        },
        loading: true
      }
    case UPDATE_YEARS_FIELD_FILTER:
      return {
        ...state,
        selectedYears: action.data,
        filterParams: {
          ...state.filterParams,
          currentPage: 1,
        },
        loading: true
      }
    case UPDATE_PAGE_NUMBER_FILTER:
    
      return {
        ...state,
        filterParams: {
          ...state.filterParams,
          currentPage: action.data
        },
        loading: true
      }
    case UPDATE_SORT_BY_FILTER:
      return {
        ...state,
        filterParams: {
          ...state.filterParams,
          sortBy: action.data,
          currentPage: 1,
        },
        loading: true
      }
    case UPDATE_PROVINCE_FILTER:
      return {
        ...state,
        province: action.data,
        filterParams: {
          ...state.filterParams,
          currentPage: 1,
        },
        loading: true
      }
    case UPDATE_CITY_FILTER:
      return {
        ...state,
        city: action.data,
        filterParams: {
          ...state.filterParams,
          currentPage: 1,
        },
        loading: true
      }
    case UPDATE_BODY_TYPE_FIELD_FILTER:
      return {
        ...state,
        body: action.data,
        filterParams: {
          ...state.filterParams,
          currentPage: 1,
        },
        loading: true
      }
    case LIKE_UNLIKE_CAR:
      return {
        ...state,
        searchResults: state.searchResults.map(x => {
          if(x._id === action.data._id) {
            return action.data
          }

          return x;
        })
      }
    case LOAD_CITIES:
      return {
        ...state,
        cities: action.data
      }
    case RESET_ALL_FITLERS:
      return {
        ...state,
        selectedBrands: [],
        selectedModels: [],
        modelList: [],
        selectedYears: [],
        fuelType: [],
        body: [],
        carTypes: [],
        transmission: [],
        price: [0, 5000000],
        kmsDriven: [0, 200000],
        province: null,
        city: null,
        cities: [],
        loading: true,
        filterParams: {
          sortBy: "most_recent",
          currentPage: 1,
          limit: 15
        }
      }
    case CLEAR_FILTER_AND_DATA:
      return {
        searchResults: [],
        brandsList: [],
        modelList: [],
        selectedBrands: [],
        selectedModels: [],
        province: null,
        city: null,
        provinces: [],
        cities: [],
        transmission: [],
        selectedYears: [],
        fuelType: [],
        body: [],
        carTypes: [],
        price: [0, 5000000],
        kmsDriven: [0, 200000],
        loading: true,
        totalResults: 0,
        isSidebarLoading: true,
        filterParams: {
          sortBy: "most_recent",
          currentPage: 1,
          limit: 15
        }
      }
    default:
      return state
  }
}
