import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FavoriteIcon from "@material-ui/icons/Favorite";
// import ShareIcon from '@material-ui/icons/Share';
import { Link } from "react-router-dom";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import SpeedIcon from "@material-ui/icons/Speed";
import BuildIcon from "@material-ui/icons/Build";
import formatNumber from "../../helpers/formatNumber";

const useStyles = makeStyles((theme) => ({
  card: {
    // maxWidth: 345,
    background: "#fff none repeat scroll 0 0",
    boxShadow: "0 2px 5px -1px rgba(0, 0, 0, 0.16)",
    transition: "unset",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "&:hover": {
      boxShadow: "0 2px 5px -1px rgba(0, 0, 0, 0.4)",
    },
  },
  cardContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  media: {
    height: 270,
    position: "relative",
    width: "100%",
    // paddingTop: '56.25%', // 16:9
  },
}));

const CarCard = (props) => {
  const { featured, carData, handleLikeUnLikeCar, favouriteList, isAuthenticated } = props;
  const classes = useStyles();
  const [favoriteData, setFavoriteData] = useState([]);
  const clickHandler = (carData) => {
    if(isAuthenticated){
      let favorites = localStorage.getItem("favorite");
    favorites = favorites ? JSON.parse(favorites) : [];

    const carIndex = favorites.indexOf(carData._id);
    if (carIndex !== -1) {
      favorites.splice(carIndex, 1);
    } else {
      favorites.push(carData._id);
    }

    localStorage.setItem("favorite", JSON.stringify(favorites));
    setFavoriteData(favorites);
    }
  };

  useEffect(() => {
    const response = localStorage.getItem("favorite");
    setFavoriteData(response ? JSON.parse(response) : []);
  }, [setFavoriteData]);

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className={`${classes.card} mx-auto`}>
        <Link to={`/second-hand-cars/${carData.slug}`}>
          <CardMedia
            className={classes.media}
            image={carData.featured_image}
          >
            {/* {featured && (
              <div className="featured-ribbon">
                <span className="bg-primary">Featured</span>
              </div>
            )} */}
            <div className="bg-primary price-tag">
              {formatNumber(carData.price, 2)}
            </div>
          </CardMedia>
        </Link>
        <CardContent className={`pb-0 ${classes.cardContent}`}>
          <Typography>
            <Typography variant="body2" color="textSecondary" component="div">
              <h3 className="car-title mt-0">
                <Link to={`/second-hand-cars/${carData.slug}`} title={carData.title}>
                  {carData.title}
                </Link>
              </h3>
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              className="car-location d-flex"
            >
              <LocationOnIcon fontSize="small" />{" "}
              <span className="pl-2">{carData.address}</span>
            </Typography>
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            component="div"
            className="car-location car-features"
          >
            <div className="car-location car-feature">
              <LocalGasStationIcon
                fontSize="small"
                className="car-feature-icon"
              />{" "}
              <span className="pl-1">{carData.fueltype}</span>
            </div>
            <div className="car-location car-feature">
              <SpeedIcon fontSize="small" className="car-feature-icon" />{" "}
              <span className="pl-1">
                {carData.mileage.includes("Km")
                  ? carData.mileage
                  : `${carData.mileage} Km`}
              </span>
            </div>
            <div className="car-location car-feature">
              <BuildIcon fontSize="small" className="car-feature-icon" />{" "}
              <span className="pl-1">{carData.transmission}</span>
            </div>
            <CardActions disableSpacing style={{padding:"0px"}}>
              <IconButton
                aria-label="add to favorites"
                style={{
                  padding: "0px",
                  color: favoriteData.includes(carData._id)
                    ? "#f5c82a"
                    : carData.is_favorite === "yes"
                    ? "#f5c82a"
                    : "rgba(0, 0, 0, 0.54)",
                }}
                onClick={() => {
                  clickHandler(carData);
                  handleLikeUnLikeCar(carData._id);
                }}
              >
                <FavoriteIcon />
              </IconButton>
            </CardActions>
          </Typography>
        </CardContent>
        {/* <CardActions disableSpacing>
          <IconButton
            className="p-2"
            aria-label="add to favorites"
            style={{
              color:
                favorites.some((item) => item._id === carData._id) ||
                carData.is_favorite === "yes"
                  ? "#f5c82a"
                  : "rgba(0, 0, 0, 0.54)",
            }}
            onClick={() => {
              handleLikeUnLikeCarFunc(carData._id);
            }}
          >
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="share">
            <ShareIcon />
          </IconButton>
        </CardActions> */}
      </Card>
    </Grid>
  );
};

export default CarCard;
