import React from 'react';

import { Link } from 'react-router-dom';
import formatNumber from './../../helpers/formatNumber';
import "./../LatestOffers/latest-offers.css";

const SingleCar = (props) => {
  const { image, make, model, price, year, link, kmsDriven } = props;
  return (
    <article className="review-main loop-short title-color-hover-design mb-3">
      <Link to={link}>
        <div className="offer-slider__img" style={{ padding: '0 10px' }}>
          <img className="img-scale" src={image} alt="foto" />
          <div className="offer-slider__content-inside py-2 px-3 d-flex justify-content-between">
            <div>
              <div className="text-primary font-weight-bold font-size-20 m-0" style={{ display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>{make} {model}</div>
              <div className="m-0 text-white font-weight-bold font-size-14">{year} &middot; {kmsDriven} kms</div>
            </div>
            <div className="text-primary font-weight-bold font-size-20 m-0" style={{ alignSelf: 'center' }}>{formatNumber(price)}</div>
          </div>
        </div>
      </Link>
      {/* <div className="text-center container-text-loop">
        <div className="content-txt-loop">
          <h4 className="title-article mt-2">
            <Link to={link}>{title}</Link>
          </h4>
          <div className="offer-slider__price">Price:<strong> {formatNumber(price)}</strong></div>
          <ul className="offer-slider__list list-unstyled">
            <li className="offer-slider__desrip">{condition}</li>
            <li className="offer-slider__desrip">{year}</li>
            <li className="offer-slider__desrip">{transmission}</li>
            <li className="offer-slider__desrip">{fuel}</li>
            <li className="offer-slider__desrip">{kmsDriven} kms</li>
          </ul>
        </div>
      </div> */}
    </article>
  )
}

export default SingleCar
