import {
  FETCH_CAR_DATA,
  FETCH_CAR_DATA_FAILURE,
  RESET_ALL_CAR_DETAIL_OPTION,
  SEND_MESSAGE_REQUEST,
} from './Constants';

const initialState = {
  isLoading: true,
  hasError: false,
  message: '',
  carData: undefined,
  recentList: [],
  isMessageRequestSent: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CAR_DATA:
      return {
        ...state,
        ...action.data,
        isLoading: false,
      };
    case FETCH_CAR_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        message: action.data
      }
    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        isMessageRequestSent: true,
      }
    case RESET_ALL_CAR_DETAIL_OPTION:
      return {
        isLoading: true,
        hasError: false,
        message: '',
        carData: undefined,
        recentList: [],
        isMessageRequestSent: false,
      }
    default:
      return state;
  }
}