import React, { useEffect } from 'react'

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(5),
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}));

const TermsConditions = (props) => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <main className="mx-1200 my-4 mx-auto" style={{ minHeight: 'calc(100vh -  608px)', paddingLeft: 20, paddingRight: 20 }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms & Conditions | {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <Breadcrumbs className="mt-4" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
          Home
        </Link>
        <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">Terms & Conditions</Typography>
      </Breadcrumbs>
      <Paper elevation={3} className={`${classes.paper} shadow-lg`}>
        <Typography className="text-dark font-weight-bold pb-3" component="h2" variant="h5">
          Terms & Conditions
        </Typography>
        <div className="privacy-policy__content">
          <p>1. These terms and conditions are binding and enforceable against all website users, including private sellers and dealers, who access this website or any part thereof in order to place an advertisement (including any information and/or photographs therein) on the website.</p>
          <p>2. The content of this website is the copyright of Chat2cars and all trade mark, domain names, design rights, database rights and other intellectual property rights therein shall remain vested in Chat2cars (or the applicable third party licensors) and is protected from infringement by local and international legislation and treaties.</p>
          <p>3. The website user undertakes not to use the website for any unlawful purposes, including the sending of viruses or any other files that may disrupt or corrupt the use of the website. Chat2cars may in its sole discretion (i) suspend or remove any website user’s access where such website user has utilised the website unlawfully and (ii) remove any advertisement (including any information and/or photographs therein) from the website which is unlawful.</p>
          <p>4. The private seller must ensure that any advertisement (including any information and/or photographs therein) placed on the website is honest and legal, and does not breach any third party intellectual property rights. The private seller is responsible for any complaints or legal actions in respect of an advertisement (including any the information and/or photographs therein) and indemnifies and holds Chat2cars harmless from any and all costs, damages and other losses, arising from, or in connection with, the publication of any advertisement (including any the information and/or photographs therein) on the website.</p>
          <p>5. Chat2cars accepts no liability and responsibility for any inaccuracies, errors, omissions and/or misinterpretations occurring in any advertisement (including any information and photographs therein) on the website, and the website user absolves Chat2cars for any responsibility therein.</p>
          <p>6. All advertisements (including any information and/or photographs therein) provided on this website is provided “as is” and without any representation, warranty or guarantee whatsoever, whether express or implied, including with regard to any implied warranties of accuracy, completeness, quality, functionality, currency, copyright compliance, legality, fitness for a particular purpose or non-infringement with respect to the advertisement (including any the information and/or photographs therein) of the website.</p>
          <p>7. Chat2cars has made every effort to ensure information loaded is accurate and up to date on our webiste. Because of the unique nature of each stock item the seller is responsible for the information supplied and will need to confirm availability when contacted.</p>
          <p>8. Chat2cars absolves itself from any and all costs, damages and other losses caused to any website user, as a consequence of any failure on behalf of Chat2cars to fulfil any of its obligations in terms hereof or as a consequence of any errors or omissions in any advertisement and further, Chat2cars is hereby indemnified against any such loss, damage and or inconvenience occasioned to the website user in this regard.</p>
          <p>9. The use of this website is entirely at the website user’s own risk and the website user assumes full responsibility for any risk or loss resulting from the use of this website. Chat2cars accepts no liability or responsibility whatsoever for any loss or damage, whether direct, indirect or consequential in nature, suffered by any website user arising out of or in connection with the website user’s access to, or use of the Website.</p>
          <p>10. Chat2cars does not warrant that the website shall operate uninterrupted, timely, secure, or error-free or that the server is free from viruses or other harmful components.</p>
          <p>11. This website may contain images or links to third party websites, with information or material produced by other parties. Chat2cars does not control these linked sites and is not responsible for the content, any updates, links or changes to these linked sites.</p>
          <p>12. If any third party wishes to establish a link to this website, by way of hyperlink, frame, or by similar reference, either electronically or otherwise, such third party must obtain the prior written consent of Chat2cars. Based on its sole discretion, Chat2cars may withhold or grant such consent subject to such conditions as may be specified by Chat2cars.</p>
          <p>13. Chat2cars will use reasonable efforts to maintain the confidentiality of any personal information which may be shared by the website user with Chat2cars via the website, and undertakes not to share such information with any third party except to the extent necessary to respond to any request for information from the user or where involvement of third parties is required to address such enquiry or request or under circumstances where Chat2cars is legally compelled to do so or upon the website user’s written consent. However, Chat2cars does not warrant or guarantee that any information provided via email and/or this website shall be secure.</p>
          <p>14. Chat2cars may amend these terms and conditions from time to time without notification to the website user. By accessing the website, the website user will be bound by the terms of the latest version of these terms and conditions, as published on this website.</p>
          <p>15. Should any term of these terms and conditions be held to be invalid, unlawful or unenforceable, such terms and conditions shall be severable from the remaining terms and conditions which shall continue to be valid and enforceable.</p>
          <p>16. These terms and conditions shall be governed and interpreted in accordance with the law of South Africa and any breach of these terms and conditions entitles Chat2cars to take appropriate legal action against the website user.</p>
        </div>
      </Paper>
    </main>
  )
}

export default TermsConditions
