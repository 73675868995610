import {
  LOAD_PROVINCES_VEHICLE_BRAND_POSTAD,
  FETCH_VEHICLE_MODEL_BOOK_TEST_DRIVE,
  SUBMIT_BOOK_TEST_DRIVE_DATA,
  SUBMIT_BOOK_TEST_DRIVE_DATA_FAILURE,
  RESET_BOOK_TEST_DRIVE_OPTION,
  REMOVE_MODEL_OPTIONS,
  SET_LOADING,
  LOAD_CITIES,
} from './Constants';

const initialState = {
  isLoading: false,
  hasError: false,
  hasSuccess: false,
  message: '',
  provinces: [],
  cities: [],
  vehicleBrandsList: [],
  vehicleModelsList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case SUBMIT_BOOK_TEST_DRIVE_DATA:
      return {
        ...state,
        message: action.data,
        isLoading: false,
        hasError: false,
        hasSuccess: true,
      };
    case SUBMIT_BOOK_TEST_DRIVE_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        hasSuccess: false,
        message: action.data
      }
    case LOAD_PROVINCES_VEHICLE_BRAND_POSTAD:
      return {
        ...state,
        provinces: action.data.provinces || [],
        vehicleBrandsList: action.data.vehicleBrandsList,
      }
    case FETCH_VEHICLE_MODEL_BOOK_TEST_DRIVE:
      return {
        ...state,
        vehicleModelsList: action.data,
      }
    case LOAD_CITIES:
      return {
        ...state,
        cities: action.data
      }
    case REMOVE_MODEL_OPTIONS:
      return {
        ...state,
        vehicleModelsList: []
      }
    case RESET_BOOK_TEST_DRIVE_OPTION:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        hasSuccess: false,
        message: '',
        provinces: [],
        cities: [],
        vehicleBrandsList: [],
        vehicleModelsList: [],
      }
    default:
      return state;
  }
}