import React from 'react';

import ContactSeller from './ContactSeller';
import UserInfo from './UserInfo';
import RecentListings from './RecentListings';
import SafetyTips from './SafetyTips';

import './SingleCarSidebar.css';

const SingleCarSidebar = ({ carData, recentList, isAuthenticated, handleSubmitData, handleContinueToChat }) => {
  return (
    <React.Fragment>
      <ContactSeller handleSubmitData={handleSubmitData} handleContinueToChat={handleContinueToChat} isAuthenticated={isAuthenticated} carData={carData} />
      <UserInfo carData={carData} />
      <RecentListings recentList={recentList} />
      <SafetyTips />
    </React.Fragment>
  )
}

export default SingleCarSidebar;
