import React, { useState, useEffect } from 'react'

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';

import { Helmet } from "react-helmet";
// import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { a11yProps, TabPanel } from '../Profile';

import './Services.css';
import { UIDecorLine } from '../../components/Footer/Footer.style';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(5),
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  indicator: {
    backgroundColor: '#F5C82A',
  },
  servicesTitle: {
    fontWeight: 'bold',
  },
  servicesDescription: {
    paddingTop: theme.spacing(1),
    color: 'black',
  },
  serviceAvatar: {
    alignSelf: 'flex-start',
    paddingTop: theme.spacing(1),
    '& .MuiAvatar-colorDefault': {
      color: '#000',
      backgroundColor: '#fff',
      border: '3px solid #F5C82A !important',
    }
  }
}));

const carTrackingServices = [
  {
    id: 1,
    title: 'Complete Car Control',
    description: 'Switch on or off a car through a mobile phone: In the case of hijacking, a user can switch on/off their using the app by cutting off the oil supply. This aids the process of recovering the stolen car.',
  },
  {
    id: 2,
    title: 'Live tracking/ monitoring',
    description: 'of a car through a mobile phone.',
  },
  {
    id: 3,
    title: 'Voice Monitoring',
    description: 'a user can dial the tracking device. The tracking device picks up the call therefore enabling the user to hear sounds in and around the car .',
  },
  {
    id: 4,
    title: 'Record History',
    description: 'This function saves a car’s movements and speedometer on the server such that if need be, a user can play back previous movements through the chat2cars app.',
  },
  {
    id: 5,
    title: 'Geofencing',
    description: 'This feature has made tracking easy via the chat2cars app. A user can put a speed limit on their car through their mobile phone. A user can also prevent their car from being driven to certain areas, even if it is driven by someone else.',
  },
  {
    id: 6,
    title: 'Panic Button',
    description: 'the tracking service also comes with a panic button. In cases of emergency, once pressed its signals the emergency team or whoever is added on users SOS call, the call is then immediately placed and the helpline is immediately notified.',
  },
]

const Services = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <main className="mx-1200 my-4 mx-auto" style={{ minHeight: 'calc(100vh -  608px)', paddingLeft: 20, paddingRight: 20 }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Services | Roadside Assistance | Vehicle Tracking Device | Chat2Cars</title>
        <meta name="title" content="Services | Roadside Assistance | Vehicle Tracking Device | Chat2Cars" />

        {/* <title>Roadside Assistance, Car Tracking & Car Towing Services in South Africa - {process.env.REACT_APP_NAME}</title> */}
        <meta property="og:title" content="Services | Roadside Assistance | Vehicle Tracking Device | Chat2Cars" />
        <meta name="description" content="Track your car through a mobile application by installing our vehicle tracking device. We also provide second-hand car listing and roadside assistance services." />
        <meta property="og:image" content="https://chat2cars.co.za/logo.png" />
      </Helmet>
      <Breadcrumbs className="mt-4" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
          Home
        </Link>
        <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">Services</Typography>
      </Breadcrumbs>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="black"
          variant="scrollable"
          scrollButtons="auto"
          classes={{ indicator: classes.indicator }}
          className="shadow bg-white"
        >
          <Tab icon={<SettingsIcon />} className="px-5 py-3" label="Services" {...a11yProps(0)} />
          <Tab icon={<LocationOnIcon />} className="px-5 py-3" label="Car Tracking" {...a11yProps(1)} />
        </Tabs>
        <TabPanel className="mt-4 profile-tab" value={value} index={0}>
          <div className="services__main">
            <div className="p-4 bg-white shadow services__main-content">
              <Typography className="text-center font-weight-bold services__main-content-title" component="h1" variant="h4">
                Roadside Assistance
              </Typography>
              <UIDecorLine className="mb-2" />
              <Typography className="pt-4 text-center services__main-content-content" component="p">
                The tech/roadside assist function of
                the app provides fast road side assist
                to clients that have car troubles by
                sending them a mechanic , car
                electrician or tow van pending on
                requests.
              </Typography>
            </div>
            <div className="shadow services__main-image">
              <img alt="" src={`${process.env.REACT_APP_BASE_PATH}/media/services/roadside-assitance.png`} className="img-responsive" />
            </div>
          </div>
          <div className="services__main services__main-alter">
            <div className="shadow services__main-image">
              <img alt="" src={`${process.env.REACT_APP_BASE_PATH}/media/services/car-listing.png`} className="img-responsive" />
            </div>
            <div className="p-4 bg-white shadow services__main-content">
              <Typography className="text-center font-weight-bold services__main-content-title" component="h1" variant="h4">
                Car Listing
              </Typography>
              <UIDecorLine className="mb-2" />
              <Typography className="pt-4 text-center services__main-content-content" component="p">
                We also provide a platform through
                our website chat2cars.co.za where
                car buyers find car sellers at a
                competitive market price.
              </Typography>
            </div>
          </div>
          <div className="services__main">
            <div className="p-4 bg-white shadow services__main-content">
              <Typography className="font-weight-bold text-center services__main-content-title" component="h1" variant="h4">
                Car Tracking
              </Typography>
              <UIDecorLine className="mb-2" />
              <Typography className="pt-4 text-center services__main-content-content" component="p">
                A tracking device/chip is installed to a
                car/truck/buses etc. The device enables a car owner
                to operate their car from their cell phone.
              </Typography>
            </div>
            <div className="shadow services__main-image">
              <img alt="" src={`${process.env.REACT_APP_BASE_PATH}/media/services/track-your-car.png`} className="img-responsive" />
            </div>
          </div>
        </TabPanel>
        <TabPanel className="mt-4 profile-tab" value={value} index={1}>
          <div className="p-4 bg-white shadow">
            <Typography className="font-weight-bold text-center" component="h1" variant="h4">
              Prices
            </Typography>
            <div className="w-100 text-center">
              <UIDecorLine className="mb-2" />
            </div>
            <Typography className="pt-4" component="p">
              Tracking device: R4,800 per car for
              a fleet of 5 cars including installation
              and tracking maintenance for 2 years.
            </Typography>
            <Typography className="pt-3" component="p">
              Using this service, you can carry out the following:
            </Typography>
            <List className="pt-3">
              {carTrackingServices.map(service => (
                <ListItem key={`carTrackingServices-${service.id}`} className="px-0">
                  <ListItemAvatar className={classes.serviceAvatar}>
                    <Avatar>{service.id}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className="mx-0"
                    primary={service.title}
                    secondary={service.description}
                    primaryTypographyProps={{
                      className: classes.servicesTitle
                    }}
                    secondaryTypographyProps={{
                      className: classes.servicesDescription
                    }}
                  />
                </ListItem>
              ))}
            </List>
            <Typography className="pt-4 text-center" component="p" style={{ wordBreak: 'break-word' }}>
              Information regarding the car listing subscription cost can be found on
              our website given the link below: <Link to="/subscriptions">https://chat2cars.co.za/subscriptions</Link>
            </Typography>
            <div className="d-flex align-items-center car-tracking-buttons mt-4 justify-content-center">
              <div className="car-tracking__main">
                <div className="car-tracking__title">Once Off payment</div>
                <div className="car-tracking__price">R5000</div>
              </div>
              <div className="car-tracking__or">
                OR
              </div>
              <div className="d-flex car-tracking__main_initial align-items-center">
                <div className="position-relative">
                  <div className="car-tracking__title">Initial Payment</div>
                  <div className="car-tracking__price car-tracking__price_initial">R2500</div>
                  <AddIcon className="car-tracking__add_icon" />
                </div>
                <div className="car-tracking__extra_price">
                  R100 <br/>
                  <span className="car-tracking__extra_price_info">(debit for 3 years)</span>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </Paper>
    </main>
  )
}

export default Services
