import {
  FETCH_CAR_DATA,
  FETCH_CAR_DATA_FAILURE,
  SEND_MESSAGE_REQUEST,
  RESET_ALL_CAR_DETAIL_OPTION,
} from './Constants';
import { ADDED_NOTIFICATION } from "./../Notification/Constants";
import Api from './../../helpers/ApiHandler';

const api = new Api();

export const fetchCarDataAction = (slug) => dispatch => {
  api.get(`cars/get-single-car/${slug}`, { skipAuth: true })
    .then((result) => {
      if(result.success) {
        
        dispatch({
          type: FETCH_CAR_DATA,
          data: {
            carData: result.data,
            recentList: result.recent_list
          }
        });
      } else {
        dispatch({
          type: FETCH_CAR_DATA_FAILURE,
          data: result.msg
        })
      }
    })
    .catch((err) => {
      console.log('err', err);
    });
}

export const sendMessageToSellerAction = (payload) => dispatch => {
  // console.log(payload);
  api.post(`users/send/car-enquiry-mail`, { data: payload, skipAuth: true })
    .then((result) => {
      if(result.success) {
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'success',
            message: result.msg
          }
        });
      } else { 
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'error',
            message: result.msg
          }
        });
      }
    })
    .catch((err) => {
      // console.log('err', err);
    });
}

export const sendMessageRequest = (payload) => dispatch => {

  api.post(`chat/save-chat-info`, { data: payload })
    .then((result) => {
      if(result.success) {
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'success',
            message: 'Message request sent.'
          }
        });
        dispatch({
          type: SEND_MESSAGE_REQUEST
        });
      } else {
        dispatch({
          type: ADDED_NOTIFICATION,
          data: {
            type: 'error',
            message: result.msg
          }
        });
      }
    })
    .catch((err) => {
      // console.log('err', err);
    });
}

export const resetAllCarDetailOptionAction = () => dispatch => {
  dispatch({
    type: RESET_ALL_CAR_DETAIL_OPTION
  })
}

export const sendNotificationAction = (type, message) => dispatch => {
  dispatch({
    type: ADDED_NOTIFICATION,
    data: {
      type,
      message,
    },
  })
}