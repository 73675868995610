import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Confetti from "react-confetti";
import zIndex from "@material-ui/core/styles/zIndex";

function getModalStyle() {
  const top = 50;
  const left = 50;


  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    maxWidth: 400,
    width:"90%",
    margin:"0px auto",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4),
    
  },
}));
const Popup = ({ open, setOpen, statusMsg }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <p id="simple-modal-description" style={{fontFamily:"sans-serif", fontSize:"17px"}}>{statusMsg}</p>
    </div>
  );

  const { width, height } = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  return (
    <div>
      <Confetti
        recycle={false}
        width={width}
        height={height}
        numberOfPieces={1000}
        style={{zIndex:"99999999", position:"absolute", top:"0"}}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default Popup;
