export const GET_CURRENT_USER_PROFILE = 'Profile/GET_CURRENT_USER_PROFILE';
export const GET_CURRENT_USER_PROFILE_FAILURE = 'Profile/GET_CURRENT_USER_PROFILE_FAILURE';
export const UPDATE_CURRENT_USER_PROFILE = 'Profile/UPDATE_CURRENT_USER_PROFILE';
export const UPDATE_CURRENT_USER_PROFILE_FAILURE = 'Profile/UPDATE_CURRENT_USER_PROFILE_FAILURE';
export const CAR_LIST_UPDATED = 'Profile/CAR_LIST_UPDATED';
export const FAVOURITE_LIST_UPDATED = 'Profile/FAVOURITE_LIST_UPDATED';
export const RESET_LOADING_FLAG = 'Profile/RESET_LOADING_FLAG';
export const UPDATE_CAR_FAV_UNFAV_STATUS = 'Profile/UPDATE_CAR_FAV_UNFAV_STATUS';
export const DELETE_MY_CAR = 'Profile/DELETE_MY_CAR';
export const CHANGE_CAR_STATUS = 'Profile/CHANGE_CAR_STATUS';
export const LOAD_PROVINCES = 'Profile/LOAD_PROVINCES';
export const LOAD_CITIES = 'Profile/export cosnt LOAD_CITIES';
export const INQUIRY_LIST_UPDATED = 'Profile/INQUIRY_LIST_UPDATED';