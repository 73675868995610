import {
  GET_ALL_DEALERS,
  UPDATE_PAGE_NUMBER,
  RESET_LOADING_FLAG,
  SEARCH_TERM_ADDED,
} from './Constants';
// import { ADDED_NOTIFICATION } from "./../Notification/Constants";
import Api from './../../helpers/ApiHandler';

const api = new Api();

export const getAllDealersDataAction = (pageNumber, limit, search) => dispatch => {
  // console.log(pageNumber);
  api.post(`users/get-dealer-list`, { data: { page: pageNumber, limit, search } })
    .then(result => {
      if (result.success) {
        // console.log(result)
        dispatch({
          type: GET_ALL_DEALERS,
          data: {
            dealersData: result.data,
            totalResults: result.totalCount,
          }
        })
      }
    })
    .catch(err => {
      // console.log('err', err)
      // dispatch({
      //   type: GET_CAR_SEARCH_RESULT_DATA,
      //   data: [],
      // })
    });
}

export const changePageNumberAction = (pageNumber) => dispatch => {
  dispatch({
    type: UPDATE_PAGE_NUMBER,
    data: pageNumber
  })
}

export const resetLoadingFlagAction = () => dispatch => {
  dispatch({
    type: RESET_LOADING_FLAG
  })
}

export const getSearchedDataAction = () => dispatch => {
  dispatch({
    type: SEARCH_TERM_ADDED
  })
}