import React from 'react';

import Chip from '@material-ui/core/Chip';
import formatNumber from '../../helpers/formatNumber';

import './SingleCarDescription.css';

const SingleCarDescription = ({ carData }) => {
  const features = typeof carData.carfeatures === 'object' ? carData.carfeatures : carData.carfeatures.split(',');
  return (
    <div className="content-box-grid shadow">
      <div className="singleCarDescription">
        <h3>Description</h3>
        <div className="DetailRow__description pb-3" dangerouslySetInnerHTML={{ __html: carData.description }} />
        <div className="DetailRow">
          <div className="DetailRowChild">
            <b>Condition:&nbsp;</b>
            <span>{carData.condition}</span>
          </div>
          <div className="DetailRowChild">
            <b>Model:&nbsp;</b>
            <span>{carData.model}</span>
          </div>
          <div className="DetailRowChild">
            <b>Price:&nbsp;</b>
            <span>{formatNumber(carData.price, 2)}</span>
          </div>
        </div>
      </div>
      <div className="SingleCarFeatures">
        <h3>Car Features</h3>
        <div className="CarFeatureList">{features.map((s, i) => <Chip className="m-1" key={i} label={s} />)}</div>
      </div>
    </div>
  );
}

export default SingleCarDescription;
