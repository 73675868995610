import React, { useEffect } from 'react'

// For redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './../../redux/BlogDetail/Actions';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import MUIRichTextEditor from 'mui-rte';

import moment from "moment";
import { Helmet } from "react-helmet";
import MetaTags from 'react-meta-tags';
import { Link, Redirect } from 'react-router-dom';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: -theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  imgResponsive: {
    display: 'block',
    maxWidth: '80%',
    height: 'auto',
  }
}));

const BlogDetail = (props) => {
  // console.log('props', props)
  const { isLoading, blogData, hasError, fetchBlogDetailAction, resetBlogDetailAction, match: { params } } = props;
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
      resetBlogDetailAction();
    }
  }, [resetBlogDetailAction])

  useEffect(() => {
    if (isLoading && !hasError) {
      // console.log('params.slug', params.slug)
      fetchBlogDetailAction(params.slug)
    }
  }, [isLoading, hasError, params.slug, fetchBlogDetailAction])
  return (
    hasError ?
      <Redirect to="/" />
      :
      isLoading ?
        <Container className="my-4 d-flex justify-content-center align-items-center" component="main" style={{ minHeight: 'calc(100vh -  608px)' }} maxWidth="sm">
          <CircularProgress />
        </Container>
        :
        <main className="mx-1200 my-4 mx-auto" style={{ minHeight: 'calc(100vh -  608px)', paddingLeft: 20, paddingRight: 20 }}>
          <MetaTags>
            <meta charSet="utf-8" />
            <title>Blogs | Best Car Buying and Selling Tips | Chat2Cars</title>
            <meta name="title" content="Blogs | Best Car Buying and Selling Tips | Chat2Cars" />
            <meta name="description" content="Learn and get tips about car and vehicles - Here you can find the best car buying and selling
            tips, car modification tips, car decoration ideas, and much more!" />

            <meta property="og:title" content="Blogs | Best Car Buying and Selling Tips | Chat2Cars" />
            <meta property="og:image" content="https://chat2cars.co.za/logo.png" />
          </MetaTags>
          <Breadcrumbs className="mt-4" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link to="/" className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
              Home
          </Link>
            <Link to={`/blog`} className="MuiTypography-root MuiLink-root MuiTypography-colorInherit">
              Blog
          </Link>
          {/* {console.log('blogData', blogData)} */}
            <Typography className="text-dark font-weight-bold breadcrumb-active" color="textPrimary">{blogData && blogData.title}</Typography>
          </Breadcrumbs>
          <img
            className={`${classes.imgResponsive} mx-auto shadow mt-4`}
            src={blogData.image}
            alt={blogData.title}
          />
          <Paper elevation={3} className={`${classes.paper} shadow-lg`}>
            <Typography className="text-center" variant="subtitle1" color="textSecondary">
              {moment(blogData.updated_at).format('DD-MM-YYYY')}
            </Typography>
            <div className="blog__content">
              <MUIRichTextEditor value={blogData.description} readOnly={true} />
            </div>
          </Paper>
          <div className="mt-2 text-center">
            <FacebookShareButton className="mx-2" url={`${process.env.REACT_APP_PUBLIC_URL}/blog/${blogData.slug}`}>
              <FacebookIcon size={54} round />
            </FacebookShareButton>
            <LinkedinShareButton className="mx-2" url={`${process.env.REACT_APP_PUBLIC_URL}/blog/${blogData.slug}`}>
              <LinkedinIcon size={54} round />
            </LinkedinShareButton>
            <TwitterShareButton className="mx-2" url={`${process.env.REACT_APP_PUBLIC_URL}/blog/${blogData.slug}`}>
              <TwitterIcon size={54} round />
            </TwitterShareButton>
            <WhatsappShareButton className="mx-2" url={`${process.env.REACT_APP_PUBLIC_URL}/blog/${blogData.slug}`}>
              <WhatsappIcon size={54} round />
            </WhatsappShareButton>
          </div>
        </main>
  )
}

const mapStateToProps = (state) => {
  // console.log('state', state)
  return {
    ...state.BlogDetail
  };
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ ...Actions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogDetail);
