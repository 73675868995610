import {
  FETCH_VEHICLE_BRAND_HOMEPAGE,
  FETCH_VEHICLE_MODEL_HOMEPAGE,
  FETCH_HOMEPAGE_DATA,
  REMOVE_FROM_MODEL_LIST,
  RESET_HOMEPAGE,
} from './Constants';

const initialState = {
  usersData: [],
  vehicleBrandsList: [],
  vehicleModelsList: [],
  carListing: [],
  featuredDealerships: [],
  awardNomineesData: [],
  blogsData: [],
  testimonials: [],
  carTypes: [],
}

export default (state = initialState, action) => {
  // console.log(action.data);
  switch (action.type) {
    case FETCH_VEHICLE_BRAND_HOMEPAGE:
      return {
        ...state,
        vehicleBrandsList: action.data
      }
    case FETCH_VEHICLE_MODEL_HOMEPAGE:
      // console.log(action.data);
      const fetchedModels = action.data.vehicleModelsList.map(x => ({...x, brand: action.data.brand}));
      // console.log(fetchedModels);
      return {
        ...state,
        vehicleModelsList: [
          ...state.vehicleModelsList,
          ...fetchedModels
        ],
      }
    case REMOVE_FROM_MODEL_LIST:
      const newVehicleModelsList = state.vehicleModelsList.filter(x => x.brand._id !== action.data._id);
      
      return {
        ...state,
        vehicleModelsList: newVehicleModelsList
      }
    case FETCH_HOMEPAGE_DATA:
      // console.log(action.data);
      return {
        ...state,
        ...action.data
      }
    case RESET_HOMEPAGE:
      return {
        ...state,
        vehicleModelsList: []
      }
    default:
      return state
  }
}