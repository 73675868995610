import styled from "styled-components";

export const ProfileImage = styled.div`
  background: transparent ${props => props.bgimage ? `${`url(${props.bgimage})`}` : `url("${process.env.REACT_APP_BASE_PATH}/media/icons/camera.png")` } 50% no-repeat;
  background-size: ${props => props.bgimage ? 'cover' : 'auto'};
  background-color: rgba(243,246,248,.94);
  /* margin-top: ${props => !props.mainPage ? '-130px' : 'auto'}; */
  max-width: 160px;
  max-height: 160px;
  border-radius: 50%;
  width: 100%;
  height: 160px;
  position: relative;
  flex: 0 0 160px;

  &.business-profile {
    border-radius: 0% !important;
  }

  &:hover {
    background: transparent url(${process.env.REACT_APP_BASE_PATH}/media/icons/camera.png) 50% no-repeat;
    background-size: auto;
    background-color: rgba(243,246,248,.94);
  }

  .profile-photo-edit__file-upload-input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: transparent;
    opacity: 0;
  }
`;
